import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "container",
  "ion-padding": ""
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "grid_2" }
const _hoisted_4 = { class: "grid_3" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "grid_4" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: _normalizeClass(_ctx.isPastEvent() ? 'past-event' : '' )
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "grid_1 avatar",
          style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage })
        }, [
          _createElementVNode("img", {
            class: "avatar-circle",
            src: _ctx.circleImage
          }, null, 8, _hoisted_2)
        ], 4),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.ticket.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.parseDate()), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "tools" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    mode: "md",
                    disabled: (_ctx.ticket.link_info_pack && !_ctx.isPastEvent()) ? false : true,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.openLink(_ctx.ticket.link_info_pack)))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_icon, { icon: _ctx.informationCircleOutline }, null, 8, ["icon"]),
                        _createElementVNode("p", {
                          innerHTML: _ctx.$t('tickets.buttons.first')
                        }, null, 8, _hoisted_5)
                      ])
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_ion_button, {
                    mode: "md",
                    disabled: (_ctx.ticket.link_whatsapp && !_ctx.isPastEvent())? false : true,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (this.openLink(_ctx.ticket.link_whatsapp)))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_icon, { icon: _ctx.logoWhatsapp }, null, 8, ["icon"]),
                        _createElementVNode("p", {
                          innerHTML: _ctx.$t('tickets.buttons.second')
                        }, null, 8, _hoisted_6)
                      ])
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_ion_button, {
                    mode: "md",
                    disabled: _ctx.isPastEvent() || _ctx.ticket.quantity == 0,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openInviteGuestModal()))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_icon, { icon: _ctx.personAddOutline }, null, 8, ["icon"]),
                        _createElementVNode("p", {
                          innerHTML: _ctx.$t('tickets.buttons.third')
                        }, null, 8, _hoisted_7)
                      ])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            innerHTML: _ctx.ticket.item
          }, null, 8, _hoisted_9)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["grid_5", this.lock ? 'qr-lock-container' : ''])
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "separator" }, null, -1)),
          (this.lock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "qr-lock",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (this.$router.push({path: '/your-details', })))
              }, [
                _createVNode(_component_ion_icon, { icon: _ctx.lockClosed }, null, 8, ["icon"]),
                _cache[4] || (_cache[4] = _createElementVNode("p", null, "UNLOCK", -1))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_qrcode_vue, {
            class: _normalizeClass(this.isPastEvent() ? 'qr-past' : (_ctx.ticket.item?.match(/sold/i) ? 'qr qr-invalid' : 'qr')),
            value: _ctx.ticket.ticketToken,
            level: "L"
          }, null, 8, ["class", "value"])
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}