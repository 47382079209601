import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container",
  fullscreen: "false"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "total-container" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "discount"
}
const _hoisted_6 = { class: "total" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "stripe-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_quantity_selector = _resolveComponent("quantity-selector")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_stripe_page = _resolveComponent("stripe-page")!
  const _component_purchased_tokens = _resolveComponent("purchased-tokens")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_loading, {
        "is-open": this.loaderShown,
        message: ""
      }, null, 8, ["is-open"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!this.loaderShown)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h1", {
                  innerHTML: this.checkout.title
                }, null, 8, _hoisted_2),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_ion_subtitle, {
                  innerHTML: this.checkout.subtitle
                }, null, 8, ["innerHTML"]),
                (this.$route.path.includes('tokens'))
                  ? (_openBlock(), _createBlock(_component_ion_list, {
                      key: 0,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkout.items, (item) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: item.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_quantity_selector, {
                                item: item,
                                tokens: true
                              }, null, 8, ["item"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("form", null, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", {
                      class: "service-fee",
                      innerHTML: ((this.$route.query.id == 1 || !this.$route.query.id) ? _ctx.$t('checkout.service_fee') : _ctx.$t('checkout.service_fee_item')) + ' ' + this.checkout.serviceFee.price + _ctx.$t('checkout.currency')
                    }, null, 8, _hoisted_4),
                    (this.discount > 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                          _createElementVNode("b", null, "Discount -" + _toDisplayString(this.discount + _ctx.$t('checkout.currency')), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h1", {
                        innerHTML: _ctx.$t('checkout.total_price')
                      }, null, 8, _hoisted_7),
                      _createElementVNode("h1", {
                        ref: "totalPrice",
                        innerHTML: this.totalPriceServiceFee + _ctx.$t('checkout.currency')
                      }, null, 8, _hoisted_8)
                    ]),
                    _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1))
                  ]),
                  _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_ion_button, {
                      color: "dark",
                      size: "large",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submit())),
                      disabled: this.checkout.disabled || this.totalPriceServiceFee == 0
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(this.checkout.disabled ? _ctx.$t('checkout.button_disabled') : _ctx.$t('checkout.button_confirm')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (Object.keys(this.stripePayload).length != 0)
            ? (_openBlock(), _createBlock(_component_stripe_page, {
                key: 1,
                payload: this.stripePayload
              }, null, 8, ["payload"]))
            : _createCommentVNode("", true),
          (!this.loaderShown && (this.$route.query.id == 1 || !this.$route.query.id))
            ? (_openBlock(), _createBlock(_component_purchased_tokens, {
                key: 2,
                class: "tokens"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}