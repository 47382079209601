import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "no-tickets"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "item-divider"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ticket_card = _resolveComponent("ticket-card")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_loading, {
        "is-open": this.loaderShown,
        message: ""
      }, null, 8, ["is-open"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!this.loaderShown && this.getActiveTickets().length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_title, {
                  innerHTML: _ctx.$t('tickets.no_tickets.title')
                }, null, 8, ["innerHTML"]),
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('tickets.no_tickets.subtitle')
                }, null, 8, _hoisted_2),
                _createVNode(_component_ion_button, {
                  class: "events",
                  color: "light",
                  shape: "round",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$router.push({ path: '/events' })))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.musicalNotesOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (!this.loaderShown)
            ? (_openBlock(), _createBlock(_component_ion_list, {
                key: 1,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getActiveTickets(), (item) => {
                    return (_openBlock(), _createBlock(_component_ion_item, { key: item }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ticket_card, {
                          ticket: item,
                          lock: ((this.accountInfo.document == 'None' || this.accountInfo.document == '') || (this.accountInfo.name == 'None' || this.accountInfo.name == '') || (this.accountInfo.surname == 'None' || this.accountInfo.surname == '')  || (this.accountInfo.phone == 'None' || this.accountInfo.phone == '')) ? true : false
                        }, null, 8, ["ticket", "lock"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!this.loaderShown && _ctx.getPastTickets().length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_ion_label, { class: "divider-center" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      innerHTML: _ctx.$t('tickets.divider.past_tickets')
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (!this.loaderShown)
            ? (_openBlock(), _createBlock(_component_ion_list, {
                key: 3,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getPastTickets(), (item) => {
                    return (_openBlock(), _createBlock(_component_ion_item, { key: item }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ticket_card, { ticket: item }, null, 8, ["ticket"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadData($event))),
            threshold: "100px",
            id: "infinite-scroll",
            disabled: _ctx.isDisabled,
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                "loading-spinner": "bubbles",
                "loading-text": ""
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}