import { Ticket } from '../types'

export type TicketsState = {
  tickets: [Ticket],
  ticketToken: string | undefined,
};

export const ticketsState = (): TicketsState => ({
  tickets: [{
    event_id: 1,
    startDate:"2020-01-01",
    endDate:"2020-01-01",
    title:"",
    item: "",
    invitedItem: "",
    price: 0,
    quantity: 0,
    ticketToken: "",
    linkInfoPack:"",
    linkWhatsapp:"",
    }],
  ticketToken: undefined
})
