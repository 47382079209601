<script lang="ts">
import { IonItem, IonList, IonLabel, IonIcon } from '@ionic/vue'
import { defineComponent } from 'vue'
import { ellipsisVertical, informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, pizzaOutline, heartOutline, starOutline, homeOutline, personOutline, settingsOutline, carOutline, airplaneOutline, bedOutline, cameraOutline, chatbubbleOutline, checkmarkCircleOutline, cloudOutline, footballOutline, earthOutline, giftOutline } from "ionicons/icons";

export default defineComponent({
name: 'IconPicker',
component: {
  IonItem, 
  IonList,
  IonLabel,
  IonIcon
},
props: {
  setIcon: {
    type: String
  },
  forceClose: {
    type: Boolean
  },
  index: {
    type: Number
  }
},
data() {
  return {
    open: false,
    toolIcon: this.setIcon?.length ? this.setIcon : 'ellipsisVertical',
    icons: [informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, pizzaOutline, heartOutline, starOutline, homeOutline, personOutline, settingsOutline, carOutline, airplaneOutline, bedOutline, cameraOutline, chatbubbleOutline, checkmarkCircleOutline, cloudOutline, footballOutline, earthOutline, giftOutline]
  }
},
setup() {
  return {
    ellipsisVertical, informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, pizzaOutline, heartOutline, starOutline, homeOutline, personOutline, settingsOutline, carOutline, airplaneOutline, bedOutline, cameraOutline, chatbubbleOutline, checkmarkCircleOutline, cloudOutline, footballOutline, earthOutline, giftOutline
  }
},
methods: {
  openTools() {
    if (!this.open) {
      setTimeout(() => {
        this.open = true
      }, 20);
    }
  },
  getIcon(name) {
      let icons = {
        'ellipsisVertical': ellipsisVertical,
        'informationCircleOutline': informationCircleOutline, 
        'pinOutline': pinOutline, 
        'busOutline': busOutline, 
        'flowerOutline': flowerOutline, 
        'musicalNotesOutline': musicalNotesOutline, 
        'shirtOutline': shirtOutline, 
        'ticketOutline': ticketOutline, 
        'calendarOutline': calendarOutline,
        'pizzaOutline': pizzaOutline,
        'heartOutline': heartOutline,
        'starOutline': starOutline,
        'homeOutline': homeOutline,
        'personOutline': personOutline,
        'settingsOutline': settingsOutline,
        'carOutline': carOutline,
        'airplaneOutline': airplaneOutline,
        'bedOutline': bedOutline,
        'cameraOutline': cameraOutline,
        'chatbubbleOutline': chatbubbleOutline,
        'checkmarkCircleOutline': checkmarkCircleOutline,
        'cloudOutline': cloudOutline,
        'footballOutline': footballOutline,
        'earthOutline': earthOutline,
        'giftOutline': giftOutline
      };
      
      if (name in icons) {
        return icons[name];
      } else {
        return Object.keys(icons).find(key => icons[key] === name)
      }
    },
  pickIcon(icon) {
    this.toolIcon = this.getIcon(icon)
    this.$emit('icon', {icon: this.toolIcon, 'index': this.index})
  }
},
watch: {
  forceClose() {
    if (this.open) {
      this.open = false
    }
  },
}

});
</script>

<template>
  <ion-icon class="menu-icon" :icon="getIcon(this.toolIcon)" @click="this.openTools()"></ion-icon>
  <ion-list class="container" lines="none" v-show="this.open">
    <ion-item :class="'grid_'+index" v-for="(icon, index) in this.icons" :key="icon" lines="none"  @click="this.pickIcon(icon)">
      <ion-icon ref="icon" :icon="icon" ></ion-icon>
    </ion-item>
  </ion-list>
</template>

<style scoped>

.menu-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  left: 10px;
  position: absolute;
  transform: translate(0, 60%);
  z-index: 1000;
  color: var(--ion-color-dark-shade);
}


ion-list {
  position: absolute;
  z-index: 200;
  left: 30px;
  margin-top: -60px;
  box-shadow: 0px 0px 5px 0px var(--ion-color-dark-tint);
  background: var(--ion-color-white);
  border-radius: 5px;
  padding: 5px;
  max-height: 110px;
  overflow: scroll;
}

ion-item {
  --background: var(--ion-color-white);
  cursor: pointer;
}

ion-item:hover {
  --background: var(--ion-color-light-shade)
}


.container {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 48px 48px 48px ;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "grid_1 grid_2 grid_3"
    "grid_4 grid_5 grid_6"
    "grid_7 grid_8 grid_9"
    "grid_10 grid_11 grid_12"
    "grid_13 grid_14 grid_15"
    "grid_16 grid_17 grid_18"
    "grid_19 grid_20 grid_21";
}

.grid_1 { grid-area: grid_1; }

.grid_2 { grid-area: grid_2; }

.grid_3 { grid-area: grid_3; }

.grid_4 { grid-area: grid_4; }

.grid_5 { grid-area: grid_5; }

.grid_6 { grid-area: grid_6; }

.grid_7 { grid-area: grid_7; }

.grid_8 { grid-area: grid_8; }

.grid_9 { grid-area: grid_9; }

.grid_10 { grid-area: grid_10; }

.grid_11 { grid-area: grid_11; }

.grid_12 { grid-area: grid_12; }

.grid_13 { grid-area: grid_13; }

.grid_14 { grid-area: grid_14; }

.grid_15 { grid-area: grid_15; }

.grid_16 { grid-area: grid_16; }

.grid_17 { grid-area: grid_17; }

.grid_18 { grid-area: grid_18; }

.grid_19 { grid-area: grid_19; }

.grid_20 { grid-area: grid_20; }

.grid_21 { grid-area: grid_21; }

</style>
