import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fab_menu = _resolveComponent("fab-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, { mode: "md" }, {
    default: _withCtx(() => [
      _createVNode(_component_fab_menu, {
        class: "toolbar",
        isAuthorized: this.isAuthorized,
        forceClose: this.closeFab,
        onFabClick: _cache[0] || (_cache[0] = ($event: any) => (this.isMenuOpen = !this.isMenuOpen))
      }, null, 8, ["isAuthorized", "forceClose"]),
      _createElementVNode("div", {
        style: {"height":"100%"},
        onClick: _cache[1] || (_cache[1] = ($event: any) => (this.routerClicked()))
      }, [
        _createVNode(_component_ion_router_outlet, {
          class: _normalizeClass(["transition", this.isMenuOpen ? 'blur' : ''])
        }, null, 8, ["class"])
      ])
    ]),
    _: 1
  }))
}