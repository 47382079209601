import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "grid_1" }
const _hoisted_4 = { class: "grid_2" }
const _hoisted_5 = { class: "grid_3" }
const _hoisted_6 = { class: "grid_4" }
const _hoisted_7 = { class: "grid_5" }
const _hoisted_8 = { class: "grid_6" }
const _hoisted_9 = { class: "grid_7" }
const _hoisted_10 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_loading, {
            "is-open": this.loaderShown,
            message: ""
          }, null, 8, ["is-open"]),
          (!this.loaderShown)
            ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_header, { class: "ion-no-border" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, {
                        innerHTML: _ctx.$t('auth.signup.title')
                      }, null, 8, ["innerHTML"]),
                      _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
                      _createVNode(_component_ion_subtitle, {
                        innerHTML: _ctx.$t('auth.signup.subtitle')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  }),
                  _withDirectives(_createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.informationCircleOutline,
                      size: "large"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_text, {
                      innerHTML: _ctx.$t('auth.prefill_banner')
                    }, null, 8, ["innerHTML"])
                  ], 512), [
                    [_vShow, this.userExists == 'prefill']
                  ]),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_ion_subtitle, {
                        innerHTML: _ctx.$t('auth.signup.credentials')
                      }, null, 8, ["innerHTML"]),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            color: this.emailDanger
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('auth.email')) + " ", 1),
                              _withDirectives(_createVNode(_component_ion_text, {
                                class: "danger-message",
                                color: "warning",
                                innerHTML: _ctx.$t('auth.signup.email_registered')
                              }, null, 8, ["innerHTML"]), [
                                [_vShow, this.userExists == 'prefill']
                              ]),
                              _withDirectives(_createVNode(_component_ion_text, {
                                class: "danger-message",
                                color: "danger",
                                innerHTML: _ctx.$t('auth.signup.email_validation')
                              }, null, 8, ["innerHTML"]), [
                                [_vShow, this.emailDanger]
                              ]),
                              _withDirectives(_createVNode(_component_ion_text, {
                                class: "danger-message",
                                color: "danger",
                                innerHTML: _ctx.$t('auth.signup.email_exists').replace('*site*', _ctx.Site[this.userExists]).replace('*url*', this.$router.name).replace('*email*', this.email)
                              }, null, 8, ["innerHTML"]), [
                                [_vShow, this.userExists && this.userExists != 'prefill']
                              ])
                            ]),
                            _: 1
                          }, 8, ["color"]),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.email,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                            onChange: _cache[1] || (_cache[1] = ($event: any) => {_ctx.validateInput(); _ctx.checkExistingEmail()}),
                            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
                            type: "email"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            color: this.nameDanger,
                            innerHTML: _ctx.$t('auth.name')
                          }, null, 8, ["color", "innerHTML"]),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.name,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.name) = $event)),
                            style: {"text-transform":"capitalize"},
                            onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateInput())),
                            onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            color: this.surnameDanger,
                            innerHTML: _ctx.$t('auth.surname')
                          }, null, 8, ["color", "innerHTML"]),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.surname,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.surname) = $event)),
                            style: {"text-transform":"capitalize"},
                            onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateInput())),
                            onKeyup: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            color: this.instagramDanger
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(this.noInstagram ? _ctx.$t('auth.referral') :  _ctx.$t('auth.instagram')), 1)
                            ]),
                            _: 1
                          }, 8, ["color"]),
                          _createVNode(_component_ion_input, {
                            class: "instagram_input",
                            modelValue: _ctx.instagram,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.instagram) = $event)),
                            onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.validateInput())),
                            onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_ion_button, {
                            color: this.noInstagram ? 'primary' : 'danger',
                            class: "no_instagram",
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (this.noInstagram = !this.noInstagram))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(this.noInstagram ?  _ctx.$t('auth.short_instagram') : _ctx.$t('auth.short_no_instagram')), 1)
                            ]),
                            _: 1
                          }, 8, ["color"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            color: this.documentDanger
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('auth.document')), 1)
                            ]),
                            _: 1
                          }, 8, ["color"]),
                          _createVNode(_component_ion_input, {
                            class: "document_input",
                            modelValue: _ctx.document,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.document) = $event)),
                            onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.validateInput())),
                            onKeyup: _cache[15] || (_cache[15] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            innerHTML: _ctx.$t('auth.phone') + ' ' + _ctx.$t('auth.optional')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.phone,
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.phone) = $event)),
                            onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.validateInput())),
                            onKeyup: _cache[18] || (_cache[18] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
                            type: "tel"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            color: this.passwordDanger
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('auth.password')) + " ", 1),
                              (this.passwordDanger)
                                ? (_openBlock(), _createBlock(_component_ion_text, {
                                    key: 0,
                                    class: "danger-message",
                                    color: "danger",
                                    innerHTML: _ctx.$t('auth.signup.password_validation')
                                  }, null, 8, ["innerHTML"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["color"]),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.password,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.password) = $event)),
                            onChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.validateInput())),
                            onKeyup: _cache[21] || (_cache[21] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
                            type:  this.showPassword ? 'text' : 'password'
                          }, null, 8, ["modelValue", "type"]),
                          _createVNode(_component_ion_button, {
                            class: "show_password",
                            slot: "end",
                            onClick: _cache[22] || (_cache[22] = ($event: any) => (this.showPassword = !this.showPassword))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: this.showPassword ? _ctx.eyeOffOutline : _ctx.eyeOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_button_loading, {
                      color: this.buttonDanger,
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (this.submit())),
                      isLoading: this.loaderShown,
                      text: _ctx.$t('auth.signup.button_submit')
                    }, null, 8, ["color", "isLoading", "text"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}