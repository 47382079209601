<script lang="ts">
import { IonPage, IonItem, IonLabel, IonInput, IonLoading, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { Site } from '../../enums'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import UserExistsModal from '../components/modal/UserExistsModal.vue'


export default defineComponent({
name: 'UserDetailsPage',
  components: {
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonLoading,
    ButtonLoading
  },
  data() {
    return {
      loaderShown: false,
      submitted: false,
      nameDanger: "",
      surnameDanger: "",
      documentDanger: "",
      buttonDanger: "light",
      name: "",
      surname: "",
      document: "",
      phone: "",
      Site
    }
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized,
      userExists: GettersTypes.userExists,
      accountInfo: GettersTypes.accountInfo
    }),
  },
  methods: {
    ...mapActions({
      userDetails: ActionTypes.userDetails,
      checkEmail: ActionTypes.checkEmail,
      getAccountInfo: ActionTypes.getAccountInfo,
      confirmPayment: 'CONFIRM_PAYMENT',
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        await this.userDetails({token: this.$cookies.get('access_token'), name: this.name, surname: this.surname, document: this.document, phone: this.phone})
        this.loaderShown = false
        if (!this.userExists) {
          this.$emit('signedUp')
          this.getAccountInfo(this.$cookies.get('access_token'))
          this.$router.push({ path: this.$t('toolbar.path_tickets') })
        }
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validateInput() {
      if (this.submitted) {
        this.nameDanger = this.name ? '' : 'danger'
        this.surnameDanger = this.surname ? '' : 'danger'
        this.documentDanger = this.document ? '' : 'danger'
        this.phoneDanger = this.phone ? '' : 'danger'
        this.buttonDanger = ( this.name && this.surname && this.document && this.phone)  ? 'light' : 'danger'
        return (this.name && this.surname && this.document && this.phone)
      }
    },
    checkExistingEmail() {
      if (this.email) {
        this.checkEmail(this.email)
      }
    },
    async openUserExistsModal() {
      const modal = await modalController.create({
        component: UserExistsModal,
        componentProps: {prefilledEmail: this.email, site: this.userExists}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
  },
  async mounted() {
    await this.getAccountInfo(this.$cookies.get('access_token'))
    this.name = this.accountInfo.name
    this.surname = this.accountInfo.surname
    this.document = this.accountInfo.document
    this.phone = this.accountInfo.phone
  },
  watch: {
    userExists() {
      if (this.userExists) {
        this.openUserExistsModal()
      }
    }
  },
});
</script>


<template>
  <ion-page>
    <ion-content>
        <ion-loading
          :is-open="this.loaderShown"
          message=""
        ></ion-loading>
      <ion-card>
        <ion-header class="ion-no-border">
          <ion-title v-html="$t('auth.signup.title')"></ion-title>
          <br>
          <ion-subtitle v-html="$t('auth.signup.subtitle_details')"></ion-subtitle>
        </ion-header>

        <div class="container">
          {{this.nameDanger }} {{this.surnameDanger}} {{this.documentDanger}}
          <div class="grid_1">
            <ion-item>
              <ion-label position="floating" :color="this.nameDanger" v-html="$t('auth.name')"></ion-label>
              <ion-input v-model="name" style="text-transform:capitalize;" @change="validateInput()" @keyup.enter="submit()"></ion-input>
            </ion-item>
          </div>

          <div class="grid_2">
            <ion-item>
              <ion-label position="floating" :color="this.surnameDanger" v-html="$t('auth.surname')"></ion-label>
              <ion-input v-model="surname" style="text-transform:capitalize;" @change="validateInput()" @keyup.enter="submit()"></ion-input>
            </ion-item>
          </div>

          <div class="grid_3">
            <ion-item>
              <ion-label position="floating" :color="this.documentDanger">{{ $t('auth.document') }}</ion-label>
              <ion-input class="document_input" v-model="document" @change="validateInput()" @keyup.enter="submit()"></ion-input>
            </ion-item>
          </div>

          <div class="grid_4">
            <ion-item>
              <ion-label position="floating" :color="this.phoneDanger">{{ $t('auth.phone') }}</ion-label>
              <ion-input v-model="phone" @change="validateInput()" @keyup.enter="submit()" type="tel"></ion-input>
            </ion-item>
          </div>

        
        </div>
        <div class="button">
          <button-loading :color="this.buttonDanger" @click="this.submit()" :isLoading="this.loaderShown" :text="$t('auth.signup.button_submit')"></button-loading>
        </div>
      </ion-card>
      
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-card {
  margin: 20px auto;
  max-height: 720px;
  max-width: 780px;
  padding: 50px;
  --box-shadow: none;
  background: var(--ion-color-white)
}

ion-item {
  --background: var(--ion-color-white)
}

ion-header {
  margin-top: 20px;
  margin-bottom: 50px;
}

ion-title {
  color: var(--ion-color-dark);
  position: relative;
  padding: 0 5px;
  text-align: left
}

ion-button {
  padding: 0 20px;
  float: right
}

.button {
  text-align: center
}

.danger-message {
  font-style: italic;
}

.edit {
  position: absolute;
  top: 20px;
  right: 40px;
  height: 40px;
  width: 40px;
  z-index: 10;
  --padding-start: 0;
  --padding-end: 0;
}

.edit ion-icon {
  margin-left: 2px;
  min-width: 20px;
}

.confirm {
  justify-content: right;
  margin-top: 50px;
}

.confirm ion-button {
  margin: 0 10px;
}

.delete_account {
  --background: transparent; 
  --color: var(--ion-color-dark);
  padding: 0;
  margin-top: 20px;
  border: none;
  --box-shadow: none;
}

.no_instagram {
    opacity:0;
    position: absolute;
    margin: 20px;
    right: 0;
    z-index: 2;
}

ion-input:focus + .no_instagram {
    opacity:1;
}

.instagram_input.has-focus + .no_instagram {
   opacity:1;
}

.instagram_input {
  transition: 1000;
}


.container {  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: calc(1fr + 20px) 1fr 1fr 1fr;
  gap: 10px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    "grid_1 grid_2"
    "grid_3 grid_3"
    "grid_4 grid_4";
  margin-bottom: 50px
}

.grid_1 { grid-area: grid_1; }

.grid_2 { grid-area: grid_2; }

.grid_3 { grid-area: grid_3; }

.grid_4 { grid-area: grid_4; }

.grid-title { grid-area: grid-title }



@media (max-width: 780px) { 
  ion-card {
    margin-top: 50px;
    min-height: 550px;
    width: 90%;
    padding: 40px 20px;
  }

  ion-button {
    float: none
  }

  .danger-message {
    font-size: 12px;
  }
  .container {  
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.5fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "grid_1"
      "grid_2"
      "grid_3"
      "grid_4";
  }

}
</style>
