<script lang="ts">
    import {
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        modalController,
    } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import CheckoutPage from '@/modules/checkout/presentation/views/CheckoutPage.vue'
    export default defineComponent({
        name: 'CheckoutPageModal',
        components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, CheckoutPage },
        methods: {
          cancel() {
            return modalController.dismiss(null, 'cancel');
          },
          confirm() {
            return modalController.dismiss(this, 'confirm');
          },
        },
    });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-title></ion-title>
      <ion-buttons slot="end">
        <ion-button @click="confirm" v-html="$t('common.cancel')"></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <checkout-page></checkout-page>
  </ion-content>
</template>

<style scoped>
.login {
    margin: 20px
}
</style>
