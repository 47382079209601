<script lang="ts">
  import {
      IonContent,
      IonHeader,
      IonTitle,
      IonToolbar,
      IonButtons,
      IonButton,
      modalController,
  } from '@ionic/vue';
  import { closeOutline, copy, checkmark } from 'ionicons/icons';
  import { defineComponent } from 'vue';
  import { ActionTypes } from '../../../business'
  import { mapActions } from 'vuex'
  import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
  
  export default defineComponent({
      name: 'AssignTicketModal',
      components: { 
        IonContent, 
        IonHeader, 
        IonTitle, 
        IonToolbar, 
        IonButtons,
        // IonCardSubtitle,
        IonButton,
        ButtonLoading },
      props: {
        token: {
          type: String
        }
      },
      data() {
        return {
          coppied: false,
          isLoading: false
        }
      },
      methods: {
        ...mapActions('tickets',{
          assignTickets: ActionTypes.assignTickets,
          getTickets: ActionTypes.getTickets,
        }),
        cancel() {
          return modalController.dismiss(null, 'cancel');
        },
        async confirm() {
          this.isLoading = true
          await this.assignTickets({ticket_token: this.token, token: this.$cookies.get('access_token')})
          await this.getTickets(this.$cookies.get('access_token'))
          this.isLoading = false
          return modalController.dismiss(this, 'confirm');
        },
        copyClipboard() {
          navigator.clipboard.writeText(this.getUrl());
          this.coppied = true
        },
        getUrl() {
          return process.env.VUE_APP_FE_URL + '/entrances/?share=' + this.token
        }

      },
      setup() {
        return {
          closeOutline,
          copy,
          checkmark
        }
      }
  });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Assign Ticket</ion-title>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel" size="large">
          <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-text>You've been invited to an event!<br>Do you want to assign ticket to yourself?</ion-text>
    <br>
    <button-loading color="success" @click="confirm" :text="$t('common.yes')" :isLoading="this.isLoading"></button-loading>
    <ion-button color="danger" @click="cancel">{{$t('common.no')}}</ion-button>
  </ion-content>
</template>

<style scoped>

  ion-text {
    margin: 10px 0;
  }
  ion-content {
    text-align: center
  }

  ion-button {
    margin: 20px 10px;
  }

  
</style>
