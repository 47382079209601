<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import axios from 'axios'
import { IonPage, IonLoading } from '@ionic/vue'
export default {
  name: 'StripePage',
  components: {
    IonPage,
    IonLoading,
    StripeCheckout
  },
  props: {
    payload: {
      type: Object,
      default() {
        return {basket: []}
      } 
    }
  },
  data() {
    return {
      loading: false,
      sessionId: null,
      successURL: 'https://app.cabanalibre.com' + (this.payload.token ? this.$t('toolbar.path_tokens') + '?paymentId='+this.payload.paymentHash : this.$t('toolbar.path_tickets') + '?paymentId='+this.payload.paymentHash),
      cancelURL: 'https://app.cabanalibre.com' + (this.payload.token ? this.$t('toolbar.path_tokens') : this.$t('toolbar.path_events'))
    };
  },
  methods: {
    async createCheckoutSession() {
      try {
        // Call your backend to create a checkout session
        const path = process.env.VUE_APP_BASE_URL+'/create-checkout-session/'+this.$cookies.get('access_token');
        const response = await axios.post(path, {
          basket: this.payload.basket,
          successURL: this.successURL,
          cancelURL: this.cancelURL,
          discountCode: this.payload.discountCode
        });
        this.sessionId = response.data.sessionId;
        this.$refs.checkoutRef.redirectToCheckout();
      } catch (error) {
        console.error('Error creating checkout session:', error);
      } finally {
        this.loaderShown = false;
      }
    }
  },
  mounted() {

    this.loaderShown = true
    if (this.payload.openRegistration) {
      console.log('Stripe', 'Open registration')
      this.successURL = process.env.VUE_APP_FE_URL+this.$t('toolbar.path_signup')+'?paymentId='+this.payload.paymentHash+'&email='+this.payload.email+'&instagram='+this.payload.instagram
    }
    this.createCheckoutSession();
  },
};
</script>

<template>
  <ion-page>
    <ion-loading
      :is-open="this.loaderShown"
      message=""
    ></ion-loading>
    <ion-content>
        <stripe-checkout
          ref="checkoutRef"
          mode="payment"
          :pk="this.payload.stripePk"
          :line-items="this.payload.basket"
          :success-url="successURL"
          :cancel-url="cancelURL"
          :customer-email="this.payload.email"
          :session-id="sessionId"
          @loading="v => loading = v"
        />
        <button @click="submit" v-html="$t('checkout.button_pay')"></button>
    </ion-content>
  </ion-page>
</template>