import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "grid_1" }
const _hoisted_3 = { class: "grid_2" }
const _hoisted_4 = { class: "grid_3" }
const _hoisted_5 = { class: "grid_4" }
const _hoisted_6 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_loading, {
            "is-open": this.loaderShown,
            message: ""
          }, null, 8, ["is-open"]),
          _createVNode(_component_ion_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, { class: "ion-no-border" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, {
                    innerHTML: _ctx.$t('auth.signup.title')
                  }, null, 8, ["innerHTML"]),
                  _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_component_ion_subtitle, {
                    innerHTML: _ctx.$t('auth.signup.subtitle_details')
                  }, null, 8, ["innerHTML"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createTextVNode(_toDisplayString(this.nameDanger) + " " + _toDisplayString(this.surnameDanger) + " " + _toDisplayString(this.documentDanger) + " ", 1),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        position: "floating",
                        color: this.nameDanger,
                        innerHTML: _ctx.$t('auth.name')
                      }, null, 8, ["color", "innerHTML"]),
                      _createVNode(_component_ion_input, {
                        modelValue: _ctx.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                        style: {"text-transform":"capitalize"},
                        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateInput())),
                        onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        position: "floating",
                        color: this.surnameDanger,
                        innerHTML: _ctx.$t('auth.surname')
                      }, null, 8, ["color", "innerHTML"]),
                      _createVNode(_component_ion_input, {
                        modelValue: _ctx.surname,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surname) = $event)),
                        style: {"text-transform":"capitalize"},
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateInput())),
                        onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        position: "floating",
                        color: this.documentDanger
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('auth.document')), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_input, {
                        class: "document_input",
                        modelValue: _ctx.document,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.document) = $event)),
                        onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateInput())),
                        onKeyup: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        position: "floating",
                        color: this.phoneDanger
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('auth.phone')), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_input, {
                        modelValue: _ctx.phone,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.phone) = $event)),
                        onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.validateInput())),
                        onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
                        type: "tel"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_button_loading, {
                  color: this.buttonDanger,
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (this.submit())),
                  isLoading: this.loaderShown,
                  text: _ctx.$t('auth.signup.button_submit')
                }, null, 8, ["color", "isLoading", "text"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}