import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_sign_in = _resolveComponent("sign-in")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_ion_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, { class: "ion-no-border" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, {
                    innerHTML: _ctx.$t('auth.signin.title')
                  }, null, 8, ["innerHTML"]),
                  _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_component_ion_subtitle, {
                    innerHTML: _ctx.$t('auth.signin.subtitle')
                  }, null, 8, ["innerHTML"])
                ]),
                _: 1
              }),
              _createVNode(_component_sign_in, {
                onConfirm: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('confirm')))
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, !this.isAuthorized]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}