<script lang="ts">
    import { IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonLabel, IonIcon, modalController } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { closeOutline } from 'ionicons/icons';
    import SignIn from '@/modules/auth/presentation/components/SignInComponent.vue'

    export default defineComponent({
        name: 'OpenRegistrationModal',
        components: { 
          IonHeader, 
          IonTitle, 
          IonToolbar, 
          IonButtons, 
          IonButton, 
          IonLabel, 
          IonIcon,
          SignIn
        },
        props: {
          eventId: {
            type: Number
          }
        },
        data() {
          return {
            loaderShown: false,
            login: false
          }
        },
        setup() {
          return {
            closeOutline
          }
        },
        methods: {
            cancel() {
              return modalController.dismiss(null, 'cancel');
            },
            confirm() {
              this.$emit('confirm')
              return modalController.dismiss(this, 'confirm');
            },
        },
    });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel" size="large">
          <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title v-html="$t('events.modal.title_register')"></ion-title>
    </ion-toolbar>
  </ion-header>

  <!-- <ion-content v-if="this.login">
    <ion-title class="sign-in-header" v-html="$t('toolbar.title_signin')"></ion-title>
    <sign-in @confirm="this.confirm()"></sign-in>
  </ion-content>  -->
  <ion-content class="ion-padding">

   <ion-item-divider>
        <ion-label>
          <p v-html="$t('events.modal.subtitle_signup')"></p>
        </ion-label>
    </ion-item-divider>
    <iom-item>
        <ion-button class="login" @click="$router.push({ path: $t('toolbar.path_signup'), query: {register: this.eventId} }); this.confirm()" v-html="$t('toolbar.title_signup')"></ion-button>
    </iom-item>

    <ion-item-divider>
        <ion-label>
          <p v-html="$t('events.modal.subtitle_signin')"></p>
        </ion-label>
    </ion-item-divider>

    <div class="sign-in">
      <sign-in @confirm="this.confirm()" :createAccount="false"></sign-in>
    </div>

    <!-- <open-registration @submit="this.loaderShown = true" @confirm="this.confirm()" :eventId="this.eventId" orientation='left'></open-registration> -->
  </ion-content>
</template>

<style scoped>

ion-content, ion-item {
  --background: var(--ion-color-white)
}

.login {
    margin: 20px
}

.sign-in-header {
  margin: 20px 0;
  margin-top: 50px;
  max-width: 320px;
  left: 50%;
  transform: translateX(-50%);
}

ion-content {
  background-color: transparent;
}

ion-item-divider {
  height: 20px;
}

.sign-in {
  width: 320px;
}

</style>
