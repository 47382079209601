<script lang="ts">
import { IonIcon, IonInput } from '@ionic/vue'
import { pencil, checkmark, close, refresh } from "ionicons/icons";
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../../business'
import ToolButton from '../ToolButtonComponent.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


export default defineComponent({
name: 'UsersTable',
  components: {
    // IonBadge,
    Vue3EasyDataTable,
    ToolButton,
    IonIcon,
    IonInput,
  },
  setup() {
    return {
      pencil,
      checkmark,
      close,
      refresh
    }
  },
  computed: {
    ...mapGetters('internal',{
      search: GettersTypes.search,
      users: GettersTypes.users,
      loading: GettersTypes.loading,
    }),
  },
  methods: {
    ...mapActions('internal',{
      getUsers: ActionTypes.getUsers,
    }),
    ...mapActions({
      updateAccountInfo: 'UPDATE_ACCOUNT_INFO',
    }),
    formattedTime(time) {
      var date = new Date(time)
      var formattedTime = date.toLocaleTimeString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) 
      return formattedTime
    },
    closeTools() {
      this.forceCloseTools = true
      setTimeout(() => {
        this.forceCloseTools = false
      }, 10);
    },
    editDetails(item) {
      var index = this.data.findIndex(function(e) {return e.id == parseInt(item.id)})
      this.data[index].edit = !this.data[index].edit
      item.editDetails.name = item.name
      item.editDetails.surname = item.surname
      item.editDetails.instagram = item.instagram
      item.editDetails.document = item.document
      item.editDetails.phone = item.phone
    },
    async updateDetails(item) {
      var index = this.users.findIndex(function(e) {return e.id == parseInt(item.id)})
      this.users[index].editLoading = true
      await this.updateAccountInfo({ 
        email: item.email,
        name: item.editDetails.name ? item.editDetails.name : item.name,
        surname: item.editDetails.surname ? item.editDetails.surname : item.surname,
        instagram: item.editDetails.instagram ? item.editDetails.instagram : item.instagram,
        document: item.editDetails.document ? item.editDetails.document : item.document,
        phone: item.editDetails.phone ? item.editDetails.phone : item.phone 
      })
      this.users[index].name = item.editDetails.name
      this.users[index].surname = item.editDetails.surname
      this.users[index].instagram = item.editDetails.instagram
      this.users[index].document = item.editDetails.document
      this.users[index].phone = item.editDetails.phone
      this.users[index].edit = false
      this.users[index].editLoading = false
      
    },
    async refreshTable() {
      this.refreshLoading = true
      await this.getUsers(this.$cookies.get('access_token'))
      var ids = this.data.map(a => a.id)
      var newData = this.users.filter(e => !ids.includes(e.id))
      if (newData.length) {
        newData.forEach(e => e.new = 'true');
        this.data = newData.concat(this.data)
      }
      this.refreshLoading = false
    }
  },
  data() {
    return {
      headers: [
        { text: "", value: "new"},
        { text: "CREATION TIME", value: "creation_time", sortable: true},
        { text: "EMAIL", value: "email", sortable: true},
        { text: "INSTAGRAM", value: "instagram", sortable: true},
        { text: "NAME", value: "name", sortable: true},
        { text: "SURNAME", value: "surname", sortable: true},
        { text: "DOCUMENT", value: "document", sortable: true},
        { text: "PHONE", value: "phone", sortable: true},
        { text: "", value: "tools"},
      ],
      searchValue: ref(""),
      forceCloseTools: false,
      data: [],
      refreshLoading: false
    }
  },
  async mounted() {
    await this.getUsers(this.$cookies.get('access_token'))
    this.data = this.users
  }
})
</script>

<template>
  <vue3-easy-data-table
    buttons-pagination
    alternating
    :headers="headers" 
    :items="this.data" 
    :loading="this.data.length ? false : this.loading"
    :search-value="this.search"
    @click="closeTools()">

    <template v-slot:[`item-new`]="item">
      <div v-if="item.new" class='new'></div>
    </template>

    <template v-slot:[`header-tools`]>
      <div class="customize-header">
        <ion-icon v-if="this.data.length ? !this.loading : true" :icon="refresh" class="refresh" @click="this.refreshTable()"></ion-icon>
        <div v-else class="dot-carousel"></div>
      </div>
    </template>

    <template v-slot:[`item-creation_time`]="{ creation_time }">
      {{ this.formattedTime(creation_time) }}
    </template>

    <template v-slot:[`item-name`]="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.name" :value="item.name"></ion-input>
      <div class="data" v-else>{{item.name}}</div>
    </template>
    
    <template v-slot:[`item-surname`]="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.surname" :value="item.surname"></ion-input>
      <div class="data" v-else>{{item.surname}}</div>
    </template>

    <template v-slot:[`item-instagram`]="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.instagram" :value="item.instagram"></ion-input>
      <div class="data" v-else>{{item.instagram}}</div>
    </template>

    <template v-slot:[`item-document`]="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.document" :value="item.document"></ion-input>
      <div class="data" v-else>{{item.document}}</div>
    </template>

    <template v-slot:[`item-phone`]="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.phone" :value="item.phone"></ion-input>
      <div class="data" v-else>{{item.phone}}</div>
    </template>
    
    <template v-slot:[`item-tools`]="item">
      <div v-if="!item.edit">
        <ion-icon :icon="pencil" class="pencil" @click="editDetails(item)"></ion-icon>
        <tool-button :item="item" :forceClose="this.forceCloseTools"></tool-button>
      </div>
      <div v-else-if="item.editLoading" class="dot-carousel"></div>
      <div v-else>
        <ion-icon :icon="checkmark" color="success" @click="updateDetails(item)"></ion-icon>
        <ion-icon :icon="close" color="danger" @click="editDetails(item)"></ion-icon>
      </div>
      
    </template>

  </vue3-easy-data-table>
</template>

<style scoped>
  ion-icon {
    margin: 5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .pencil {
    margin: 10px 0;
    height: 18px;
    width: 18px;
  }
  .refresh {
    margin: 0 20px;
  }
  .pencil:hover,
  .refresh:hover {
    color: var(--ion-color-dark-shade)
  }
  ion-input {
    --padding-start: 0px;
    width: 100px;
        --padding-start: 5px;
    width: 100px;
    border-bottom: solid 2px var(--ion-color-warning);
    margin: 5px -5px;
    height: 33px;
    font-weight: 500;
  }
  .new {
    height: 42px;
    width: 5px;
    margin-left: -10px;
    background-color: var(--ion-color-success)
  }
  .data {
    width: 100px;
  }

  .dot-carousel {
    position: relative;
    left: -9999px;
    margin: 0 25px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--ion-color-light);
    color: var(--ion-color-light);
    box-shadow: 9984px 0 0 0 var(--ion-color-light), 9999px 0 0 0 var(--ion-color-light), 10014px 0 0 0 var(--ion-color-light);;
    animation: dotCarousel 1.5s infinite linear;
  }
  
  @keyframes dotCarousel {
    0% {
      box-shadow: 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade);
    }
    50% {
      box-shadow: 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade);
    }
    100% {
      box-shadow: 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade);
    }
  }

</style>
