import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "selector" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "buttons center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_subtitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.item.item), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "avatar center",
        style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage })
      }, [
        _createElementVNode("img", {
          ref: "avatarCircle",
          class: "avatar-circle",
          src: _ctx.circleImage
        }, null, 8, _hoisted_3)
      ], 4),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ion_button, {
          mode: "md",
          shape: "round",
          class: "fix-position",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.decreaseValue()))
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("-")
          ])),
          _: 1
        }),
        _createElementVNode("input", {
          ref: 'quantity-'+this.item.itemId,
          type: "number",
          class: "quantity",
          min: "0",
          value: "0",
          disabled: ""
        }, null, 512),
        _createVNode(_component_ion_button, {
          mode: "md",
          shape: "round",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.increaseValue()))
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("+")
          ])),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_ion_subtitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.item.price) + "€", 1)
      ]),
      _: 1
    })
  ]))
}