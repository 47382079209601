import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_internal_navigation = _resolveComponent("internal-navigation")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab_list = _resolveComponent("ion-fab-list")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_fab, {
      horizontal: "left",
      vertical: "top",
      slot: "fixed",
      activated: this.forceClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_title, {
          class: _normalizeClass(["md", _ctx.isCurrentPath(_ctx.$t('toolbar.path_portfolio')) ? 'hidden_title' : '']),
          size: "large"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$route.name), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        (_ctx.isCurrentPath(_ctx.$t('toolbar.path_portfolio')))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.isCurrentPath(_ctx.$t('toolbar.path_admin')))
          ? (_openBlock(), _createBlock(_component_internal_navigation, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_fab_button, {
          color: "dark",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {this.click();})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.menuOutline }, null, 8, ["icon"])
          ]),
          _: 1
        }),
        (this.isAuthorized)
          ? (_openBlock(), _createBlock(_component_ion_fab_list, {
              key: 2,
              side: "bottom"
            }, {
              default: _withCtx(() => [
                (_ctx.isInternalRole())
                  ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                      key: 0,
                      color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_scanner')) ? this.activeMenuColor : 'warning',
                      "data-desc": _ctx.$t('toolbar.title_scanner'),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_scanner') })})
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.scanOutline }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["color", "data-desc"]))
                  : _createCommentVNode("", true),
                (_ctx.isInternalRole())
                  ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                      key: 1,
                      color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_admin')) ? this.activeMenuColor : 'warning',
                      "data-desc": _ctx.$t('toolbar.title_admin'),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_admin') })})
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.documentTextOutline }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["color", "data-desc"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_portfolio')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_portfolio'),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigateToCabanalibre()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.searchOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"]),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_tickets')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_tickets'),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_tickets') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.ticketOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"]),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_tokens')) ? this.activeMenuColor : 'light',
                  "data-desc": 'Tokens',
                  onClick: _cache[5] || (_cache[5] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_tokens') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.wineOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color"]),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_events')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_events'),
                  onClick: _cache[6] || (_cache[6] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_events') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.musicalNotesOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"]),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_account')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_account'),
                  onClick: _cache[7] || (_cache[7] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_account') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.personCircleOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"]),
                _createVNode(_component_ion_fab_button, {
                  color: "light",
                  "data-desc": _ctx.$t('toolbar.title_signout'),
                  onClick: _cache[8] || (_cache[8] = ($event: any) => {this.signOut(); this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_signout') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.logOutOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["data-desc"])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_ion_fab_list, {
              key: 3,
              side: "bottom"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_portfolio')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_portfolio'),
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.navigateToCabanalibre()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.searchOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"]),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_events')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_events'),
                  onClick: _cache[10] || (_cache[10] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_events') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.musicalNotesOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"]),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_signin')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_signin'),
                  onClick: _cache[11] || (_cache[11] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_signin') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.logInOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"]),
                _createVNode(_component_ion_fab_button, {
                  color: _ctx.isCurrentPath(_ctx.$t('toolbar.path_signup')) ? this.activeMenuColor : 'light',
                  "data-desc": _ctx.$t('toolbar.title_signup'),
                  onClick: _cache[12] || (_cache[12] = ($event: any) => {this.click(); _ctx.$router.push({ path: _ctx.$t('toolbar.path_signup') })})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.accessibilityOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["color", "data-desc"])
              ]),
              _: 1
            }))
      ]),
      _: 1
    }, 8, ["activated"]),
    (!this.isAuthorized)
      ? (_openBlock(), _createBlock(_component_ion_fab, {
          key: 0,
          vertical: "top",
          horizontal: "end",
          slot: "fixed",
          class: _normalizeClass(this.isMenuOpen ? 'blur' : '')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, {
              class: "md sign-in-up-text",
              size: "large"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$route.name == 'Sign In' ? 'Sign Up' : ''), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_fab_button, {
              color: "light",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.isCurrentPath(_ctx.$t('toolbar.path_signin')) ? _ctx.$router.push({ path: _ctx.$t('toolbar.path_signup') }) : _ctx.$router.push({ path: _ctx.$t('toolbar.path_signin') })))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.isCurrentPath(_ctx.$t('toolbar.path_signin')) ? _ctx.personAddOutline : _ctx.personOutline
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ], 64))
}