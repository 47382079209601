<script lang="ts">
import { IonCard } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { GettersTypes } from '../../business'

export default defineComponent({
name: 'ScanResponse',
  components: {
    IonCard
  },
  computed: {
    ...mapGetters('internal',{
      scanData: GettersTypes.scanData,
      scanStatus: GettersTypes.scanStatus,
    }),
  },
});
</script>

<template> 
    <!-- TODO: SOLD Hack -->
    <ion-card :color="this.scanData.item?.match(/sold/i) ? 'warning' : (this.scanStatus == 'SUCCESS' ? 'success' : (this.scanStatus == 'SCANNED' ? 'warning' : (this.scanStatus == 'INVALID' ? 'danger' : 'light')))">
      <h1> {{ this.scanStatus }} {{ this.scanStatus == 'SCANNED' ? this.scanData.scanned + 'x' : ''}}</h1>
      <div class="details">
        <h2 v-html="this.scanData.item"></h2>
        <p v-html="this.scanData.title "></p>
        <p v-if="this.scanData.price" v-html="'Price:' + this.scanData.price + '€'"></p>
        <h3 v-html="this.scanData.name + ' ' +this.scanData.surname "></h3>
        <p v-html="this.scanData.creation_time "></p>
        <p v-if="this.scanData.username" v-html="'Last scan by: ' + this.scanData.username "></p>
      </div>
    </ion-card>
</template>

<style scoped>
 ion-card {
  position: absolute;
  top: 20px;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  max-width: 780px;
  min-width: 400px;
  padding: 20px;
  padding-top: 10px;
  z-index: 10;
  text-align: center;
  border-radius: 20px;
  
 }

 .details {
  color: var(--ion-color-dark);
 }

</style>
