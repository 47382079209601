import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "about_us" }
const _hoisted_2 = { class: "video-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "item-divider" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "next-event" }
const _hoisted_7 = { class: "item-divider" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "item-divider" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "gallery" }
const _hoisted_12 = { class: "item-divider" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "item-divider" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "footer" }
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_event_card_component = _resolveComponent("event-card-component")!
  const _component_core_values = _resolveComponent("core-values")!
  const _component_item_list = _resolveComponent("item-list")!
  const _component_slide = _resolveComponent("slide")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_carousel = _resolveComponent("carousel")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_text, {
              innerHTML: _ctx.$t('portfolio.heading_first')
            }, null, 8, ["innerHTML"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("iframe", {
                width: "560",
                height: "315",
                src: _ctx.$t('portfolio.link_youtube'),
                title: "YouTube video player",
                frameborder: "0",
                allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: ""
              }, null, 8, _hoisted_3)
            ]),
            _createVNode(_component_ion_text, {
              innerHTML: _ctx.$t('portfolio.heading_second')
            }, null, 8, ["innerHTML"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ion_label, { class: "divider-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('portfolio.divider.first')
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_event_card_component, {
              onSubmit: _cache[0] || (_cache[0] = ($event: any) => (this.events[0].state = 'REGISTERED')),
              event: this.getEvent()
            }, null, 8, ["event"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ion_label, { class: "divider-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('portfolio.divider.second')
                }, null, 8, _hoisted_8)
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('portfolio.content_first'), (item) => {
            return (_openBlock(), _createBlock(_component_item_list, { key: item }, {
              default: _withCtx(() => [
                _createVNode(_component_core_values, { item: item }, null, 8, ["item"])
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_ion_label, { class: "divider-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('portfolio.divider.third')
                }, null, 8, _hoisted_10)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_carousel, {
              "items-to-show": 1.4,
              wrapAround: true
            }, {
              addons: _withCtx(() => [
                _createVNode(_component_navigation)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.randomIndex, (slide) => {
                  return (_openBlock(), _createBlock(_component_slide, { key: slide }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        style: _normalizeStyle({backgroundImage: this.getGalleryImage(slide)}),
                        class: "carousel__item gallery_item"
                      }, null, 4)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ion_label, { class: "divider-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('portfolio.divider.fourth')
                }, null, 8, _hoisted_13)
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('portfolio.content_second'), (item) => {
            return (_openBlock(), _createBlock(_component_item_list, { key: item }, {
              default: _withCtx(() => [
                _createVNode(_component_core_values, {
                  class: _normalizeClass(_ctx.smaller-_ctx.title),
                  item: item
                }, null, 8, ["class", "item"])
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_ion_label, { class: "divider-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('portfolio.divider.fifth')
                }, null, 8, _hoisted_15)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                shape: "round",
                color: "dark",
                onclick: "window.open('https://www.instagram.com/cabanalibre/')"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.logoInstagram,
                    size: "large"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                shape: "round",
                color: "dark",
                onclick: "window.open('https://www.facebook.com/CabanaLibreInt')"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.logoFacebook,
                    size: "large"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                shape: "round",
                color: "dark",
                onclick: "window.open('https://soundcloud.com/cabanalibre')"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.logoSoundcloud,
                    size: "large"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                shape: "round",
                color: "dark",
                onclick: "window.open('https://www.twitch.tv/cabanalibre')"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.logoTwitch,
                    size: "large"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_16, [
            _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("p", {
              innerHTML: _ctx.$t('portfolio.copyright')
            }, null, 8, _hoisted_17)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}