import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 1,
  style: {"height":"20px"}
}
const _hoisted_3 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_button_loading = _resolveComponent("button-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_loading, {
      "is-open": this.loaderShown,
      message: ""
    }, null, 8, ["is-open"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", null, [
        (this.invalidCredentials)
          ? (_openBlock(), _createBlock(_component_ion_text, {
              key: 0,
              color: "danger",
              innerHTML: _ctx.$t('auth.signin.invalid_credentials')
            }, null, 8, ["innerHTML"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2)),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              position: "floating",
              color: this.emailDanger,
              innerHTML: _ctx.$t('auth.email')
            }, null, 8, ["color", "innerHTML"]),
            _createVNode(_component_ion_input, {
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateInput())),
              onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
              disabled: this.prefilledEmail,
              type: "email"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }),
        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              position: "floating",
              color: this.passwordDanger,
              innerHTML: _ctx.$t('auth.password')
            }, null, 8, ["color", "innerHTML"]),
            _createVNode(_component_ion_input, {
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
              onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateInput())),
              onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
              type:  this.showPassword ? 'text' : 'password'
            }, null, 8, ["modelValue", "type"]),
            _createVNode(_component_ion_button, {
              class: "show_password",
              slot: "end",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (this.showPassword = !this.showPassword))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: this.showPassword ? _ctx.eyeOffOutline : _ctx.eyeOutline
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_button, {
          mode: "md",
          class: "forgotten_password",
          color: this.passwordDanger || this.invalidCredentials ? 'danger' : '',
          size: "small",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (this.openForgottenPasswordModal())),
          innerHTML: _ctx.$t('auth.signin.forgotten_password')
        }, null, 8, ["color", "innerHTML"]),
        _withDirectives(_createVNode(_component_ion_button, {
          mode: "md",
          color: "light",
          class: "create_account",
          size: "small",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (this.$router.push({ path: this.$t('toolbar.path_signup'), query: {redirect: this.$route.query.redirect} }))),
          innerHTML: _ctx.$t('auth.signin.create_account')
        }, null, 8, ["innerHTML"]), [
          [_vShow, this.createAccount]
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_button_loading, {
            onClick: _cache[9] || (_cache[9] = ($event: any) => (this.submit())),
            color: this.styling.buttonColor ? this.styling.buttonColor : this.buttonDanger,
            isLoading: this.loaderShown,
            text: this.styling.buttonText
          }, null, 8, ["color", "isLoading", "text"])
        ])
      ])
    ], 512), [
      [_vShow, !this.isAuthorized || this.unAuthorized]
    ])
  ], 64))
}