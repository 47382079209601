import { MutationTypes } from './mutations'
import axios from 'axios'
import { ScanStatuses } from '../enums';
import { useToast } from "vue-toastification";

export enum ActionTypes {
    search = "SEARCH",
    selectTable = "SELECT_TABLE",
    scan = "SCAN",
    getRegistrations = "GET_REGISTRATIONS",
    getPayments = "GET_PAYMENTS",
    getTickets = 'GET_TICKETS',
    getUsers = "GET_USERS",
    getNewUsers = "GET_NEW_USERS",
    eventApproval = "EVENT_APPROVAL",
    getCheckoutPages = "GET_CHECKOUT_PAGES",
    assignTicket = "ASSIGN_TICKET",
    editEvent = 'EDIT_EVENT',
    getDiscounts = "GET_DISCOUNTS",
    createDiscount = "CREATE_DISCOUNT",
    updateDiscount = "UPDATE_DISCOUNT",
    deleteDiscount = "DELETE_DISCOUNT",
    removeTickets = "remove_TICKETS",
}

export const actions = {
    async [ActionTypes.search]({ commit }, value) {
        commit(MutationTypes.search, value);
    },
    async [ActionTypes.selectTable]({ commit }, table) {
        commit(MutationTypes.table, table);
    },
    async [ActionTypes.scan]({ commit }, data) {
        const path = process.env.VUE_APP_BASE_URL + '/internal/scan/';
        await axios.post(path, data).then((response) => {
            if (response.status == 200) {
                commit(MutationTypes.scanData, response.data);
                commit(MutationTypes.scanStatus, ScanStatuses.SUCCESS);
            } else {
                commit(MutationTypes.scanData, response.data);
                commit(MutationTypes.scanStatus, ScanStatuses.SCANNED);
            }
        }).catch((error) => {
            if (error.response.status == 400) {
                commit(MutationTypes.scanData, Object);
                commit(MutationTypes.scanStatus, ScanStatuses.INVALID);
            } else {
                useToast().error("Something went wrong!\nRefresh the window and try again.");
            }
        });
    },
    async [ActionTypes.getRegistrations]({ commit }, token) {
        commit(MutationTypes.loading, true);
        const path = process.env.VUE_APP_BASE_URL + '/registrations/' + token;
        await axios.get(path, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.registrations, response.data);
            commit(MutationTypes.loading, false);
        });
    },
    async [ActionTypes.getPayments]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL + '/payments/' + token;
        commit(MutationTypes.loading, true);
        await axios.get(path, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.payments, response.data);
            commit(MutationTypes.loading, false);
        });
    },
    async [ActionTypes.getTickets]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL + '/internal/tickets/' + token;
        commit(MutationTypes.loading, true);
        await axios.get(path, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.tickets, response.data);
            commit(MutationTypes.loading, false);
        });
    },
    async [ActionTypes.getUsers]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL + '/users/' + token;
        commit(MutationTypes.loading, true);
        await axios.get(path, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.users, response.data);
            commit(MutationTypes.loading, false);
        });
    },
    async [ActionTypes.eventApproval]({ commit }, { token, data }) {
        const path = process.env.VUE_APP_BASE_URL + '/registration_approval/' + token;
        await axios.post(path, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            useToast().success("User approved!");
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.");
        });
    },
    async [ActionTypes.getCheckoutPages]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL + '/checkout_pages/' + token;
        await axios.get(path, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.checkoutPages, response.data);
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.");
        });
    },
    async [ActionTypes.assignTicket]({ commit }, { token, data }) {
        const path = process.env.VUE_APP_BASE_URL + '/assign_ticket/' + token;
        await axios.post(path, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            useToast().success("Assigned!");
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.");
        });
    },
    async [ActionTypes.editEvent]({ commit }, { token, data }) {
        const path = process.env.VUE_APP_BASE_URL + '/internal/edit_event/' + token;
        await axios.post(path, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            useToast().success("Events updated!");
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.");
        });
    },
    async [ActionTypes.getDiscounts]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL + '/internal/discounts/' + token;
        commit(MutationTypes.loading, true);
        await axios.get(path, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.discounts, response.data);
            commit(MutationTypes.loading, false);
        });
    },
    async [ActionTypes.createDiscount]({ commit }, { token, data }) {
        const path = process.env.VUE_APP_BASE_URL + '/internal/create_discount/' + token;
        await axios.post(path, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.addDiscount, response.data);
            useToast().success("Discount created successfully!");
        }).catch((error) => {
            useToast().error("Failed to create discount. Please try again.");
        });
    },
    async [ActionTypes.updateDiscount]({ commit }, { token, id, data }) {
        const path = process.env.VUE_APP_BASE_URL + '/internal/update_discount/' + token + '/' + id;
        await axios.put(path, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            commit(MutationTypes.updateDiscount, response.data);
            useToast().success("Discount updated successfully!");
        }).catch((error) => {
            useToast().error("Failed to update discount. Please try again.");
        });
    },
    async [ActionTypes.deleteDiscount]({ commit }, { token, id }) {
        const path = `${process.env.VUE_APP_BASE_URL}/internal/delete_discount/${token}/${id}`;
        try {
            const response = await axios.delete(path, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.status === 200) {
                commit(MutationTypes.deleteDiscount, id);
            }
            return response;  // Return the full response
        } catch (error) {
            console.error("Error in deleteDiscount action:", error);
            throw error;  // Throw the error to be caught in the component
        }
    },
    async [ActionTypes.removeTickets]({ commit, dispatch }, { token, data }) {
        const path = `${process.env.VUE_APP_BASE_URL}/internal/remove_tickets/${token}`;
        try {
            const response = await axios.post(path, data, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            useToast().success("Ticket removed successfully!");
            return response;  // Return the full response
        } catch (error) {
            useToast().error("Failed to remove ticket. Please try again.");
            console.error("Error in deleteDiscount action:", error);
            throw error;  // Throw the error to be caught in the component
        }
    },
};
