<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import FabMenu from '@/modules/common/components/FabMenuComponent.vue'
import { mapGetters, mapActions } from 'vuex'
import { Roles } from '@/modules/auth/enums'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    FabMenu,
  },
  data() {
    return {
      isMenuOpen: false,
      routerClick: true,
      closeFab: false,
      authorizedPaths: [this.$t('toolbar.path_tickets'), this.$t('toolbar.path_account'), this.$t('toolbar.path_scanner'), this.$t('toolbar.path_checkout'), this.$t('toolbar.path_tokens')],
      internalPaths: [this.$t('toolbar.path_scanner')]

    }
  },
  computed: {
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED'
    }),
  },
  methods: {
    ...mapActions({
      setToken: 'SET_TOKEN',
      verifyToken: 'VERIFY_TOKEN'
    }),
    async authorizedPages() {
      var currentRoute = this.$router.currentRoute.value.path
      currentRoute = currentRoute.slice(-1) == '/' ? currentRoute.slice(0,-1) : currentRoute
      if (!this.isAuthorized && this.authorizedPaths.includes(currentRoute) && !this.route?.paymentId) {
        this.$router.push({ path: this.$t('toolbar.path_signin'), query: {redirect: this.$route.fullPath} })
      }
      if (this.isAuthorized && this.internalPaths.includes(currentRoute)) {
        await this.verifyToken(this.auth.token)
        if (this.auth.role == Roles.USER) {
          this.$router.push({ path: this.$t('toolbar.path_portfolio') })
        }
      }
    },
    routerClicked() {
      if (this.isMenuOpen) {
       this.closeFab = true
        this.isMenuOpen = false
        setTimeout(() => {
          this.closeFab = false
        }, 50) 
      }
    }
  },
  watch: {
    'auth.token': function() {
      if (this.auth.token) {
        this.$cookies.set('access_token',this.auth.token)
      } else {
        this.$cookies.remove('access_token')
      }
    },
    isAuthorized() {
      if (this.isAuthorized) {
        this.setToken({token: this.auth.token, role: this.auth.role}) 
      }
      this.authorizedPages()
    },
    $route (to, from){
      this.authorizedPages()
      if (to.path == '/sign-up' && from.path == '/sign-in' && this.isAuthorized) {
        this.$router.push({ path:'/events' })
      } else if (to.path == '/sign-in' && from.path == '/sign-up' && this.isAuthorized) {
        this.$router.push({ path:'/events' })
      } else if (to.path == '/forgotten-password' && from.path == '/sign-in') {
        this.$router.push({ path:'/events' })
      }
    }
  },
  mounted() {
    const cookie = this.$cookies.get('access_token')
    if (cookie) {
      this.setToken({token: cookie, role: this.auth.role}) 
    }
    this.authorizedPages()
  }
});
</script>

<template>
  <ion-app mode="md">
    <fab-menu class="toolbar" :isAuthorized="this.isAuthorized" :forceClose="this.closeFab" @fabClick="this.isMenuOpen = !this.isMenuOpen"></fab-menu>
    <div style="height: 100%" @click="this.routerClicked()">
      <ion-router-outlet class="transition" :class="this.isMenuOpen ? 'blur' : ''" />
    </div>
  </ion-app>
</template>

<style scope>
  ion-toolbar {
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    top: 0;
    background-color: var(--toolbar-background-color);
    box-shadow: 5px 5px 5px 6px var(--toolbar-background-color);
    --background: var(--toolbar-background-color)
  }
  ion-content{
    --background: var()
  }
  ion-router-outlet {
    margin-top: 80px
  }
  .blur {
    filter: blur(5px);
    transition: 200ms;
    pointer-events: none;
  }
  .transition {
    transition: 100ms;
  }
</style>