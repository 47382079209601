import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue';
import VueCookies from 'vue-cookies'


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';



/* Theme variables */
// import './theme/variables_bosc.css';
import './theme/style.css';

import { createI18n } from 'vue-i18n'
import { CopyCabanaLibre } from './copywriting/CopyCabanaLibre'
import { CopyBuenaOnda } from './copywriting/CopyBuenaOnda'

// TODO: Make more scalable
const i18n = process.env.VUE_APP_SITE == 'CabanaLibre' ? createI18n(CopyCabanaLibre) : createI18n(CopyBuenaOnda)
process.env.VUE_APP_SITE == 'CabanaLibre' ? require('./theme/variables.css') : require('./theme/variables_bosc.css') 

import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate";

import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import './theme/vue-toastification.css';

// Google Tag Manager
import { createGtm } from '@gtm-support/vue-gtm';

import { ticketsState as ticketsState , 
        mutations as ticketsMutations , 
        actions as ticketsActions , 
        ticketsGetters as ticketsGetters } from '@/modules/tickets/business'

import { state as eventsState, 
        mutations as eventsMutations, 
        actions as eventsActions, 
        getters as eventsGetters } from '@/modules/events/business'


import { state as checkoutState, 
        mutations as checkoutMutations, 
        actions as checkoutActions, 
        getters as checkoutGetters } from '@/modules/checkout/business'

import { state as authState, 
        mutations as authMutations, 
        actions as authActions, 
        getters as authGetters } from '@/modules/auth/business'

import { state as portfolioState, 
        mutations as portfolioMutations, 
        actions as portfolioActions, 
        getters as portfolioGetters } from '@/modules/portfolio/business'

import { state as internalState, 
        mutations as internalMutations, 
        actions as internalActions, 
        getters as internalGetters } from '@/modules/internal/business'

const store = createStore({
  modules: {
    auth: {
      state: authState,
      mutations: authMutations,
      actions: authActions,
      getters: authGetters,
    },
    events: {
      namespaced: true,
      state: eventsState,
      mutations: eventsMutations,
      actions: eventsActions,
      getters: eventsGetters
    },
    tickets: {
      namespaced: true,
      state: ticketsState,
      mutations: ticketsMutations,
      actions: ticketsActions,
      getters: ticketsGetters,
    },
    checkout: {
      namespaced: true,
      state: checkoutState,
      mutations: checkoutMutations,
      actions: checkoutActions,
      getters: checkoutGetters,
    },
    portfolio: {
      namespaced: true,
      state: portfolioState,
      mutations: portfolioMutations,
      actions: portfolioActions,
      getters: portfolioGetters,
    },
    internal: {
      namespaced: true,
      state: internalState,
      mutations: internalMutations,
      actions: internalActions,
      getters: internalGetters,
    }
  }
})



const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(router)
  .use(i18n)
  .use(VueCookies)
  .use(Toast, { position: POSITION.BOTTOM_CENTER })
  .use(
    createGtm({
      id: "GTM-P37C9HMR",
      vueRouter: router
    })
  );
  
  
router.isReady().then(() => {
  app.mount('#app');
});