<script lang="ts">
import { IonPage, IonItem, IonLabel, IonInput, IonIcon, IonLoading, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { Site } from '../../enums'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import UserExistsModal from '../components/modal/UserExistsModal.vue'
import { informationCircleOutline } from 'ionicons/icons';
import { eyeOutline, eyeOffOutline } from "ionicons/icons";


export default defineComponent({
name: 'CreateAccountPage',
  components: {
    IonPage,
    IonItem,
    IonIcon,
    IonLabel,
    IonInput,
    IonLoading,
    ButtonLoading
  },
  data() {
    return {
      loaderShown: false,
      submitted: false,
      nameDanger: "",
      surnameDanger: "",
      documentDanger: "",
      emailDanger: "",
      passwordDanger: "",
      passwordDangerMessage: "",
      buttonDanger: "light",
      name: "",
      surname: "",
      document: "",
      phone: "",
      instagram: this.$route.query.instagram ? this.$route.query.instagram as string : "",
      email: this.$route.query.email ? this.$route.query.email as string : "",
      password: "",
      noInstagram: false,
      showPassword: false,
      Site
    }
  },
  setup() {
    return {
      informationCircleOutline,
      eyeOutline, 
      eyeOffOutline
    }
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized,
      userExists: GettersTypes.userExists,
      prefillData: GettersTypes.prefillData
    }),
  },
  methods: {
    ...mapActions({
      createAccount: ActionTypes.createAccount,
      checkEmail: ActionTypes.checkEmail,
      getPrefillData: ActionTypes.getPrefillData
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        const parsedInstagram = this.instagram[0] == "@" ? this.instagram.substring(1) : this.instagram
        const instagram = this.noInstagram ? parsedInstagram : "@" + parsedInstagram
        await this.createAccount({email: this.email, password: this.password, name: this.name, surname: this.surname, instagram: instagram, document: this.document, phone: this.phone})
        this.loaderShown = false
        if (!this.userExists) {
          this.$emit('signedUp')
          this.$router.push({ path: this.$t('toolbar.path_events') })
        }
      }
    },
    validateEmail(email) {
      var valid = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      this.emailDanger = valid ? '' : 'danger'
      return valid
    },
    validateInput() {
      if (this.submitted) {
        this.nameDanger = this.name ? '' : 'danger'
        this.surnameDanger = this.surname ? '' : 'danger'
        this.instagramDanger = this.instagram ? '' : 'danger'
        this.documentDanger = this.document ? '' : 'danger'
        this.emailDanger = this.validateEmail(this.email) ? '' : 'danger'
        this.passwordDanger = this.password.length >= 8 ? '' : 'danger'
        this.passwordDangerMessage = this.password ? '' : 'danger'
        this.buttonDanger = (this.name && this.surname && this.instagram && this.document && this.password.length >= 8 && this.validateEmail(this.email))  ? 'light' : 'danger'
        return (this.name && this.surname && this.instagram && this.document && this.password.length >= 8 && this.validateEmail(this.email))
      }
    },
    checkExistingEmail() {
      this.validateEmail(this.email)
      if (this.email) {
        this.checkEmail(this.email)
      }
    },
    async openUserExistsModal() {
      const modal = await modalController.create({
        component: UserExistsModal,
        componentProps: {prefilledEmail: this.email, site: this.userExists}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
    async fillData(hashId) {
      this.loaderShown = true
      this.submitted = true
      await this.getPrefillData(hashId)
      this.email = this.prefillData.email
      this.name = this.prefillData.name
      this.surname = this.prefillData.surname
      this.document = this.prefillData.document
      this.validateInput()
      this.loaderShown = false
    }
  },
  watch: {
    userExists() {
      if (this.userExists && this.userExists != 'prefill') {
        this.openUserExistsModal()
      }
    }
  },
  mounted() {
    const hashId = this.$route.query.hashId as string
    if (hashId) {
      this.fillData(hashId)
    }
  },
});
</script>


<template>
  <ion-page>
    <ion-content>
        <ion-loading
          :is-open="this.loaderShown"
          message=""
        ></ion-loading>
      <ion-card v-if="!this.loaderShown">
        <ion-header class="ion-no-border">
          <ion-title v-html="$t('auth.signup.title')"></ion-title>
          <br>
          <ion-subtitle v-html="$t('auth.signup.subtitle')"></ion-subtitle>
        </ion-header>

        <div class="banner" v-show="this.userExists == 'prefill'">
          <ion-icon :icon="informationCircleOutline" size="large"></ion-icon>
          <ion-text  v-html="$t('auth.prefill_banner')"></ion-text>
        </div>
        <div class="container">

          <div class="grid_1">
          <ion-subtitle v-html="$t('auth.signup.credentials')"></ion-subtitle>
           <ion-item>
              <ion-label position="floating" :color="this.emailDanger">
                {{ $t('auth.email') }}
                <ion-text class="danger-message" v-show="this.userExists == 'prefill'" color="warning" v-html="$t('auth.signup.email_registered')"></ion-text>
                <ion-text class="danger-message" v-show="this.emailDanger" color="danger" v-html="$t('auth.signup.email_validation')"></ion-text>
                <ion-text class="danger-message" v-show="this.userExists && this.userExists != 'prefill'" color="danger" v-html="$t('auth.signup.email_exists').replace('*site*', Site[this.userExists]).replace('*url*', this.$router.name).replace('*email*', this.email)"></ion-text>
              </ion-label>
              <ion-input v-model="email" @change="validateInput(); checkExistingEmail()" @keyup.enter="submit()" type="email"></ion-input>
            </ion-item>
          </div>
          
          <div class="grid_2">
            <ion-item>
              <ion-label position="floating" :color="this.nameDanger" v-html="$t('auth.name')"></ion-label>
              <ion-input v-model="name" style="text-transform:capitalize;" @change="validateInput()" @keyup.enter="submit()"></ion-input>
            </ion-item>
          </div>

          <div class="grid_3">
            <ion-item>
              <ion-label position="floating" :color="this.surnameDanger" v-html="$t('auth.surname')"></ion-label>
              <ion-input v-model="surname" style="text-transform:capitalize;" @change="validateInput()" @keyup.enter="submit()"></ion-input>
            </ion-item>
          </div>

          <div class="grid_4">
            <ion-item>
              <ion-label position="floating" :color="this.instagramDanger">{{ this.noInstagram ? $t('auth.referral') :  $t('auth.instagram') }}</ion-label>
              <ion-input class="instagram_input" v-model="instagram" @change="validateInput()" @keyup.enter="submit()"></ion-input>
              <ion-button :color="this.noInstagram ? 'primary' : 'danger'" class="no_instagram" @click="this.noInstagram = !this.noInstagram">{{ this.noInstagram ?  $t('auth.short_instagram') : $t('auth.short_no_instagram') }}</ion-button>
            </ion-item>
          </div>

          <div class="grid_5">
            <ion-item>
              <ion-label position="floating" :color="this.documentDanger">{{ $t('auth.document') }}</ion-label>
              <ion-input class="document_input" v-model="document" @change="validateInput()" @keyup.enter="submit()"></ion-input>
            </ion-item>
          </div>

          <div class="grid_6">
            <ion-item>
              <ion-label position="floating" v-html="$t('auth.phone') + ' ' + $t('auth.optional')"></ion-label>
              <ion-input v-model="phone" @change="validateInput()" @keyup.enter="submit()" type="tel"></ion-input>
            </ion-item>
          </div>

          <div class="grid_7">
           <ion-item>
              <ion-label position="floating" :color="this.passwordDanger"> 
                {{ $t('auth.password') }}
                <ion-text class="danger-message" v-if="this.passwordDanger" color="danger" v-html="$t('auth.signup.password_validation')"></ion-text>
              </ion-label>
              <ion-input v-model="password" @change="validateInput()" @keyup.enter="submit()" :type=" this.showPassword ? 'text' : 'password'"></ion-input>
              <ion-button class="show_password" slot="end" @click="this.showPassword = !this.showPassword">
                  <ion-icon :icon="this.showPassword ? eyeOffOutline : eyeOutline"></ion-icon>
              </ion-button>
            </ion-item>
          </div>
        </div>
        <div class="button">
          <button-loading :color="this.buttonDanger" @click="this.submit()" :isLoading="this.loaderShown" :text="$t('auth.signup.button_submit')"></button-loading>
        </div>
      </ion-card>
      
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-card {
  margin: 20px auto;
  max-width: 780px;
  padding: 50px;
  --box-shadow: none;
  background: var(--ion-color-white)
}

ion-item {
  --background: var(--ion-color-white)
}

ion-header {
  margin-top: 20px;
  margin-bottom: 50px;
}

ion-title {
  color: var(--ion-color-dark);
  position: relative;
  padding: 0 5px;
  text-align: left
}

ion-button {
  padding: 0 20px;
  float: right
}

.button {
  text-align: center
}

.danger-message {
  font-style: italic;
}

.edit {
  position: absolute;
  top: 20px;
  right: 40px;
  height: 40px;
  width: 40px;
  z-index: 10;
  --padding-start: 0;
  --padding-end: 0;
}

.edit ion-icon {
  margin-left: 2px;
  min-width: 20px;
}

.confirm {
  justify-content: right;
  margin-top: 50px;
}

.confirm ion-button {
  margin: 0 10px;
}

.delete_account {
  --background: transparent; 
  --color: var(--ion-color-dark);
  padding: 0;
  margin-top: 20px;
  border: none;
  --box-shadow: none;
}

.no_instagram {
    opacity:0;
    position: absolute;
    margin: 20px;
    right: 0;
    z-index: 2;
}

ion-input:focus + .no_instagram {
    opacity:1;
}

.instagram_input.has-focus + .no_instagram {
   opacity:1;
}

.instagram_input {
  transition: 1000;
}

.banner {
  display: flex;
  width: 100%;
  padding: 10px;
  background-color: var(--ion-color-light-shade);
  border-radius: 5px;
  margin-bottom: 10px;
}

.banner ion-icon {
  margin: auto;
  width: 80px;
  
}


.container {  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: calc(1fr + 20px) 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    "grid_1 grid_1"
    "grid_2 grid_3"
    "grid_4 grid_4"
    "grid_5 grid_5"
    "grid_6 grid_6"
    "grid_7 grid_7";
  margin-bottom: 50px
}

.grid_1 { grid-area: grid_1; }

.grid_2 { grid-area: grid_2; }

.grid_3 { grid-area: grid_3; }

.grid_4 { grid-area: grid_4; }

.grid_5 { grid-area: grid_5; }

.grid_6 { grid-area: grid_6; }

.grid_7 { grid-area: grid_7; }

.grid-title { grid-area: grid-title }


.show_password {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  --background: none;
  --box-shadow: none;
  --color: var(--ion-color-dark-shade);
  z-index: 10;
}

@media (max-width: 780px) { 
  ion-card {
    min-height: 800px;
    width: 90%;
    padding: 40px 20px;
  }

  ion-button {
    float: none
  }

  .danger-message {
    font-size: 12px;
  }
  .container {  
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 0.7fr 0.7fr 40px 0.5fr 0.5fr 0.5fr 0.5fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "grid_1"
      "grid_2"
      "grid_3"
      "grid-title"
      "grid_4"
      "grid_5"
      "grid_6"
      "grid_7";
  }
  .banner ion-icon {
    margin-left: 3px;
    margin-right: 10px;
  }

}
</style>
