<script lang="ts">
import { IonContent, IonPage, IonList, IonLoading } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import QuantitySelector from '../components/QuantitySelectorComponent.vue'
import PurchasedTokens from '../components/PurchasedTokensComponent.vue'
import StripePage from './StripePage.vue'

export default defineComponent({
name: 'TokensPage',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonLoading,
    QuantitySelector,
    PurchasedTokens,
    StripePage
  },
  data() {
    return {
      loaderShown: true,
      totalPriceServiceFee: 0,
      stripeItems: [{price: '', quantity: 0}],
      stripePayload: {},
      paymentHash: (Math.random() + 1).toString(36).substr(4, 10),
      discount: 0,
      quantity: 0
    }
  },
  computed: {
    ...mapGetters('checkout',{
      checkout: GettersTypes.checkout,
      basket: GettersTypes.basket,
      totalPrice: GettersTypes.totalPrice,
      loaderShown: GettersTypes.loaderShown,
      contact: GettersTypes.getContact
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED'
    }),
  },
  methods: {
    ...mapActions('checkout',{
      getCheckout: ActionTypes.getCheckout,
      incrementBasket: ActionTypes.incrementBasket,
      clearBasket: ActionTypes.clearBasket,
      registerPayment: ActionTypes.registerPayment,
    }),
    ...mapActions({
      confirmPayment: 'CONFIRM_PAYMENT',
    }),
    async getItemById() {
      this.loaderShown = true
      const pageId = this.$route.query.id as string
      await this.getCheckout(pageId ? pageId : 1)
      this.loaderShown = false
    },
    async submit() {
      if (!this.loaderShown) {
        this.loaderShown = true
        const basket = this.basket;
        const paymentHash = this.paymentHash
        const user = this.auth.token ? this.auth.token : this.$route.query.hashId
        const eventId = this.checkout.eventId
        const pageId = this.$route.query.id ? this.$route.query.id : 1 // HARDCODED
        await this.registerPayment({basket, paymentHash, user, eventId, pageId})
        this.loaderShown = false
        if (this.contact.email) {
          this.redirectToStripe()
        }
      }
    },
    redirectToStripe() {
      // this.stripeItems = [{price: this.checkout.serviceFee.priceId as string, quantity: this.quantity > 10 ? 2 : 1}]
      this.stripeItems = [{price: this.checkout.serviceFee.priceId as string, quantity: 1}]
      // const stripeItems : object[] = [];
      const pageId = this.$route.query.id ? this.$route.query.id : 1
      var clone = structuredClone(this.basket)
      clone = clone.filter(el => el.itemId != 0)
      if (pageId == 1) {
        // this.stripeItems = [{price: this.checkout.serviceFee.priceId as string, quantity: this.quantity > 10 ? 2 : 1}]
        // var item = clone.filter(el => el.itemId == 2)
        // console.log('item',item)
        // const quantityFactor = 4;
        // const quantity = item.quantity * quantityFactor
        // var quantitySale = item.quantity >= 20 ? quantity - 18 : 
        //                   item.quantity >= 10 ? quantity - 8 :
        //                   item.quantity >= 6 ? quantity - 4 :
        //                   item.quantity >= 3 ? quantity - 2 :
        //                   quantity;
        // this.stripeItems.push({price: item.priceId, quantity: quantitySale})
        // console.log('cln',clone)
        // clone.forEach((item) => this.stripeItems.push({price: item.priceId, quantity: quantitySale}))
        const quantityFactor = 2;
        clone.forEach((item) => {
          var quantity = item.quantity * quantityFactor
          if (item.itemId == 2) {
            var quantitySale = item.quantity >= 40 ? quantity - 10 : 
                              item.quantity >= 20 ? quantity - 4 :
                              item.quantity >= 16 ? quantity - 2 :
                              quantity;
            this.stripeItems.push({price: item.priceId, quantity: quantitySale})
          } else {
            this.stripeItems.push({price: item.priceId, quantity: quantity})
          }
        })
      } else {
        var sum = 0
        clone.forEach(element => {
          sum += element.quantity;
        });
        this.stripeItems = [{price: this.checkout.serviceFee.priceId as string, quantity: sum}]
        clone.forEach((item) => this.stripeItems.push({price: item.priceId, quantity: item.quantity}))
      }
      
      
      // this.stripeItems.push(stripeItems)
      this.stripePayload = {
        basket: this.stripeItems,
        eventId:this.checkout.eventId,
        paymentHash: this.paymentHash,
        stripePk:this.checkout.stripePk,
        serviceFee:this.checkout.serviceFee,
        email:this.contact.email,
        instagram:this.contact.instagram,
        openRegistration: this.$route.query.hashId ? true : false,
        token: (this.$route.query.id == '1' || !this.$route.query.id) ? true : false
      }
    },
    clearUrlPath() {
      history.pushState(
        {},
        "",
        '/tokens'
      )
    }
  },
  mounted() {
    this.getItemById()
    const paymentId = this.$route.query.paymentId as string
    if (paymentId) {
      this.confirmPayment(paymentId)
      this.clearUrlPath()
    }
  },
  watch: {
    checkout() {
      this.loaderShown = false
    },
    $route (to, from){
      if (to.path==this.$t('toolbar.path_checkout') || to.path==this.$t('toolbar.path_tokens')) {
        this.getItemById()
        this.clearBasket()
        // this.discount = 0
        // this.totalPriceServiceFee = 0
      }
    },
    totalPrice() {
      if (this.totalPrice >= this.checkout.serviceFee.price) {
        const pageId =  this.$route.query.id ? this.$route.query.id as string : '1'
        var cloneBasket = structuredClone(this.basket)
        this.quantity = 0
        cloneBasket.forEach(element => {
          this.quantity += element.quantity;
        });
        if (pageId == '1' && cloneBasket.filter(e => e.itemId == 2)[0]) {
          this.discount = cloneBasket.filter(e => e.itemId == 2)[0].quantity >= 40 ? + 10 : 
                          cloneBasket.filter(e => e.itemId == 2)[0].quantity >= 20 ? + 4 : 
                          cloneBasket.filter(e => e.itemId == 2)[0].quantity >= 16 ? + 2 :
                          0;
          
          // var multiplyServiceFee = (this.quantity > 10) ? 2 : 1
          var multiplyServiceFee = 1
          this.totalPriceServiceFee = this.totalPrice + this.checkout.serviceFee.price * multiplyServiceFee - this.discount
        } else {
          this.totalPriceServiceFee = this.totalPrice + this.checkout.serviceFee.price * this.quantity
        }
      } else {
        this.totalPriceServiceFee = 0
      }
    },
    // 'contact.email': function() {
    //   if (this.contact.email) {
    //     this.redirectToStripe()
    //   }
    // }
  }
});
</script>


<template>
  <ion-page>
      <ion-loading
      :is-open="this.loaderShown"
      message=""
    ></ion-loading>
    <ion-content>
      <div class="container" v-if="!this.loaderShown" fullscreen="false">
          <h1 v-html="this.checkout.title"></h1>
          <br>
          <ion-subtitle v-html="this.checkout.subtitle"></ion-subtitle>
          
          <ion-list lines="none" v-if="this.$route.path.includes('tokens')">
            <ion-item v-for="item in checkout.items" :key="item.id">
              <quantity-selector :item="item" :tokens="true"></quantity-selector>
            </ion-item>
          </ion-list>
          
          <form>
            <div class="total-container">
              <!-- <p class="service-fee" v-html="((this.$route.query.id == 1 || !this.$route.query.id) ? $t('checkout.service_fee') : $t('checkout.service_fee_item')) + ' ' + this.checkout.serviceFee.price * (this.quantity > 10 ? 2 : 1) + $t('checkout.currency')"> </p> -->
              <p class="service-fee" v-html="((this.$route.query.id == 1 || !this.$route.query.id) ? $t('checkout.service_fee') : $t('checkout.service_fee_item')) + ' ' + this.checkout.serviceFee.price + $t('checkout.currency')"> </p>
              <p v-if="this.discount > 0" class="discount"><b>Discount -{{ this.discount + $t('checkout.currency') }}</b></p>
              <div class="total">
                  <h1 v-html="$t('checkout.total_price')"></h1>
                  <h1 ref="totalPrice" v-html="this.totalPriceServiceFee + $t('checkout.currency')"></h1>
              </div>
              <hr>
            </div>
            <br>
          
            <div class="stripe-button">
              <ion-button color="dark" size="large" @click="submit()" :disabled="this.checkout.disabled || this.totalPriceServiceFee == 0">{{this.checkout.disabled ? $t('checkout.button_disabled') : $t('checkout.button_confirm')}}</ion-button>
            </div>
          </form>
        </div>
        <stripe-page v-if="Object.keys(this.stripePayload).length != 0" :payload="this.stripePayload"></stripe-page>
        <purchased-tokens v-if="!this.loaderShown && (this.$route.query.id == 1 || !this.$route.query.id)" class="tokens"></purchased-tokens>
     </ion-content>
  </ion-page>
</template>

<style scoped>
ion-item {
  padding-inline-start: -10px; 
}

.tokens {
  margin-bottom: 50px;
}

hr {
  margin: 0px 5% !important;
  border: none;
  height: px;
  background-color: black;
}
.total-container {
  margin: auto;
  max-width: 400px;
}

.total {
  display: flex;
  margin: 0 auto 0 5%;
  height: 40px;
}

.total h1 {
  margin:0 
}

.total h1:nth-child(2) {
  margin: 0;
  margin-right: 5%;
  margin-left: auto;
  text-align: right;
}


.service-fee {
  font-size: calc(0.04 * var(--viewport-width)) ;
  text-align: right;
  margin: 50px 5% 0 5%;
}

.discount {
  font-size: calc(0.04 * var(--viewport-width)) ;
  text-align: right;
  margin: 5px 5% 0 5%;
}



ion-title {
  font-size: 32px
}


.container {
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  max-width: 720px;
  text-align: center;
}
.stripe-button{
  padding-top: 20px
}

.avatar {
  height: 200px;
  width: 200px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
}

.avatar-circle {
  float: left;
  padding: 10%
}

@media (max-width: 780px) { 
  ion-card {
    padding: 20px;
    margin: 20px auto;
  }
}

</style>