import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import EventsPage from '../modules/events/presentation/views/EventsPage.vue'
import TicketsPage from '../modules/tickets/presentation/views/TicketsPage.vue'
import CheckoutPage from '../modules/checkout/presentation/views/CheckoutPage.vue'
import TokensPage from '../modules/checkout/presentation/views/TokensPage.vue'
import StripePage from '../modules/checkout/presentation/views/StripePage.vue'
import SignInPage from '../modules/auth/presentation/views/SignInPage.vue'
import SignUpPage from '../modules/auth/presentation/views/SignUpPage.vue'
import CreateAccountPage from '../modules/auth/presentation/views/CreateAccountPage.vue'
import SignOutPage from '../modules/auth/presentation/views/SignOutPage.vue'
import UserDetailsPage from '../modules/auth/presentation/views/UserDetailsPage.vue'
import AccountInfoPage from '../modules/auth/presentation/views/AccountInfoPage.vue'
import ResetPasswordPage from '../modules/auth/presentation/views/ResetPasswordPage.vue'
import PortfolioPage from '../modules/portfolio/presentation/views/PortfolioPage.vue'
import ScannerPage from '../modules/internal/presentation/views/ScannerPage.vue'
import TablePage from '../modules/internal/presentation/views/TablePage.vue'
import QRPage from '../modules/common/views/QRPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/events'
  },
  {
    path: '/portfolio',
    redirect: '/events'
  },
  {
    path: '/home',
    name: 'Cabana Libre',
    component: PortfolioPage,
  },
  {
    path: '/sign-in',
    name: 'Sign in',
    component: SignInPage,
  },
  {
    path: '/sign-out',
    name: 'Sign out',
    component: SignOutPage,
  },
  {
    path: '/sign-up',
    name: 'Create Account',
    component: SignUpPage,
  },
  {
    path: '/create-account',
    name: 'Sign Up',
    component: CreateAccountPage,
  },
  {
    path: '/your-details',
    name: 'Your Details',
    component: UserDetailsPage,
  },
  {
    path: '/events',
    name: 'Events',
    component: EventsPage
  },
  {
    path: '/entrances',
    name: 'Entrances',
    component: TicketsPage
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutPage
  },
  {
    path: '/tokens',
    name: 'Tokens',
    component: TokensPage
  },
  {
    path: '/stripe',
    name: 'Stripe',
    component: StripePage
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountInfoPage,
  },
  {
    path: '/forgotten-password',
    name: 'Forgotten Password',
    component: ResetPasswordPage,
  },
  {
    path: '/internal/scanner',
    name: 'QR Scanner',
    component: ScannerPage,
  },
  {
    path: '/internal/admin/table',
    name: 'Admin Panel',
    component: TablePage,
  },
  {
    path: '/qr',
    name: 'QR',
    component: QRPage,
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
