import { MutationTree } from 'vuex'
import { State, state } from './state'
import { ScanData, Registrations, Payments, Tickets, Users, CheckoutPages, Discounts, Discount } from '../types'
import { ScanStatuses } from '../enums'


export enum MutationTypes {
    search = 'SEARCH',
    table = 'TABLE',
    scanData = 'SCAN_DATA',
    scanStatus = 'SCAN_STATUS',
    loading = 'LOADING',
    registrations = 'REGISTRATIONS',
    payments = 'PAYMENTS',
    tickets = 'TICKETS',
    users = "USERS",
    checkoutPages = "CHECKOUT_PAGES",
    discounts = 'DISCOUNTS',
    addDiscount = 'ADD_DISCOUNT',
    updateDiscount = 'UPDATE_DISCOUNT',
    deleteDiscount = 'DELETE_DISCOUNT',
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
    [MutationTypes.search](state: State, value: string) {
        state.search = value;
    },
    [MutationTypes.table](state: State, table: string) {
        state.table = table;
    },
    [MutationTypes.scanData](state: State, scanData: ScanData) {
        state.scanData = scanData;
    },
    [MutationTypes.scanStatus](state: State, scanStatus: ScanStatuses) {
        state.scanStatus.status = scanStatus;
    },
    [MutationTypes.loading](state: State, loading: boolean) {
        state.loading = loading;
    },
    [MutationTypes.registrations](state: State, registrations: Registrations) {
        state.registrations = registrations;
    },
    [MutationTypes.payments](state: State, payments: Payments) {
        state.payments = payments;
    },
    [MutationTypes.tickets](state: State, tickets: Tickets) {
        state.tickets = tickets;
    },
    [MutationTypes.users](state: State, users: Users) {
        state.users = users;
    },
    [MutationTypes.checkoutPages](state: State, checkoutPages: CheckoutPages) {
        state.checkoutPages = checkoutPages;
    },
    [MutationTypes.discounts](state: State, discounts: Discounts) {
        state.discounts = discounts;
    },
    [MutationTypes.addDiscount](state: State, discount: Discount) {
        state.discounts.unshift(discount);
    },
    [MutationTypes.updateDiscount](state: State, updatedDiscount: Discount) {
        const index = state.discounts.findIndex(d => d.id === updatedDiscount.id);
        if (index !== -1) {
            state.discounts.splice(index, 1, updatedDiscount);
        }
    },
    [MutationTypes.deleteDiscount](state: State, id: number) {
        state.discounts = state.discounts.filter(d => d.id !== id);
    },
}
