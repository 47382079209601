<script lang="ts">
import { IonContent, IonPage, IonLoading } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { QrcodeStream } from 'vue3-qrcode-reader'
import ScanResponse from '../components/ScanResponseComponent.vue'
import { ScanStatuses } from '../../enums'

export default defineComponent({
name: 'ScannerPage',
  components: {
    IonContent,
    IonPage,
    IonLoading,
    QrcodeStream,
    ScanResponse
  },
  data() {
    return {
      camera: 'auto',
      scanned: false,
      result: null,
      loaderShown: false,
      ScanStatuses
    }
  },
  computed: {
    ...mapGetters('internal',{
      scanData: GettersTypes.scanData,
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED',
    }),
  },
  methods: {
    ...mapActions('internal',{
      scan: ActionTypes.scan,
    }),
    async onDecode(decodedString) {
      if (decodedString) {
        this.camera = 'off'
        this.loaderShown = true
        this.scanned = false
        await this.scan({ticket_token: decodedString, access_token: this.auth.token})
        this.loaderShown = false
        setTimeout(() => {this.camera = 'auto'}, 1500)
      }
    },
    hideDetails() {
      this.scanned = false
    },
    async onInit (promise) {
      try {
        await promise
      } catch (e) {
        console.error(e)
      } finally {
        this.camera === "off"
      }
    },
    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  },
  watch: {
    scanData() {
      this.scanned = true
    }
  }
});
</script>

<template>
  <ion-page>
    <ion-loading
      :is-open="this.loaderShown"
      message=""
    ></ion-loading>
    <ion-content>
      <scan-response class="scan_response" :class="this.scanned ? 'scanned' : ''" @click="hideDetails()"></scan-response>
      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit"></qrcode-stream>
    </ion-content>
  </ion-page>  
</template>

<style scoped>

  .scan_response {
    transition: 0.5s;
    top: -400px;
    animation: hideResponse 0.2s forwards ease-out;
  }

 .scanned {
    animation: showResponse 0.2s forwards ease-out;
  }

  .scanned-hide {
    
  }

  @keyframes showResponse {
    0% {
      top: -400px;
    }
    80% {
      top: 40px
    }
    100% {
      top: 20px
    }
  }

  @keyframes hideResponse {
    0% {
      top: 20px
    }
    100% {
      top: -400px;
    }
  }

</style>
