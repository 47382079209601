import { MutationTypes } from '@/modules/tickets/business/mutations'
import axios from 'axios'
import { useToast } from "vue-toastification";

export enum ActionTypes {
  getTickets = 'GET_TICKETS',
  assignTickets = 'ASSIGN_TICKETS',
  createTicketToken = 'CREATE_TICKET_TOKEN',
  removeTicketToken = 'REMOVE_TICKET_TOKEN',
}


export const actions = {
  async [ActionTypes.getTickets]({ commit }, auth) {
    const path = process.env.VUE_APP_BASE_URL+'/tickets/' + auth;
    await axios.get(path).then((response) => {
      commit(MutationTypes.updateTickets, response.data)
    }).catch(function (error) {
      commit(MutationTypes.updateTickets, [])
    });
  },
  async [ActionTypes.assignTickets]({ commit }, {ticket_token,token}) {
    const path = process.env.VUE_APP_BASE_URL+'/assign_ticket/' + ticket_token + '/' + token;
    await axios.get(path).then((response) => {
      commit(MutationTypes.updateTickets, response.data)
      useToast().success("Your ticket is assigned!")
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      } else if (error.response.status == 402) {
        useToast().error("There's not any ticket left to assign!")
      } else if (error.response.status == 403) {
        useToast().error("You cannot assign the ticket to yourself!")
      } else if (error.response.status == 404) {
        useToast().error("Ticket not found!")
      } else {
        useToast().error("Something went wrong!\nRefresh the window and try again.")
      }
      commit(MutationTypes.updateTickets, [])
    });
  },
  async [ActionTypes.createTicketToken]({ commit }, {ticket_token, quantity, auth}) {
    const path = process.env.VUE_APP_BASE_URL+'/create_ticket_token/' + auth;
    await axios.post(path, {ticket_token: ticket_token, quantity: quantity}).then((response) => {
      commit(MutationTypes.createTicketToken, response.data?.ticket_token)
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      } else if (error.response.status == 402) {
        useToast().error("There's not any ticket left to assign!")
      } else if (error.response.status == 403) {
        useToast().error("You cannot assign the ticket to yourself!")
      } else if (error.response.status == 404) {
        useToast().error("Ticket not found!")
      } else {
        useToast().error("Something went wrong!\nRefresh the window and try again.")
      }
      commit(MutationTypes.updateTickets, [])
    });
  },
  async [ActionTypes.removeTicketToken]({ commit }) {
    commit(MutationTypes.createTicketToken, undefined)
  }
}

