import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button-loader" }
const _hoisted_2 = {
  key: 0,
  class: "dot-carousel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    size: this.size,
    color: this.color,
    mode: "md"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (this.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", {
        style: _normalizeStyle(this.isLoading ? 'opacity: 0' : '')
      }, _toDisplayString(_ctx.text), 5)
    ]),
    _: 1
  }, 8, ["size", "color"]))
}