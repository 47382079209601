<script lang="ts">
  import { IonContent, IonText, modalController} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { closeOutline } from 'ionicons/icons';

  export default defineComponent({
    name: 'TermsOfUseModal',
    props: {
        prefilledEmail: {
            type: String
        }
    },
    components: { 
      IonContent, 
      IonText
    },
    setup() {
      return {
        closeOutline
      }
    },
    methods: {
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      confirm() {
        return modalController.dismiss(this, 'confirm');
      },
    },
  });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel" size="large">
          <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    <ion-title v-html="$t('auth.terms')"></ion-title>
    </ion-toolbar>
  </ion-header>
    <ion-content>
      <ion-text v-html="$t('auth.terms_text')"></ion-text>
    </ion-content>
</template>

<style scoped>
ion-title {
    margin: 10px 0
}
ion-content, ion-item {
  --background: var(--ion-color-white)
}
ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
}

ion-text {
  padding-bottom: 20px;
}

.sign-in-subtitle {
    margin: 20px 130px;
    margin-bottom: 50px;
    padding-bottom: 50px;
}
.sign-in-header {
    max-width: 300px;
  margin: 10px auto;
  margin-top: 50px;
  margin-bottom: 20px
}
</style>
