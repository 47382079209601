<script lang="ts">
import { IonBadge, IonCheckbox } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../../business'
import { refresh } from "ionicons/icons";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import ProgressBar from '@/modules/internal/presentation/components/ProgressBarComponent.vue'

export default defineComponent({
name: 'RegistrationsTable',
  components: {
    IonBadge,
    IonCheckbox,
    Vue3EasyDataTable,
    ProgressBar
  },
  computed: {
    ...mapGetters('internal',{
      search: GettersTypes.search,
      registrations: GettersTypes.registrations,
      loading: GettersTypes.loading
    }),
  },
  setup() {
    return {
      refresh
    }
  },
  methods: {
    ...mapActions('internal',{
      getRegistrations: ActionTypes.getRegistrations,
      eventApproval: ActionTypes.eventApproval
    }),
    formattedTime(time) {
      var date = new Date(time)
      var formattedTime = date.toLocaleTimeString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) 
      return formattedTime
    },
    confirmApproval(index, item) {
      if (this.registrations[index].approval_delay) {
        this.eventApproval({token: this.$cookies.get('access_token'), data: {email: item.email, event_id: item.event_id, event: item.event, approved: !item.approved}})
      }
    },
    approve(item) {
      var index = this.registrations.findIndex(function(e) {return e.id == parseInt(item.id)})
      if (!this.registrations[index].approval_delay) {
        this.registrations[index].approval_delay = true
        setTimeout(() => {
          this.confirmApproval(index, item)
          this.registrations[index].approval_delay = false
          item.approved = !item.approved
        }, 10000);
      } else {
        this.registrations[index].approval_delay = false
      }
    },
    async refreshTable() {
      this.refreshLoading = true
      await this.getRegistrations(this.$cookies.get('access_token'))
      var ids = this.data.map(a => a.id)
      var newData = this.registrations.filter(e => !ids.includes(e.id))
      if (newData.length) {
        this.data = newData.concat(this.data)
      }
      this.refreshLoading = false
    }
  },
  data() {
    return {
      headers: [
        { text: "CREATION TIME", value: "creation_time", sortable: true},
        { text: "EVENT", value: "event", sortable: true},
        { text: "NAME", value: "name", sortable: true},
        { text: "EMAIL", value: "email", sortable: true},
        { text: "CHECKOUT", value: "page_id", sortable: true},
        { text: "INSTAGRAM", value: "instagram", sortable: true},
        { text: "APPROVED", value: "approved", sortable: true},
        { text: "TICKETS", value: "tickets"},
        { text: "", value: "tools"},
      ],
      data: []
    }
  },
  async mounted() {
    await this.getRegistrations(this.$cookies.get('access_token'))
    this.data = this.registrations
  }
})
</script>

<template>
  <vue3-easy-data-table
    buttons-pagination
    alternating
    :headers="headers" 
    :items="registrations" 
    :loading="this.data.length ? false : this.loading"
    :search-value="this.search">

    <template v-slot:[`header-tools`]>
      <div class="customize-header">
        <ion-icon v-if="this.data.length ? !this.loading : true" :icon="refresh" class="refresh" @click="this.refreshTable()"></ion-icon>
        <div v-else class="dot-carousel"></div>
      </div>
    </template>

    <template v-slot:[`item-creation_time`]="{ creation_time }">
      {{ this.formattedTime(creation_time) }}
    </template>

    <template v-slot:[`item-name`]="item">
      {{ (item.name ? item.name : '') + ' ' + (item.surname ? item.surname : '') }}
    </template>

    <template v-slot:[`item-instagram`]="item">
      <a :href="'https://instagram.com/'+item.instagram?.replace('@','')" target="_blank">{{ item.instagram?.replace('@','') }}</a>
    </template>

    <template v-slot:[`item-checkout`]="item">
      <a >{{ item.pageId }}</a>
    </template>

    <template v-slot:[`item-approved`]="item">
      <ion-badge v-if="item.pre_approved" color="warning">pre-approved</ion-badge>
      <div v-else class="checkbox" >
        <ion-checkbox @click="this.approve(item)" :checked="item.approved"></ion-checkbox>
        <progress-bar class="progress-bar" :class="item.approval_delay ? 'approved_delay' : ''"></progress-bar>
      </div>
    </template>

    <template v-slot:[`item-tickets`]="item">
      <ion-badge :color="item.tickets ? 'success' : 'danger'">{{ item.tickets ? 'YES' : 'NO' }}</ion-badge>
    </template>
  </vue3-easy-data-table>
</template>

<style scoped>

.progress-bar {
  display: none;
  margin: 4px 5px;
}

.approved_delay  {
  display: block;
}

.checkbox {
  display: flex;
}

@media (max-width: 780px) { 
  .progress-bar {
    margin: 8px 5px;
  }
}

ion-icon {
    margin: 5px 6px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .refresh {
    margin: 0;
  }
  .refresh:hover {
    color: var(--ion-color-dark-shade)
  }

.dot-carousel {
    position: relative;
    left: -9999px;
    margin: 0 25px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--ion-color-light);
    color: var(--ion-color-light);
    box-shadow: 9984px 0 0 0 var(--ion-color-light), 9999px 0 0 0 var(--ion-color-light), 10014px 0 0 0 var(--ion-color-light);;
    animation: dotCarousel 1.5s infinite linear;
  }
  
  @keyframes dotCarousel {
    0% {
      box-shadow: 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade);
    }
    50% {
      box-shadow: 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade);
    }
    100% {
      box-shadow: 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade);
    }
  }

</style>
