import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.closeOutline,
                      color: "danger",
                      size: "large"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, {
              innerHTML: _ctx.$t('internal.assign_ticket')
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Email")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.email), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Event")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_select, {
                  interface: "popover",
                  placeholder: "Select event",
                  onIonChange: _cache[0] || (_cache[0] = ($event: any) => (this.event = $event.detail.value))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getEvents(), (checkoutPage) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: checkoutPage,
                        value: checkoutPage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(checkoutPage), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Checkout")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_select, {
                  interface: "popover",
                  placeholder: "Select page",
                  onIonChange: _cache[1] || (_cache[1] = ($event: any) => (this.page = $event.detail.value)),
                  disabled: !this.event
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getPages(this.event), (checkoutPage) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: checkoutPage,
                        value: checkoutPage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(checkoutPage), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Item")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_select, {
                  interface: "popover",
                  placeholder: "Select item",
                  compareWith: _ctx.compareWith,
                  onIonChange: _cache[2] || (_cache[2] = ($event: any) => (this.item = $event.detail.value)),
                  multiple: true,
                  disabled: !this.page
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getItems(this.page), (checkoutPage) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: checkoutPage,
                        value: checkoutPage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(checkoutPage), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["compareWith", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("Quantity")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.quantity,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.quantity) = $event)),
                  interface: "popover",
                  type: "number",
                  style: {"text-align":"right"},
                  placeholder: "0"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_button_loading, {
          class: "confirm",
          text: "Confirm",
          color: this.buttonColor,
          isLoading: this.isLoading,
          disabled: !this.item || this.quantity == undefined || this.quantity == 0,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirm()))
        }, null, 8, ["color", "isLoading", "disabled"])
      ]),
      _: 1
    })
  ], 64))
}