import { Event } from '../types'
import { UserEventState } from '../enums'

export type State = {
  events: [Event]
};

export const state = (): State => ({
  events: [{
    eventId: 0,
    title: "",
    description: "",
    details: [{title: "", description: "", icon: ""}],
    startDate: "",
    endDate: "",
    type:"past",
    state: UserEventState.NOT_REGISTERED,
    linkInfoPack: "",
    linkWhatsApp: "",
    background: "",
    edit: false,
  }]
})
