<script lang="ts">
import { IonContent, IonPage, IonList, IonLoading, IonInput, IonButton } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import QuantitySelector from '../components/QuantitySelectorComponent.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import StripePage from './StripePage.vue'
import axios from 'axios'

interface Discount {
  valid: boolean;
  percentage: number;
  code: string;
}


export default defineComponent({
  name: 'CheckoutPage',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonLoading,
    IonInput,
    IonButton,
    QuantitySelector,
    StripePage,
    ButtonLoading
  },
  data() {
    return {
      loaderShown: true,
      totalPriceServiceFee: 0,
      stripeItems: [{ price: '', quantity: 0 }],
      stripePayload: {},
      paymentHash: (Math.random() + 1).toString(36).substr(4, 10),
      discountCode: '',
      appliedDiscount: null as Discount | null,
      dicountButtonLoading: false,
    }
  },
  computed: {
    ...mapGetters('checkout', {
      checkout: GettersTypes.checkout,
      basket: GettersTypes.basket,
      totalPrice: GettersTypes.totalPrice,
      loaderShown: GettersTypes.loaderShown,
      contact: GettersTypes.getContact
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED'
    }),
    discountAmount(): number {
      if (this.appliedDiscount) {
        return Math.round((this.totalPrice * (this.appliedDiscount.percentage / 100))*100)/100;
      }
      return 0;
    },
    totalPriceWithDiscount(): number {
      return this.totalPriceServiceFee - this.discountAmount;
    },
    serviceFeePerItem(): boolean {
      return this.$route.query.id == '1' || !this.$route.query.id;
    }
  },
  methods: {
    ...mapActions('checkout', {
      getCheckout: ActionTypes.getCheckout,
      incrementBasket: ActionTypes.incrementBasket,
      clearBasket: ActionTypes.clearBasket,
      registerPayment: ActionTypes.registerPayment,
      applyDiscountCode: ActionTypes.applyDiscountCode,
    }),
    async getUpdatedPricing() {
      try {
        // const response = await axios.get(`http://localhost:8000/cl/updated-pricing/${this.paymentHash}`);
        // this.basket = response.data.basket;
        // this.totalPrice = response.data.totalPrice;
      } catch (error) {
        console.error('Error fetching updated pricing:', error);
      }
    },
    ...mapActions({
      confirmPayment: 'CONFIRM_PAYMENT',
    }),
    async getItemById() {
      this.loaderShown = true;
      const pageId = this.$route.query.id as string;
      await this.getCheckout(pageId ? pageId : 1);
      
      // Filter out invalid items
      // if (this.basket) {
      //   this.basket = this.basket.filter(item => item.itemId !== 0);
      // }
      
      this.loaderShown = false;
    },
    async applyDiscount() {
      this.dicountButtonLoading = true
      try {
        const result = await this.applyDiscountCode({
          token: this.$cookies.get('access_token'),
          code: this.discountCode
        });
        if (result && result.valid) {
          this.appliedDiscount = result;
          // this.$toast.success('Discount applied successfully!');
        } else {
          // this.$toast.error('Invalid discount code.');
        }
      } catch (error) {
        console.error('Error applying discount:', error);
        // this.$toast.error('Failed to apply discount. Please try again.');
      }
      this.dicountButtonLoading = false
    },
    async submit() {
      if (!this.loaderShown) {
        this.loaderShown = true

        const basket = this.basket.map(item => ({

          ...item,
          price: this.appliedDiscount ?
            Math.round(item.price * (1 - this.appliedDiscount.percentage / 100) * 100) / 100 :
            item.price
        }));
        const paymentHash = this.paymentHash

        const user = this.auth.token ? this.auth.token : this.$route.query.hashId
        const eventId = this.checkout.eventId
        const pageId = this.$route.query.id ? this.$route.query.id : 1

        await this.registerPayment({
          basket,
          paymentHash,
          user,
          eventId,
          pageId,
          discountCode: this.appliedDiscount ? this.appliedDiscount.code : null
        })

        this.loaderShown = false
        if (this.contact.email) {
          this.redirectToStripe(basket)
        }
      }
    },
    redirectToStripe(basket) {
      // Fetch the latest pricing information from the backend
      this.getUpdatedPricing().then(() => {
        this.stripeItems = [{ price: this.checkout.serviceFee.priceId as string, quantity: 1 }]
        const pageId = this.$route.query.id ? this.$route.query.id : 1
        
        var clone = structuredClone(basket)
        clone = clone.filter(el => el.quantity > 0)

        if (pageId == 1) {

          const quantityFactor = 2;
          clone.forEach((item) => {
            var quantity = item.quantity * quantityFactor
            if (item.itemId == 2) {
              var quantitySale = item.quantity >= 40 ? quantity - 20 :
                item.quantity >= 24 ? quantity - 8 :
                  item.quantity >= 16 ? quantity - 4 :
                    item.quantity >= 8 ? quantity - 2 :
                      quantity;
              this.stripeItems.push({ price: item.priceId, quantity: quantitySale })
            } else {
              this.stripeItems.push({ price: item.priceId, quantity: quantity })
            }
          })
        } else {
          var sum = 0
          clone.forEach(element => {
            sum += element.quantity;
          });
          this.stripeItems = [{ price: this.checkout.serviceFee.priceId as string, quantity: sum }]
          clone.forEach((item) => this.stripeItems.push({ price: item.priceId, quantity: item.quantity }))
        }


        this.stripePayload = {
          basket: this.stripeItems,
          eventId: this.checkout.eventId,
          paymentHash: this.paymentHash,
          stripePk: this.checkout.stripePk,
          serviceFee: this.checkout.serviceFee,
          email: this.contact.email,
          instagram: this.contact.instagram,
          openRegistration: this.$route.query.hashId ? true : false,
          token: (this.$route.query.id == '1' || !this.$route.query.id) ? true : false,
          discountCode: this.appliedDiscount ? this.appliedDiscount.code : null
        }
      });
    },
    clearUrlPath() {
      history.pushState(
        {},
        "",
        '/tokens'
      )
    }
  },
  mounted() {
    this.getItemById()
    const paymentId = this.$route.query.paymentId as string
    if (paymentId) {
      this.confirmPayment(paymentId)
      this.clearUrlPath()
    }
  },
  watch: {
    $route(to, from) {
      this.clearBasket();
      this.totalPriceServiceFee = 0;
      this.basket = [];
      
      // Clear invalid items
      this.basket = this.basket.filter(item => item.itemId !== 0);

      if (to.path == this.$t('toolbar.path_checkout') || to.path == this.$t('toolbar.path_tokens')) {
        this.getItemById();
        this.appliedDiscount = null;
        this.discountCode = '';
      } 
    },
    totalPrice() {
      if (this.totalPrice >= this.checkout.serviceFee.price) {
        if (this.serviceFeePerItem) {
          this.totalPriceServiceFee = this.totalPrice + (this.checkout.serviceFee.price * this.basket.length);
        } else {
          this.totalPriceServiceFee = this.totalPrice + this.checkout.serviceFee.price;
        }
      } else {
        this.totalPriceServiceFee = 0;
      }
    },
  }
});
</script>


<template>
  <ion-page>
    <ion-loading :is-open="this.loaderShown" message=""></ion-loading>
    <ion-content>
      <div class="container" v-if="!this.loaderShown" fullscreen="false">
        <h1 v-html="this.checkout.title"></h1>
        <br>
        <ion-subtitle v-html="this.checkout.subtitle"></ion-subtitle>

        <ion-list lines="none" v-if="this.$route.path.includes('checkout')">
          <ion-item v-for="item in checkout.items" :key="item.id">
            <quantity-selector :item="item"></quantity-selector>
          </ion-item>
        </ion-list>

        <form>
          <div class="discount-container">
            <ion-input v-model="discountCode" placeholder="Enter discount code" class="discount-input"></ion-input>
            <button-loading @click="applyDiscount" 
              color="primary"
              :isLoading="this.dicountButtonLoading" 
              text="Apply"
              :disabled="!discountCode"
            />
          </div>
          <div class="total-container">
            <p class="service-fee"
              v-html="$t('checkout.service_fee') + ' ' + this.checkout.serviceFee.price + $t('checkout.currency')"></p>
            <p v-if="appliedDiscount" class="discount">
              <b>Discount: -{{ appliedDiscount.percentage }}% (-{{ discountAmount + $t('checkout.currency') }})</b>
            </p>
            <div class="total">
              <h1 v-html="$t('checkout.total_price')"></h1>
              <h1 ref="totalPrice" v-html="Math.max(this.totalPriceWithDiscount, 0) + $t('checkout.currency')"></h1>
            </div>
            <hr>
          </div>
          <br>

          <div class="stripe-button" style="padding-bottom: 50px;">
            <ion-button color="dark" size="large" @click="submit()"
              :disabled="this.checkout.disabled || this.totalPriceWithDiscount == 0">
              {{ this.checkout.disabled ? $t('checkout.button_disabled') : $t('checkout.button_confirm') }}
            </ion-button>
          </div>
        </form>
      </div>
      <stripe-page v-if="Object.keys(this.stripePayload).length != 0" :payload="this.stripePayload"></stripe-page>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-item {
  padding-inline-start: -10px;
}

.tokens {
  margin-bottom: 50px;
}

hr {
  margin: 0px 5% !important;
  border: none;
  height: px;
  background-color: black;
}

.total-container {
  margin: auto;
  max-width: 400px;
}

.total {
  display: flex;
  margin: 0 auto 0 5%;
  height: 40px;
}

.total h1 {
  margin: 0
}

.total h1:nth-child(2) {
  margin: 0;
  margin-right: 5%;
  margin-left: auto;
  text-align: right;
}


.service-fee {
  font-size: calc(0.04 * var(--viewport-width));
  text-align: right;
  margin: 50px 5% 0 5%;
}

.discount {
  font-size: calc(0.04 * var(--viewport-width));
  text-align: right;
  margin: 5px 5% 0 5%;
}



ion-title {
  font-size: 32px
}


.container {
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  max-width: 720px;
  text-align: center;
}

.stripe-button {
  padding-top: 20px
}

.avatar {
  height: 200px;
  width: 200px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
}

.avatar-circle {
  float: left;
  padding: 10%
}

@media (max-width: 780px) {
  ion-card {
    padding: 20px;
    margin: 20px auto;
  }
}

.discount-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.discount-input {
  max-width: 200px;
  margin-right: 10px;
}

.discount {
  font-size: calc(0.04 * var(--viewport-width));
  text-align: right;
  margin: 5px 5% 0 5%;
  color: green;
}
</style>
