<script lang="ts">
import { IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonRow, IonIcon, modalController, IonSkeletonText } from '@ionic/vue';
import { IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonText, IonTitle } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex'
import { EventState, UserEventState } from '../../enums';
import { ActionTypes } from '@/modules/events/business'
import { informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, pencil, pizzaOutline, heartOutline, starOutline, homeOutline, personOutline, settingsOutline, carOutline, airplaneOutline, bedOutline, cameraOutline, chatbubbleOutline, checkmarkCircleOutline, cloudOutline, footballOutline, earthOutline, giftOutline } from "ionicons/icons";
import OpenRegistrationModal from './modal/OpenRegistrationModal.vue'
import CheckoutPageModal from './modal/CheckoutPageModal.vue'
import StepProgressBar from './external/StepProgressBarComponent.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
  name: 'EventCard',
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonRow,
    IonIcon,
    StepProgressBar,
    IonAccordionGroup, 
    IonAccordion, 
    IonItem, 
    IonLabel, 
    IonText, 
    IonTitle,
    IonSkeletonText,
    ButtonLoading
  },
  props: {
    event: {
        type: Object,
        default() {
            return {eventId: "0", startDate: "", endDate: ""}
        }
    },
    loading: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      loaderShown: false,
      UserEventState, 
      EventState,
      infoShown: false,
      showSkeleton: true
    }
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'IS_AUTHORIZED',
      auth: 'AUTH'
    }),
    backgroundImage() {
      const eventId = this.event.eventId;
      return `linear-gradient(180deg, rgba(0,0,0,0) 0, var(--background-color-light) 80px), url(/assets/background/card-bg-${eventId}.svg)`;
       // dark mode
      // return `linear-gradient(0deg, var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff))) 120px, rgba(0,0,0,0.7) 100%), url(/assets/background/element_0${eventId}_bg.svg)`;
    },
  },
  methods: {
    ...mapActions('events', {
      registerToEvent: ActionTypes.registerToEvent
    }),
    ...mapActions({
      isAuthorized: 'IS_AUTHORIZED',
    }),
    getOrdinalNum(n: number) {
      return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    },
    parseDate(startDate, endDate) {
      if (startDate) {
        const formattedStartDate = new Date(startDate)
        const formattedEndDate = new Date(endDate)
        if (typeof formattedStartDate === 'object' && formattedStartDate !== null && 'toLocaleDateString' in formattedStartDate) {
          if (endDate) {
            const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
            const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
            const endMonth = formattedEndDate.toLocaleDateString("en-US", { month: 'short' })
            const endDay = this.getOrdinalNum(parseInt(formattedEndDate.toLocaleDateString("en-US", { day: 'numeric' })))
            const date = startMonth == endMonth ? startMonth + " " + startDay + " - " + endDay : startMonth + " " + startDay + " - " + endMonth + " " + endDay
            return date
          } else {
            const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
            const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
            const date = startMonth + " " + startDay
            return date
          }
        }
      }
    },
    async openRegistrationModal() {
      const modal = await modalController.create({
        component: OpenRegistrationModal,
        componentProps: {eventId: this.event.eventId}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      if (role === 'confirm') {
        this.$emit('submit')
      } else {
        this.loaderShown = false
      }
    },
    async openCheckoutModal() {
      const modal = await modalController.create({
        component: CheckoutPageModal,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        // this.message = `Hello, ${data}!`;
      }
    },
    register() {
      if (!this.loaderShown) {
        this.loaderShown = true
        if (this.isAuthorized) {
          this.registerToEvent({eventId: this.event.eventId, token: this.auth.token})
        } else {
          this.openRegistrationModal()
        }
      }
    },
    isPastEvent() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      const formattedStartDate = new Date(this.event.startDate)
      const formattedEndDate = this.event.endDate ? new Date(this.event.endDate) : undefined
      return formattedStartDate < tomorrow && (formattedEndDate ? formattedEndDate < tomorrow : true)
    },
    isLiveEvent() {
      return this.event.type == EventState.LIVE || this.event.type == EventState.PLAN || this.event.type == EventState.SOLD_OUT
    },
    getIcon(name) {
      let icons = {
        'informationCircleOutline': informationCircleOutline, 
        'pinOutline': pinOutline,
        'busOutline': busOutline,
        'flowerOutline': flowerOutline,
        'musicalNotesOutline': musicalNotesOutline,
        'shirtOutline': shirtOutline,
        'ticketOutline': ticketOutline,
        'calendarOutline': calendarOutline,
        'pizzaOutline': pizzaOutline,
        'heartOutline': heartOutline,
        'starOutline': starOutline,
        'homeOutline': homeOutline,
        'personOutline': personOutline,
        'settingsOutline': settingsOutline,
        'carOutline': carOutline,
        'airplaneOutline': airplaneOutline,
        'bedOutline': bedOutline,
        'cameraOutline': cameraOutline,
        'chatbubbleOutline': chatbubbleOutline,
        'checkmarkCircleOutline': checkmarkCircleOutline,
        'cloudOutline': cloudOutline,
        'footballOutline': footballOutline,
        'earthOutline': earthOutline,
        'giftOutline': giftOutline
      };

      if (name in icons) return icons[name];
    },
    triggerCollapse() {
      setTimeout(() => this.infoShown = !this.infoShown, 200);
    },
    addToCalendar(title) {
      window.open('/assets/calendar/' + title + '.ics')
    },
    isInternalRole() {
      var token = this.auth.token
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload).role != 'user';
      }
      return false
    }
  },
  setup() {
    return {
      informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, pencil
    };
  },
  watch: {
    event() {
      this.showSkeleton = false
    },
  },
  mounted() {
    this.showSkeleton = this.loading
  }
});
</script>

<template>
  
  <ion-card :class="this.showSkeleton ? 'loading' : (isPastEvent() ? 'past-event' : '') " :style="{ backgroundImage: backgroundImage }">
    
    <!-- HEADER -->
    <ion-card-header>
      <ion-skeleton-text v-if="this.showSkeleton" animated style="width: 30%; min-width: 200px; height: 25px"></ion-skeleton-text>
      <ion-card-title v-else>{{ event.title }}</ion-card-title>

      <ion-skeleton-text v-if="this.showSkeleton" animated style="width: 10%; min-width: 70px; height: 20px; opacity: 0.5;"></ion-skeleton-text>
      <ion-card-subtitle style="color: var(--ion-color-dark); opacity: 0.7;" v-else>{{ parseDate(this.event.startDate, this.event.endDate) }}</ion-card-subtitle>
      

      <div v-show="isInternalRole() && !this.showSkeleton" class="button-container">
        <ion-icon :icon="pencil" @click="this.$emit('edit')"></ion-icon>
      </div>

    </ion-card-header>

    <div v-if="this.showSkeleton">
      <ion-skeleton-text v-if="this.showSkeleton" animated style="width: 70%; min-width: 250px;"></ion-skeleton-text>
      <ion-skeleton-text v-if="this.showSkeleton" animated style="width: 60%; min-width: 150px;"></ion-skeleton-text>
      <ion-skeleton-text v-if="this.showSkeleton" animated style="width: 30%; min-width: 50px;"></ion-skeleton-text>
    </div>
    <ion-card-content v-else>
      {{ event.description }}
    </ion-card-content>

    
    <div v-if="this.showSkeleton">
      <br>
      <ion-skeleton-text v-if="this.showSkeleton" animated style="width: 50%; min-width: 200px; height: 40px; margin-top: 15px; opacity: 0.5;"></ion-skeleton-text>
      <ion-skeleton-text v-if="this.showSkeleton" animated style="width: 20%; min-width:100px; height: 40px; margin-top: 35px;"></ion-skeleton-text>
    </div>
    <ion-card-footer v-else v-show="!isPastEvent() && isLiveEvent()">
      
      <!-- INFO DROPDOWN -->
      <ion-accordion-group v-if="event.details.length">
        <ion-accordion value="first">
          <ion-item class="dropdown" slot="header" color="white" @click="this.triggerCollapse()">
            <ion-label v-html="$t('events.more_details')"></ion-label>
          </ion-item>
          <div readonly class="wrapper" slot="content">
            <div class="detail" v-for="detail in event.details" :key="detail">
              <ion-title><ion-icon :icon="getIcon(detail.icon)" size="medium"></ion-icon>{{ detail.title }}</ion-title>
              <ion-text v-html="detail.description"></ion-text>
            </div>
          </div>
          <!-- <ion-item v-show="this.infoShown" class="dropdown dropdown-bottom" slot="header" color="white"><ion-label class="collapse" @click="this.triggerCollapse()">Collapse</ion-label></ion-item>-->
        </ion-accordion>
      </ion-accordion-group>
      
      <!-- PROGRESS BAR -->
      <step-progress-bar v-if="this.event.state != UserEventState.PURCHASED && this.event.state != UserEventState.NOT_REGISTERED && this.event.state != UserEventState.PRE_APPROVED" :state="this.event.state"></step-progress-bar>
      <p v-if="this.event.state == UserEventState.NOT_REGISTERED && this.event.type != EventState.SOLD_OUT" v-html="$t('events.message.not_registered')"></p>
      <p v-if="this.event.state == UserEventState.PURCHASED" color="light" @click="$router.push({ path: $t('toolbar.path_tickets') })">{{ $t('events.message.purchased') }}</p>
      <!-- <p v-if="this.event.state == UserEventState.PURCHASED" color="light" class="show_tickets" @click="$router.push({ path: $t('toolbar.path_tickets') })"><ion-icon :icon="ticketOutline" color="dark" size="small"></ion-icon>{{ $t('events.show_tickets') }}</p> -->
      <p v-if="this.event.state == UserEventState.PRE_APPROVED  && this.event.type != EventState.SOLD_OUT" v-html="$t('events.message.pre_approved')"></p>
      <br v-else>
      <!-- BUTTONS -->
      
      <!-- <button-loading  :isLoading="this.loaderShown" @click="this.register()" :text="'Register'"></button-loading> -->

      <button-loading v-if="this.event.type == EventState.SOLD_OUT" :isLoading="this.loaderShown" @click="this.register()" :text="'Sold Out'" :color="'danger'" disabled></button-loading>  
      <button-loading v-else-if="this.event.state == UserEventState.NOT_REGISTERED" :isLoading="this.loaderShown" @click="this.register()" :text="'Register'"></button-loading>
      <p v-else-if="this.event.state == UserEventState.REGISTERED" v-html="$t('events.message.registartion_pending')"></p>
      <ion-button v-else-if="[UserEventState.APPROVED, UserEventState.PRE_APPROVED].includes(this.event.state) && !this.isAuthorized" size="medium" color="medium" @click="this.openRegistrationModal()" v-html="$t('events.message.purchase')"></ion-button>
      <ion-button v-else-if="this.event.state == UserEventState.PURCHASED" size="small" color="medium" @click="$router.push({ path: $t('toolbar.path_checkout'), query:{'id': this.event.pageId}})" v-html="$t('events.message.purchase_more')"></ion-button>
      <ion-button v-else-if="this.isAuthorized" size="medium" color="medium" @click="$router.push({ path: $t('toolbar.path_checkout'), query:{'id': this.event.pageId}})" v-html="$t('events.message.purchase')"></ion-button>
      
    </ion-card-footer>
    
    <!-- PAST EVENTS -->
    <ion-card-footer v-show="!isPastEvent() && !isLiveEvent() && !this.showSkeleton && this.event.type != EventState.SOLD_OUT">
      <ion-row v-show="!isPastEvent()">
        
        <p class="no_reg-message"><ion-icon :icon="informationCircleOutline" size="small"></ion-icon>{{ $t('events.message.no_reg') }}</p>
      </ion-row>
    </ion-card-footer>
  </ion-card>
</template>

<style scoped>

ion-skeleton-text {
  margin: auto;
  margin-bottom: 15px; 
  border-radius: 5px;
}

div ion-skeleton-text {
  margin-bottom: 10px;
}

ion-card {
  width: 95%;
  margin: 50px auto;
  padding: 20px;
  max-width: 720px;
  text-align: center;

  background: var(--background-color-light);
  background-size: 100%;
  background-position: 50% 0;

  box-shadow: 0px 6px 12px 0px var(--ion-color-light-shade);
  border-radius: 10px;
}

.loading {
  /* filter: grayscale(100%);*/
  min-height: 300px;
}


ion-card-header {
  padding-top: 0;
  max-height: 80px;
}

ion-card-title {
  color: var(--ion-color-dark);
}

.button-container {
  position: absolute;
  right: 0;
  top: 0;
}

.button-container ion-icon { 
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.button-container ion-icon:hover { 
  color: var(--ion-color-dark)
}

.show_tickets,
.add_to_calendar {
  font-size: medium;
  cursor: pointer;
  text-decoration: underline
}

.add_to_calendar {
  font-size: small;
  margin: 2px 0;
  color: var(--ion-color-dark-tint)
}

.add_to_calendar ion-icon {
  height: 15px;
  color: var(--ion-color-dark-shade);
}

.purchased {
  margin-top: 10px
}
.purchased ion-button {
  margin: 5px 20px;
  width: 150px
}

p {
  opacity: 0.7;
}

.past-event {
  opacity: 0.7;
}

.no_reg-message {
  margin: auto 10px;
}

ion-row {
  justify-content: center;
  opacity: 0.7;
  margin-top: 5px;
}



ion-title { 
  position: relative;
  text-align: left;
  padding-left: 50px;
  padding-inline: 25px
}

ion-text {
  text-align: left;
  display: block;
  margin: 5px 25px;
}

ion-icon {
  position: absolute;
  transform: translate(-120%, 5%);
}

ion-accordion {
  background-color: transparent;
}

.dropdown {
  width: 190px;
  margin: auto;
  color: var(--ion-color-medium-shade);
  background-color: var(--ion-color-white-shade);
  border-radius: 20px;
}

ion-title:not(:first-child) {
  padding-top: 20px
}

/*
.wrapper {
  padding: 20px 0;
  padding-bottom: 50px;
  text-align: left;
}
*/

.dropdown-bottom {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.collapse {
  opacity: 0.7;
  font-weight: 300;
  position: absolute;
  top: 15px;
  left: 78px;
  transform: translateX(-50%);
}

.detail{
  padding: 20px 10px;
}

@media (max-width: 780px) { 
  .dropdown {
    width: 220px;
  }
  .add_to_calendar {
    font-size: 10px
  }
  .add_to_calendar ion-icon {
    height: 13px;
  }
}


@media (max-width: 780px) { 
  ion-card-header {
    padding-bottom: 10px
  }
  
  ion-card-content {
    padding: 0;
    padding-bottom: 5px
  }

  .container {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}


</style>
