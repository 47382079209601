<script lang="ts">
  import {
    IonContent,
    IonDatetime,
    // IonButton,
    modalController,
  } from '@ionic/vue';
  import { defineComponent, ref } from 'vue';
  export default defineComponent({
    name: 'DatePickerModal',
    components: { IonContent, IonDatetime },
    props: {
      defaultDatetime: {
      type: Date,
      default: () => new Date().toISOString().slice(0, 11) + '18:00:00.000Z'
      }
    },
    setup(props) {
      const datetime = ref();
      const cancel = () => datetime.value.$el.cancel();
      const confirm = () => datetime.value.$el.confirm();
      return { datetime, cancel, confirm }
    },
    methods: {
      close() {
      this.$emit('setDatetime', this.setDatetime)
      return modalController.dismiss(this.setDatetime, 'confirm');
      },
    },
    data() {
      return {
      setDatetime: this.defaultDatetime.toISOString().slice(0, 11) + '18:00:00.000Z'
      }
    },
  });
</script>

<template>
  <ion-content class="ion-padding">
   <ion-datetime
    minuteValues="0,15,30,45"
    max="2030-12-31"
    v-model="setDatetime"
    ref="datetime"
    :first-day-of-week="1"
    >
    <ion-buttons slot="buttons">
      <ion-button color="danger" @click="close()">Cancel</ion-button>
      <ion-button color="primary" @click="confirm(); close()">Save</ion-button>
    </ion-buttons>
    </ion-datetime>
  </ion-content>
</template>

<style scoped>

ion-content {
  --background: transparent
}
.login {
  margin: 20px
}

#cancel-button {
  color: var(--ion-color-danger)
}

</style>
