<script lang="ts">
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '@/modules/events/business'
import EventCard from '../components/EventCardComponent.vue'
import EditEvent from '../components/EditEventComponent.vue'
import { addCircleOutline } from "ionicons/icons";


export default defineComponent({
name: 'TicketingPage',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    EventCard,
    EditEvent,
    // IonLoading,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  data() {
    return {
      loaderShown: true,
      pastEvents: Object,
      showSkeleton: true,
      addNewEvent: false
    }
  },
  computed: {
    ...mapGetters('events',{
      events: GettersTypes.events
    }),
    ...mapGetters({
      isAuthorized: 'IS_AUTHORIZED',
      auth: 'AUTH',
    })
  },
  methods: {
    ...mapActions('events',{
      getEvents: ActionTypes.getEvents,
      registerToEvent: ActionTypes.registerToEvent
    }),
    hideLoader() {
      this.loaderShown = false
    },
    getUpcomingEvents() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      return this.events.filter(e => new Date(e.startDate) >= tomorrow)
    },
    getPastEvents() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      return this.events.filter(e => new Date(e.startDate) < tomorrow).slice().reverse()
    },
    isInternalRole() {
      var token = this.auth.token
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload).role != 'user';
      }
      return false
    }
  },
  setup() {
    const isDisabled = ref(false);
    const toggleInfiniteScroll = () => {
      isDisabled.value = !isDisabled.value;
    }
    const items = ref<number[]>([]);
    const pushData = () => {
      const max = items.value.length + 5;
      const min = max - 5;
      for (let i = min; i < max; i++) {
        items.value.push(15-i); // TODO HARDCODED LIST
      }
    }
    
    const loadData = (ev: any) => {
      setTimeout(() => {
        pushData();
        ev.target.complete();
  
        if (items.value.length === 15) { // TODO HARDCODED LIST
          ev.target.disabled = true;
        }
      }, 1000);
    }
    
    pushData();
    
    return {
      isDisabled,
      toggleInfiniteScroll,
      loadData,
      items,
      addCircleOutline
    }
    
  },
  mounted() {
    this.events = []
    this.getEvents(this.$cookies.get('access_token'))
  },
  watch: {
    events() {
      this.hideLoader()
      this.loaderShown = false
    },
    isAuthorized() {
      this.getEvents(this.auth.token)
    },
  }
});
</script>


<template>
  <ion-page>
    <ion-content fullscreen="false">
      <div v-if="this.isInternalRole()">
        <edit-event class="add-event" v-if="this.addNewEvent" @close="this.addNewEvent = false"></edit-event>
        <div v-else class="add-icon">
          <ion-icon :icon="addCircleOutline" @click="addNewEvent = true"></ion-icon>
        </div>
      </div>

      <ion-list lines=none>
        <event-card v-if="this.loaderShown" :loading="true" ></event-card>
        <div class="upcoming-events" v-else v-for="item in this.getUpcomingEvents()" :key="item">
          <edit-event v-if="item.edit" :event="item" @close="this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = false"></edit-event>
          <event-card v-else :event="item" :loading="false" @edit="this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = true"></event-card>
        </div>
      </ion-list>

      <div v-if="!this.loaderShown" class="item-divider">
        <ion-label class="divider-center">
          <p v-html="$t('events.divider.past_events')"></p>
        </ion-label>
      </div>

      <ion-list lines=none>
        <div  v-for="(item, index) in this.getPastEvents()" :key="item">
          <ion-item v-if="(index != 0 && new Date(this.getPastEvents()[index].startDate).getFullYear() != new Date(this.getPastEvents()[index-1]?.startDate).getFullYear())">
            <div class="item-divider margin-top-0">
              <ion-label class="divider-center">
                <p>{{ new Date(this.getPastEvents()[index].startDate).getFullYear() }}</p>
              </ion-label>
            </div>
          </ion-item>
          <div class="upcoming-events">
            <edit-event v-if="item.edit" :event="item" @close="this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = false"></edit-event>
            <event-card v-else :event="item" :loading="false" @edit="this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = true"></event-card>
          </div>
        </div>
      </ion-list>

        <ion-infinite-scroll
        @ionInfinite="loadData($event)"
        threshold="100px" 
        id="infinite-scroll"
        :disabled="isDisabled"
        position="bottom"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text=""
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      
    </ion-content>
  </ion-page>
</template>

<style scoped>


  .upcoming-events {
    margin-bottom: 100px;
  }

  .item-divider {
    margin-top: 0;
  }

  .divider-center {
    background: var(--ion-background-color);
  }

  .add-icon {
    text-align: center;
  }

  .add-icon ion-icon {
    width: 70px;
    height: 70px;
    transform: translateX(-5px);
    color: var(--ion-color-light-shade);
    cursor: pointer
  }

  .add-icon ion-icon:hover {
    color: var(--ion-color-dark-tint);
  }

  .add-event {
    animation: popup 0.1s forwards;
  }

  @keyframes popup {
    0% {
      transform: scale(0.5);
      margin: 0 auto;
      opacity: 0;
    },
    100% {
      transform: scale(1);
      margin: 50px auto;
      opacity: 1;
    }
  }

</style>
