<script lang="ts">
  import {  IonFab,
            IonFabList,
            IonFabButton,
            IonIcon } from '@ionic/vue';
  import { menuOutline, musicalNotesOutline, ticketOutline, personCircleOutline, wineOutline, logInOutline, logOutOutline, accessibilityOutline, searchOutline, personOutline, personAddOutline, scanOutline, documentTextOutline } from 'ionicons/icons';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex'
  import { Tables } from '@/modules/internal/enums'
  import InternalNavigation from './InternalNavigationComponent.vue'
  
  export default defineComponent({
    name: 'FabMenu',
    components: {
      IonFab,
      IonFabList,
      IonFabButton,
      IonIcon,
      InternalNavigation
    },
    props: {
      isAuthorized: {
        type: Boolean
      },
      forceClose: {
        type: Boolean
      }
    },
    data() {
      return {
        isMenuOpen: false,
        activeMenuColor: 'primary',
        search: "",
        Tables
      }
    },
    setup() {
      return {
        menuOutline,
        musicalNotesOutline,
        ticketOutline,
        personCircleOutline,
        wineOutline,
        logInOutline, 
        logOutOutline,
        accessibilityOutline,
        searchOutline,
        personOutline,
        personAddOutline,
        scanOutline,
        documentTextOutline
      }
    },
    computed: {
      ...mapGetters({
        isAuthorized: 'IS_AUTHORIZED',
        auth: 'AUTH'
      }),
      ...mapGetters('internal',{
        table: 'TABLE'
      })
    },
    methods: {
      ...mapActions({
        signOut: 'SIGN_OUT'
      }),
      ...mapActions('internal',{
        setSearch: 'SEARCH',
        selectTable: 'SELECT_TABLE',
      }),
      navigateToCabanalibre() {
        window.location.href = 'https://www.cabanalibre.com/';
      },
      click() {
        this.isMenuOpen = !this.isMenuOpen
        this.$emit('fabClick')
      },
      isCurrentPath(path) {
        return path == this.$router.currentRoute.value.path 
      },
      isInternalRole() {
        var token = this.auth.token
        if (token) {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          return JSON.parse(jsonPayload).role != 'user';
        }
        return false
      }
    },
    watch: {
      forceClose() {
        if (this.forceClose == true) {
          this.isMenuOpen = false
        }
      }
    }
  });
  </script>
  
  
  <template>
    <ion-fab horizontal="left" vertical="top" slot="fixed" :activated="this.forceClose">
      <ion-title class="md" :class="isCurrentPath($t('toolbar.path_portfolio')) ? 'hidden_title' : ''" size="large">{{ $route.name }}</ion-title>
      <div v-if="isCurrentPath($t('toolbar.path_portfolio'))" class='logo'></div>
      
      <internal-navigation v-if="isCurrentPath($t('toolbar.path_admin'))"></internal-navigation>

      <ion-fab-button color="dark" @click="this.click();">
        <ion-icon :icon="menuOutline"></ion-icon>
      </ion-fab-button>
  
      <ion-fab-list v-if="this.isAuthorized" side="bottom">
        <ion-fab-button v-if="isInternalRole()" :color="isCurrentPath($t('toolbar.path_scanner')) ? this.activeMenuColor : 'warning'" :data-desc="$t('toolbar.title_scanner')" @click="this.click(); $router.push({ path: $t('toolbar.path_scanner') })">
          <ion-icon :icon="scanOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button v-if="isInternalRole()" :color="isCurrentPath($t('toolbar.path_admin')) ? this.activeMenuColor : 'warning'" :data-desc="$t('toolbar.title_admin')" @click="this.click(); $router.push({ path: $t('toolbar.path_admin') })">
          <ion-icon :icon="documentTextOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_portfolio')) ? this.activeMenuColor : 'light'"  :data-desc="$t('toolbar.title_portfolio')" @click="navigateToCabanalibre()">
  <ion-icon :icon="searchOutline"></ion-icon>
</ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_tickets')) ? this.activeMenuColor : 'light'"  :data-desc="$t('toolbar.title_tickets')" @click="this.click(); $router.push({ path: $t('toolbar.path_tickets') })">
          <ion-icon :icon="ticketOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_tokens')) ? this.activeMenuColor : 'light'" :data-desc="'Tokens'" @click="this.click(); $router.push({ path: $t('toolbar.path_tokens') })">
          <ion-icon :icon="wineOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_events')) ? this.activeMenuColor : 'light'" :data-desc="$t('toolbar.title_events')" @click="this.click(); $router.push({ path: $t('toolbar.path_events') })">
          <ion-icon :icon="musicalNotesOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_account')) ? this.activeMenuColor : 'light'"  :data-desc="$t('toolbar.title_account')" @click="this.click(); $router.push({ path: $t('toolbar.path_account') })">
          <ion-icon :icon="personCircleOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button color="light"  :data-desc="$t('toolbar.title_signout')" @click="this.signOut(); this.click(); $router.push({ path: $t('toolbar.path_signout') })">
          <ion-icon :icon="logOutOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>
  
      <ion-fab-list v-else side="bottom">
       <ion-fab-button :color="isCurrentPath($t('toolbar.path_portfolio')) ? this.activeMenuColor : 'light'"  :data-desc="$t('toolbar.title_portfolio')" @click="navigateToCabanalibre()">
  <ion-icon :icon="searchOutline"></ion-icon>
</ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_events')) ? this.activeMenuColor : 'light'"  :data-desc="$t('toolbar.title_events')" @click="this.click(); $router.push({ path: $t('toolbar.path_events') })">
          <ion-icon :icon="musicalNotesOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_signin')) ? this.activeMenuColor : 'light'"  :data-desc="$t('toolbar.title_signin')" @click="this.click(); $router.push({ path: $t('toolbar.path_signin') })">
          <ion-icon :icon="logInOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button :color="isCurrentPath($t('toolbar.path_signup')) ? this.activeMenuColor : 'light'"  :data-desc="$t('toolbar.title_signup')" @click="this.click(); $router.push({ path: $t('toolbar.path_signup') })">
          <ion-icon :icon="accessibilityOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>
  
    </ion-fab>
    
    
    <ion-fab v-if="!this.isAuthorized" vertical="top" horizontal="end" slot="fixed" :class="this.isMenuOpen ? 'blur' : ''">
    <ion-title class="md sign-in-up-text" size="large">{{ $route.name == 'Sign In' ? 'Sign Up' : '' }}</ion-title>
      <ion-fab-button color="light" @click="isCurrentPath($t('toolbar.path_signin')) ? $router.push({ path: $t('toolbar.path_signup') }) : $router.push({ path: $t('toolbar.path_signin') })">
        <ion-icon :icon="isCurrentPath($t('toolbar.path_signin')) ? personAddOutline : personOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    
  
  </template>
  
  <style scoped>
    ion-title {
      color: var(--ion-color-dark);
      position: absolute;
      margin: 14px 60px;
      width: 250px
    }
    ion-fab-button[data-desc] {
      position: relative;
    }
    ion-fab {
      position: fixed;
      margin:0 10px
    }
  
    ion-fab-button[data-desc]::after {
      opacity: 0.9;
      position: absolute;
      content: attr(data-desc);
      z-index: 1;
      left: 55px;
      bottom: 4px;
      background-color: var(--ion-color-dark-shade);
      padding: 9px;
      border-radius: 15px;
      color: white;
      box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
    }
  
    .logo {
      background-image: url('https://app.cabanalibre.com/assets/icon/cl-logo.png/');
      background-size: cover;
      height: 25px;
      width: 25px;
      display: block;
      float: left;
      margin-right: 5px
    }
  
    .blur {
      filter: blur(5px);
      transition: 200ms;
    }
  
    .transition {
      transition: 100ms;
    }
  
    .sign-in-up-text {
      position: absolute;
      left: -160px;
      font-weight: 400;
      color: var(--ion-color-dark-shade)
    }
    .logo {
      position: fixed;
      left: 50vw;
      top: 10px;
      transform: translateX(-50%);
      height: 60px;
      width: 60px;
    }
    @media (max-width: 780px) {
      .hidden_title {
        display: none;
      }
    }
  
  </style>
  