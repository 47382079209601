import { MutationTree } from 'vuex'
import { TicketsState, ticketsState } from '@/modules/tickets/business/state'
import { Ticket } from '../types'

export enum MutationTypes {
  updateTickets = 'UPDATE_TICKETS',
  createTicketToken = 'CREATE_TICKET_TOKEN',
}
type RootState = ReturnType<typeof ticketsState>

export const mutations: MutationTree<RootState> = {
  [MutationTypes.updateTickets](state: TicketsState, tickets: [Ticket]) {
    state.tickets = tickets;
  },
  [MutationTypes.createTicketToken](state: TicketsState, ticketToken: string) {
    state.ticketToken = ticketToken;
  }
}
