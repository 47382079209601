<script lang="ts">
import { IonItem, IonLabel, IonInput, IonLoading, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { GettersTypes, ActionTypes } from '../../business'
import ForgottenPasswordModal from './modal/ForgottenPasswordModal.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
name: 'SignIn',
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonLoading,
    ButtonLoading
  },
  props: {
    prefilledEmail: {
      type: String
    },
    unAuthorized: {
      type: Boolean
    },
    createAccount: {
      type: Boolean,
      default() {
        return true
      }
    },
    styling: {
      type: Object,
      default() {
        return { 
          buttonText: 'Sign In',
          buttonColor: 'light'
         }
      }
    }
  },
  data() {
    return {
      loaderShown: false,
      submitted: false,
      emailDanger: "",
      passwordDanger: "",
      buttonDanger: "light",
      email: this.prefilledEmail ? this.prefilledEmail : this.$route.query.email,
      password: "",
      showPassword: false
    }
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized,
      invalidCredentials: GettersTypes.invalidCredentials
    }),
  },
  methods: {
    ...mapActions({
      signIn: ActionTypes.signIn,
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        await this.signIn({email: this.email, password: this.password})
        this.loaderShown = false
        if (!this.invalidCredentials) {
          this.$emit('confirm')
          if (this.$route.query.redirect && this.$route.query.share) {
            this.$router.push({path: this.$route.query.redirect, query: {share: this.$route.query.share}})
          } else if (this.$route.query.redirect ) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push({path: this.$t('toolbar.path_events')})
          }
          
        } else {
          this.invalidCredentials = true
          this.buttonDanger = 'danger'
        }
        
      }
    },
    validateInput() {
      if (this.submitted) {
        this.passwordDanger = this.password.length >= 8 ? '' : 'danger'
        this.buttonDanger = (this.password && this.email)  ? this.styling.buttonColor : 'danger'
        this.emailDanger = this.validateEmail(this.email) ? '' : 'danger'
        this.invalidCredentials = false
        return (this.password.length >= 8 && this.validateEmail(this.email))
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async openForgottenPasswordModal() {
      const modal = await modalController.create({
        component: ForgottenPasswordModal,
        componentProps: {prefilledEmail: this.email}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
  },
  mounted() {
    if (this.isAuthorized && this.$route.query.redirect) {
      if (this.$route.query.share) {
        this.$router.push({path: this.$route.query.redirect, query: {share: this.$route.query.share}})
      } else {
        this.$router.push({path: this.$route.query.redirect})
      }
    }
  },
  watch: {
    isAuthorized() {
      if (this.isAuthorized && this.$route.query.redirect) {
        if (this.$route.query.share) {
          this.$router.push({path: this.$route.query.redirect, query: {share: this.$route.query.share}})
        } else {
          this.$router.push({path: this.$route.query.redirect})
        }
      }
    }
  },
  setup() {
    return {
      eyeOutline,
      eyeOffOutline
    }
  }
});
</script>


<template>
  <ion-loading
    :is-open="this.loaderShown"
    message=""
  ></ion-loading>
  <div class="wrapper" v-show="!this.isAuthorized || this.unAuthorized">
    <form>
      <ion-text v-if="this.invalidCredentials" color="danger" v-html="$t('auth.signin.invalid_credentials')"></ion-text>
      <div v-else style="height: 20px"></div>
      <ion-item>
        <ion-label position="floating" :color="this.emailDanger" v-html="$t('auth.email')"></ion-label>
        <ion-input v-model="email" @change="validateInput()" @keyup.enter="submit()" :disabled="this.prefilledEmail" type="email"></ion-input>
      </ion-item>
      <br>
      <ion-item>
        <ion-label position="floating" :color="this.passwordDanger" v-html="$t('auth.password')"></ion-label>
        <ion-input v-model="password" @change="validateInput()" @keyup.enter="submit()" :type=" this.showPassword ? 'text' : 'password'">
        </ion-input>
        <ion-button class="show_password" slot="end" @click="this.showPassword = !this.showPassword">
            <ion-icon :icon="this.showPassword ? eyeOffOutline : eyeOutline"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-button mode="md" class="forgotten_password" :color="this.passwordDanger || this.invalidCredentials ? 'danger' : ''" size="small" @click="this.openForgottenPasswordModal()" v-html="$t('auth.signin.forgotten_password')"></ion-button>
      <ion-button mode="md" v-show="this.createAccount" color="light" class="create_account" size="small" @click="this.$router.push({ path: this.$t('toolbar.path_signup'), query: {redirect: this.$route.query.redirect} })" v-html="$t('auth.signin.create_account')"></ion-button>
      <div class="button-wrapper">
        <button-loading @click="this.submit()" 
          :color="this.styling.buttonColor ? this.styling.buttonColor : this.buttonDanger" 
          :isLoading="this.loaderShown" 
          :text="this.styling.buttonText">
        </button-loading>
      </div>
      
      
    </form>
  </div>
</template>

<style scoped>

ion-header {
  margin-bottom: 40px;
}

ion-item {
  --background: var(--ion-color-white)
}

.button-wrapper {
  text-align: center;
}

.forgotten_password {
  margin: 10px 0;
  margin-top: 20px;
  --background: none; 
  --color: var(--ion-color-dark-shade);
  padding: 0;
  border: none;
  --box-shadow: none;
}

.create_account {
  margin: 10px 0;
  margin-bottom: 20px;
  --background: var(--ion-color-dark-shade); 
  --color: var(--ion-color-dark);
  padding: 0;
  border: none;
  --box-shadow: none;
}


.wrapper {
  width: 100%;
  margin: auto;
  max-width: 320px;
  height: 200px;
  max-height: 320px;
  
}


.button {
  text-align: center
}

.show_password {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  --background: none;
  --box-shadow: none;
  --color: var(--ion-color-dark-shade);
  z-index: 10;
}

@media (max-width: 780px) { 
  .show_password {
    top: 22px;
  }
}


</style>
