export enum GettersTypes {
  checkout = 'CHECKOUT',
  basket = 'BASKET',
  totalPrice = 'TOTAL_PRICE',
  loaderShown = 'LOADER_SHOWN',
  getContact = 'GET_CONTACT',
  tokens = 'TOKENS',
}

export const getters = {
  [GettersTypes.checkout]: (data) => data?.checkout,
  [GettersTypes.basket]: (data) => data?.basket,
  [GettersTypes.totalPrice]: (data) => data?.totalPrice,
  [GettersTypes.loaderShown]: (data) => data?.loaderShown,
  [GettersTypes.getContact]: (data) => data?.contact,
  [GettersTypes.tokens]: (data) => data?.tokens,
}
