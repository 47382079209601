import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "grid_1" }
const _hoisted_3 = { class: "grid_2" }
const _hoisted_4 = { class: "grid_3" }
const _hoisted_5 = { class: "grid_4" }
const _hoisted_6 = { class: "grid_5" }
const _hoisted_7 = { class: "grid_6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_loading, {
        "is-open": this.loaderShown,
        message: ""
      }, null, 8, ["is-open"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!this.loaderShown)
            ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_header, { class: "ion-no-border" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, {
                        innerHTML: _ctx.$t('auth.account.title')
                      }, null, 8, ["innerHTML"]),
                      _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                      _createVNode(_component_ion_subtitle, {
                        innerHTML: _ctx.$t('auth.account.subtitle')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_subtitle, {
                    innerHTML: _ctx.$t('auth.account.details')
                  }, null, 8, ["innerHTML"]),
                  _createElementVNode("form", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            innerHTML: _ctx.$t('auth.name')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_input, {
                            value: this.accountInfo.name,
                            modelValue: _ctx.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                            onChange: _cache[1] || (_cache[1] = ($event: any) => (this.editActive = true))
                          }, null, 8, ["value", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            innerHTML: _ctx.$t('auth.surname')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_input, {
                            value: this.accountInfo.surname,
                            modelValue: _ctx.surname,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surname) = $event)),
                            onChange: _cache[3] || (_cache[3] = ($event: any) => (this.editActive = true))
                          }, null, 8, ["value", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "floating" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('auth.email')) + " ", 1),
                              _createVNode(_component_ion_text, {
                                class: "danger-message",
                                innerHTML: _ctx.$t('auth.account.email_change')
                              }, null, 8, ["innerHTML"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            value: this.accountInfo.email,
                            modelValue: _ctx.email,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event)),
                            disabled: true,
                            type: "email"
                          }, null, 8, ["value", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            color: this.documentDanger
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('auth.document')), 1)
                            ]),
                            _: 1
                          }, 8, ["color"]),
                          _createVNode(_component_ion_input, {
                            class: "document_input",
                            modelValue: _ctx.document,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.document) = $event)),
                            onChange: _cache[6] || (_cache[6] = ($event: any) => (this.editActive = true))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "floating",
                            innerHTML: _ctx.$t('auth.phone')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_input, {
                            value: this.accountInfo.phone,
                            modelValue: _ctx.phone,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.phone) = $event)),
                            type: "tel",
                            onChange: _cache[8] || (_cache[8] = ($event: any) => (this.editActive = true))
                          }, null, 8, ["value", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "floating" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(this.accountInfo.instagram[0] != "@" ? _ctx.$t('auth.referral') : _ctx.$t('auth.instagram')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            value: this.accountInfo.instagram,
                            modelValue: _ctx.instagram,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.instagram) = $event)),
                            onChange: _cache[10] || (_cache[10] = ($event: any) => (this.editActive = true))
                          }, null, 8, ["value", "modelValue"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createVNode(_component_ion_button, {
                    class: "delete_account",
                    color: "light",
                    size: "small",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (this.openDeleteAccountModal())),
                    innerHTML: _ctx.$t('auth.account.delete_account')
                  }, null, 8, ["innerHTML"]),
                  _withDirectives(_createVNode(_component_ion_row, { class: "confirm" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        mode: "md",
                        color: "danger",
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (this.cancelEdit())),
                        innerHTML: _ctx.$t('cancel')
                      }, null, 8, ["innerHTML"]),
                      _createVNode(_component_button_loading, {
                        color: 'success',
                        isLoading: this.buttonLoading,
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (this.confirm())),
                        text: _ctx.$t('confirm')
                      }, null, 8, ["isLoading", "text"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, this.editActive]
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}