import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "add-icon"
}
const _hoisted_3 = {
  key: 1,
  class: "item-divider"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "item-divider margin-top-0" }
const _hoisted_6 = { class: "upcoming-events" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_event = _resolveComponent("edit-event")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_event_card = _resolveComponent("event-card")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: "false" }, {
        default: _withCtx(() => [
          (this.isInternalRole())
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (this.addNewEvent)
                  ? (_openBlock(), _createBlock(_component_edit_event, {
                      key: 0,
                      class: "add-event",
                      onClose: _cache[0] || (_cache[0] = ($event: any) => (this.addNewEvent = false))
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.addCircleOutline,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNewEvent = true))
                      }, null, 8, ["icon"])
                    ]))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, { lines: "none" }, {
            default: _withCtx(() => [
              (this.loaderShown)
                ? (_openBlock(), _createBlock(_component_event_card, {
                    key: 0,
                    loading: true
                  }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.getUpcomingEvents(), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "upcoming-events",
                      key: item
                    }, [
                      (item.edit)
                        ? (_openBlock(), _createBlock(_component_edit_event, {
                            key: 0,
                            event: item,
                            onClose: ($event: any) => (this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = false)
                          }, null, 8, ["event", "onClose"]))
                        : (_openBlock(), _createBlock(_component_event_card, {
                            key: 1,
                            event: item,
                            loading: false,
                            onEdit: ($event: any) => (this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = true)
                          }, null, 8, ["event", "onEdit"]))
                    ]))
                  }), 128))
            ]),
            _: 1
          }),
          (!this.loaderShown)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_ion_label, { class: "divider-center" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      innerHTML: _ctx.$t('events.divider.past_events')
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, { lines: "none" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getPastEvents(), (item, index) => {
                return (_openBlock(), _createElementBlock("div", { key: item }, [
                  ((index != 0 && new Date(this.getPastEvents()[index].startDate).getFullYear() != new Date(this.getPastEvents()[index-1]?.startDate).getFullYear()))
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_ion_label, { class: "divider-center" }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, _toDisplayString(new Date(this.getPastEvents()[index].startDate).getFullYear()), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_6, [
                    (item.edit)
                      ? (_openBlock(), _createBlock(_component_edit_event, {
                          key: 0,
                          event: item,
                          onClose: ($event: any) => (this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = false)
                        }, null, 8, ["event", "onClose"]))
                      : (_openBlock(), _createBlock(_component_event_card, {
                          key: 1,
                          event: item,
                          loading: false,
                          onEdit: ($event: any) => (this.events[this.events.findIndex(x => x.eventId == item.eventId)].edit = true)
                        }, null, 8, ["event", "onEdit"]))
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData($event))),
            threshold: "100px",
            id: "infinite-scroll",
            disabled: _ctx.isDisabled,
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                "loading-spinner": "bubbles",
                "loading-text": ""
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}