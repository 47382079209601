import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "ion-padding" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_datetime, {
        minuteValues: "0,15,30,45",
        max: "2030-12-31",
        modelValue: _ctx.setDatetime,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.setDatetime) = $event)),
        ref: "datetime",
        "first-day-of-week": 1
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "buttons" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                color: "danger",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                color: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.confirm(); _ctx.close()})
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}