import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "details" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    color: this.scanData.item?.match(/sold/i) ? 'warning' : (this.scanStatus == 'SUCCESS' ? 'success' : (this.scanStatus == 'SCANNED' ? 'warning' : (this.scanStatus == 'INVALID' ? 'danger' : 'light')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(this.scanStatus) + " " + _toDisplayString(this.scanStatus == 'SCANNED' ? this.scanData.scanned + 'x' : ''), 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", {
          innerHTML: this.scanData.item
        }, null, 8, _hoisted_2),
        _createElementVNode("p", {
          innerHTML: this.scanData.title 
        }, null, 8, _hoisted_3),
        (this.scanData.price)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              innerHTML: 'Price:' + this.scanData.price + '€'
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("h3", {
          innerHTML: this.scanData.name + ' ' +this.scanData.surname 
        }, null, 8, _hoisted_5),
        _createElementVNode("p", {
          innerHTML: this.scanData.creation_time 
        }, null, 8, _hoisted_6),
        (this.scanData.username)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              innerHTML: 'Last scan by: ' + this.scanData.username 
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["color"]))
}