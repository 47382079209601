import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { id: "progress" }
const _hoisted_3 = {
  ref: "bar",
  id: "progress-bar",
  class: "pending"
}
const _hoisted_4 = {
  ref: "ul",
  id: "progress-num"
}
const _hoisted_5 = {
  ref: "step-1",
  class: "step"
}
const _hoisted_6 = {
  ref: "step-2",
  class: "step"
}
const _hoisted_7 = {
  ref: "step-3",
  class: "step"
}
const _hoisted_8 = {
  ref: "ul",
  id: "progress-desc"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { id: "progress-bar-bg" }, null, -1)),
      _createElementVNode("div", _hoisted_3, null, 512),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, null, 512),
        _createElementVNode("li", _hoisted_6, null, 512),
        _createElementVNode("li", _hoisted_7, null, 512)
      ], 512)
    ]),
    _createElementVNode("ul", _hoisted_8, [
      _createElementVNode("li", {
        ref: "desc-1",
        innerHTML: _ctx.$t('events.progress_bar.step_one')
      }, null, 8, _hoisted_9),
      _createElementVNode("li", {
        ref: "desc-2",
        innerHTML: _ctx.$t('events.progress_bar.step_two')
      }, null, 8, _hoisted_10),
      _createElementVNode("li", {
        ref: "desc-3",
        innerHTML: _ctx.$t('events.progress_bar.step_three')
      }, null, 8, _hoisted_11)
    ], 512)
  ]))
}