<script lang="ts">
import { IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ButtonLoading',
  props: {
    color: {
      type: String,
      default() {
        return 'medium'
      }
    },
    size: {
      type: String,
      default() {
        return 'medium'
      }
    },
    text: {
        type: String,
    },
    isLoading: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  components: {
    IonButton,
  },
});
</script>


<template>
  <ion-button :size="this.size" :color="this.color" mode="md">
    <div class="button-loader">
      <div v-if="this.isLoading" class="dot-carousel"></div>
    </div>
    <p :style="this.isLoading ? 'opacity: 0' : ''">{{ text }}</p>
  </ion-button>
</template>

<style scoped>
  .button-loader {
    position: absolute;
  }

  .dot-carousel {
    position: relative;
    left: -9999px;
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--ion-color-light);
    color: var(--ion-color-light);
    box-shadow: 9984px 0 0 0 var(--ion-color-light), 9999px 0 0 0 var(--ion-color-light), 10014px 0 0 0 var(--ion-color-light);;
    animation: dotCarousel 1.5s infinite linear;
  }
  
  @keyframes dotCarousel {
    0% {
      box-shadow: 9984px 0 0 -1px var(--ion-color-light), 9999px 0 0 1px var(--ion-color-light), 10014px 0 0 -1px var(--ion-color-light);
    }
    50% {
      box-shadow: 10014px 0 0 -1px var(--ion-color-light), 9984px 0 0 -1px var(--ion-color-light), 9999px 0 0 1px var(--ion-color-light);
    }
    100% {
      box-shadow: 9999px 0 0 1px var(--ion-color-light), 10014px 0 0 -1px var(--ion-color-light), 9984px 0 0 -1px var(--ion-color-light);
    }
  }
  
</style>
