<script lang="ts">
import { IonButton } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ActionTypes, GettersTypes } from '../../business'

export default defineComponent({
  name: 'QuantitySelector',
  props: {
    item: {
        type: Object,
    },
    tokens: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IonButton
  },
  // data() {
  //   return {
  //     totalPrice: 0
  //   }
  // },
  computed: {
    ...mapGetters('checkout',{
      basket: GettersTypes.basket,
    }),
    backgroundImage() {
      // const eventId = this.ticket.event_id;
      if (this.item.itemId) {
        return `url(/assets/background/element_0${this.item.itemId}_bg.svg)`
      } else {
        return `url(/assets/background/element_01_bg.svg)`
      }
      
       // dark mode
      // return `linear-gradient(0deg, var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff))) 120px, rgba(0,0,0,0.7) 100%), url(/assets/background/element_0${eventId}_bg.svg)`;
    },
    circleImage() {
      return process.env.VUE_APP_ASSETS_URL + '/image/bg_circle.svg'
    }
  },
  methods: {
    ...mapActions('checkout',{
      incrementBasket: ActionTypes.incrementBasket,
      totalPrice: ActionTypes.totalPrice,
      clearBasket: ActionTypes.clearBasket
    }),
    shake() {
      const el = this.$refs['avatarCircle'] as any;
        el.classList.add('shake')
        setTimeout(function(){
          el.classList.remove('shake')
        },300)
    },
    async increaseValue() {
      const el = this.$refs['quantity-'+this.item.itemId] as any
      const id = this.$route.query.id?.toString()
      var maxValue = (id && id.length == 4) ? 3 : ((this.$route.query.id == 1 || !this.$route.query.id) ? 100 : 10)
      
      if (el.value < maxValue) {
        await this.incrementBasket({itemId:this.item.itemId, price: this.item.price, priceId: this.item.priceId, quantity:1, basket: this.basket})
        this.sum = 0
        if (this.basket) {
          var cloneBasket = structuredClone(this.basket)
          var calcBasket = cloneBasket.filter(el => el.itemId == this.item.itemId)
          el.value = calcBasket[0].quantity
          this.shake()
        }
      }
      this.totalPrice(this.basket)
    },
    async decreaseValue() {
      const el = this.$refs['quantity-'+this.item.itemId] as any
      if (el.value > 0) {
        await this.incrementBasket({itemId:this.item.itemId, price: this.item.price, priceId: this.item.priceId, quantity:-1, basket: this.basket})
        this.sum = 0
        if (this.basket) {
          var cloneBasket = structuredClone(this.basket)
          var calcBasket = cloneBasket.filter(el => el.itemId == this.item.itemId)
          el.value = calcBasket[0].quantity
          this.shake()
        }
      }
      this.totalPrice(this.basket)
    },
    tokensInitialValue() {
      if (this.tokens) {
        const el = this.$refs['quantity-'+this.item.itemId] as any
        const initialValue = 10
        this.incrementBasket({itemId:this.item.itemId, price: this.item.price, priceId: this.item.priceId, quantity: initialValue, basket: this.basket})
        this.totalPrice(this.basket)
        el.value = initialValue
      }
    },
  },
  async mounted() {
    await this.clearBasket()
    if (this.tokens) {
      console.log('tokens')
      this.tokensInitialValue()
    }
  },
});
</script>

<template>
  <div class="wrapper">

    <ion-subtitle>{{this.item.item}}</ion-subtitle>
    
    <div class="selector">
      <div class="avatar center" :style="{ backgroundImage: backgroundImage }">
        <img ref="avatarCircle" class="avatar-circle" :src="circleImage">
      </div>

      <div class="buttons center">
        <ion-button mode="md" shape="round" class="fix-position" @click="decreaseValue()">-</ion-button>
        <input :ref="'quantity-'+this.item.itemId" type="number" class="quantity" min="0" value="0" disabled/>
        <!-- <input ref="quantity-2" type="number" class="quantity" min="0" value="0" disabled/> -->
        <ion-button mode="md" shape="round" @click="increaseValue()">+</ion-button>
      </div>
    </div>

    <ion-subtitle>{{this.item.price}}€</ion-subtitle>

  </div>
</template>

<style scoped>
ion-button {
  height: 100px;
  width: 100px;
  border-radius: 1000px;
  font-size: 50px;
  --background: transparent; 
  --color: var(--ion-color-dark);
  margin-top: -40px;
  padding: 0;
  border: none;
  --box-shadow: none;
  touch-action: manipulation;
}

ion-subtitle {
  font-size: 24px
}

.wrapper {
  height: 310px;
  width: 100%;
  margin-top: 50px;
  text-align: center;
}

.selector {
  position: relative; 
  height: 210px;
  width: 100%;
  margin: 20px 0;
}

.stripe-button{
  padding-top: 200px
}

.avatar {
  position: absolute;
  height: 200px;
  width: 200px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.avatar-circle {
  float: left;
  padding: 10%
}

.center {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}


.buttons {
  margin-top: 70px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.buttons * {
  width: 30%;
  text-align: center;
}

.quantity {
  
  font-size: 50px;
  text-align: center;
  background-color: transparent;
  border: none;
  opacity: 1;

}

.shake {
    animation: shake 300ms cubic-bezier(.36,.07,.19,.97) both;
    animation-fill-mode: forwards;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}



@keyframes shake {
    0% {
      transform: translate3d(0, 0, 0);
    }
    
    20% {
      transform: translate3d(2px, 2px, 0);
    }
  
    40% {
      transform: translate3d(-2px, -2px, 0);
    }
  
    60% {
      transform: translate3d(2px, -2px, 0);
    }

    80% {
      transform: translate3d(-2px, 2px, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @media (max-width: 780px) { 
    ion-button {
      width: 80px
    }

    .quantity {
      margin-left: 0;
      margin-top: -5px;
      width: 40%;
    }

    .buttons ion-button {
      width: 20%;
    }

    .fix-position {
      margin-left: -15px; 
      margin-right: 15px;
    }
  }

</style>
