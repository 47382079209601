<script lang="ts">
import { IonContent, IonPage, IonTitle, IonCard, IonLoading, IonInput, IonLabel, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { createOutline } from 'ionicons/icons';
import DeleteAccountModal from '../components/modal/DeleteAccountModal.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
name: 'SignInPage',
  components: {
    IonContent,
    IonPage,
    IonTitle,
    IonCard,
    IonLoading,
    IonInput,
    IonLabel,
    ButtonLoading
  },
  setup() {
    return {
      createOutline
    }
  },
  data() {
    return {
      loaderShown: true,
      buttonLoading: false,
      name: "",
      surname: "",
      email: "",
      phone: "",
      instagram: "",
      document: "",
      editActive: false
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: GettersTypes.accountInfo,
    }),
  },
  methods: {
    ...mapActions({
      getAccountInfo: ActionTypes.getAccountInfo,
      updateAccountInfo: ActionTypes.updateAccountInfo
    }),
    hideLoader() {
      this.loaderShown = false
    },
    async confirm() {
      this.buttonLoading = true
      await this.updateAccountInfo({ 
        name: this.name,
        surname: this.surname,
        email: this.email,
        instagram: this.instagram,
        document: this.document,
        phone: this.phone 
      })
      this.buttonLoading = false
      this.editActive = false
    },
    cancelEdit() {
      this.editActive = false
      this.getAccountInfo(this.$cookies.get('access_token'))
    },
    async openDeleteAccountModal() {
      const modal = await modalController.create({
        component: DeleteAccountModal,
        componentProps: {prefilledEmail: this.accountInfo.email}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      if (role === 'confirm') {
        this.$emit('showLoader')
      }
    },
  },
  mounted() {
    this.getAccountInfo(this.$cookies.get('access_token'))
  },
  watch: {
    accountInfo() {
      this.name = this.accountInfo.name
      this.surname = this.accountInfo.surname
      this.email = this.accountInfo.email
      this.instagram = this.accountInfo.instagram
      this.phone = this.accountInfo.phone
      this.document = this.accountInfo.document
      this.hideLoader()
    }
  }
  
});
</script>


<template>
  <ion-page>
   <ion-loading
      :is-open="this.loaderShown"
      message=""
    ></ion-loading>
      
    <ion-content>
      <ion-card v-if="!this.loaderShown">
          <ion-header class="ion-no-border">
            <ion-title v-html="$t('auth.account.title')"></ion-title>
            <br>
            <ion-subtitle v-html="$t('auth.account.subtitle')"></ion-subtitle>
          </ion-header>
          <ion-subtitle v-html="$t('auth.account.details')"></ion-subtitle>
          
        <form class="container">
          <div class="grid_1">
            <ion-item>
              <ion-label position="floating" v-html="$t('auth.name')"></ion-label>
              <ion-input :value="this.accountInfo.name" v-model="name" @change="this.editActive = true"></ion-input>
            </ion-item>
          </div>

          <div class="grid_2">
            <ion-item>
              <ion-label position="floating" v-html="$t('auth.surname')"></ion-label>
              <ion-input :value="this.accountInfo.surname" v-model="surname" @change="this.editActive = true"></ion-input>
            </ion-item>
          </div>

          <div class="grid_3">
            <ion-item>
              <ion-label position="floating">
                {{ $t('auth.email') }}
                <ion-text class="danger-message" v-html="$t('auth.account.email_change')"></ion-text>
              </ion-label>
              <ion-input :value="this.accountInfo.email" v-model="email" :disabled="true" type="email"></ion-input>
            </ion-item>
          </div>

          <div class="grid_4">
            <ion-item>
              <ion-label position="floating" :color="this.documentDanger">{{ $t('auth.document') }}</ion-label>
              <ion-input class="document_input" v-model="document" @change="this.editActive = true"></ion-input>
            </ion-item>
          </div>

          <div class="grid_5">
            <ion-item>
              <ion-label position="floating" v-html="$t('auth.phone')"></ion-label>
              <ion-input :value="this.accountInfo.phone" v-model="phone" type="tel" @change="this.editActive = true"></ion-input>
            </ion-item>
          </div>

          <div class="grid_6">
            <ion-item>
              <ion-label position="floating">{{this.accountInfo.instagram[0] != "@" ? $t('auth.referral') : $t('auth.instagram')}}</ion-label>
              <ion-input :value="this.accountInfo.instagram" v-model="instagram" @change="this.editActive = true"></ion-input>
            </ion-item>
          </div>
        </form>
        <ion-button class="delete_account" color="light" size="small" @click="this.openDeleteAccountModal()" v-html="$t('auth.account.delete_account')"></ion-button>
        <ion-row v-show="this.editActive" class="confirm">
          <ion-button mode="md" color="danger" @click="this.cancelEdit()" v-html="$t('cancel')"></ion-button>
          <button-loading :color="'success'" :isLoading="this.buttonLoading" @click="this.confirm()" :text="$t('confirm')"></button-loading>
        </ion-row>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-card {
  margin: 20px auto;
  max-height: 720px;
  max-width: 780px;
  padding: 50px;
  --box-shadow: none;
  background: var(--ion-color-white)
}

ion-header {
  margin-top: 20px;
  margin-bottom: 50px;
}

ion-title {
  position: relative;
  padding: 0 10px;
  text-align: left
}

ion-item {
  --background: var(--ion-color-white)
}

.edit {
  position: absolute;
  top: 20px;
  right: 40px;
  height: 40px;
  width: 40px;
  z-index: 10;
  --padding-start: 0;
  --padding-end: 0;
}

.edit ion-icon {
  margin-left: 2px;
  min-width: 20px;
}

.confirm {
  justify-content: right;
}

.confirm ion-button {
  margin: 0 10px;
}

.delete_account {
  --background: transparent; 
  --color: var(--ion-color-dark);
  padding: 0;
  margin-top: 20px;
  border: none;
  --box-shadow: none;
}

.danger-message {
  font-size: 12px;
}



.container {  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 20px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    "grid_1 grid_2"
    "grid_3 grid_3"
    "grid_4 grid_4"
    "grid_5 grid_5"
    "grid_6 grid_6";
  margin-bottom: 20px
}

.grid_1 { grid-area: grid_1; }

.grid_2 { grid-area: grid_2; }

.grid_3 { grid-area: grid_3; }

.grid_4 { grid-area: grid_4; }

.grid_5 { grid-area: grid_5; }

.grid_6 { grid-area: grid_6; }



@media (max-width: 780px) { 
  ion-card {
    box-shadow: none;
    padding: 20px;
    margin: 20px auto;
  }

  .container {  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "grid_1"
    "grid_2"
    "grid_3"
    "grid_4"
    "grid_5"
    "grid_6";
}

}
</style>
