<script lang="ts">
import { mapActions } from 'vuex'
import { ActionTypes } from '../../business'
import { IonContent, IonTitle, IonInput, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
    name: 'ForgottenPasswordPage',
    props: {
        prefilledEmail: {
            type: String
        }
    },
    data() {
      return {
        loaderShown: false,
        submitted: false,
        passwordDanger: "",
        password: "",
        buttonDanger: "primary"
      }
    },
    components: { 
      IonContent, 
      IonTitle,
      IonInput,
      IonLabel,
      ButtonLoading
    },
    setup() {
      return {
        closeOutline
      }
    },
    methods: {
    ...mapActions({
      resetPassword: ActionTypes.resetPassword,
    }),
    async submit() {
      this.submitted = true
      if (this.validateInput()) {
        this.loaderShown = true
        await this.resetPassword({token: this.$route.query.token, password: this.password})
        this.$router.push({path: this.$t('toolbar.path_signin')})
      } else {
        this.loaderShown = false
      }
    },
    validateInput() {
      if (this.submitted) {
        
        this.passwordDanger = this.password.length >= 8 ? '' : 'danger'
        this.buttonDanger = this.password.length >= 8  ? 'primary' : 'danger'
        return this.password.length >= 8
      }
    }
  },
});
</script>

<template>
  <ion-content>
    <div class="sign-in-header">
      <ion-title v-html="$t('auth.reset_password.modal_title')"></ion-title>
      <form>
        <ion-item>
          <ion-label position="floating" :color="this.passwordDanger">
            {{ $t('auth.reset_password.password') }} 
            <ion-text class="danger-message" v-if="this.passwordDanger" color="danger" v-html="$t('auth.reset_password.password_validation')"></ion-text>  
          </ion-label>
          <ion-input  v-model="password" @change="validateInput()" @keyup.enter="submit()" type="password"></ion-input>
        </ion-item>
        <div class="button">
          <button-loading @click="this.submit();" :isLoading="this.loaderShown" :color="this.buttonDanger" :text="$t('confirm')"></button-loading>
        </div>
      </form>
    </div>
    
  </ion-content>
</template>

<style scoped>
ion-title {
    margin: 10px 0;
}
form {
  margin-top: 20px;
}
.sign-in-subtitle {
    margin: 20px 130px;
    margin-bottom: 50px;
    padding-bottom: 50px;
}
.sign-in-header {
  max-width: 300px;
  margin: 20px auto;
  margin-top: 80px;
  margin-bottom: 50px
}
.center {
  position: absolute;
  margin: 50px auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 120px;
}

.button {
  margin-top: 20px;
  text-align: center
}
</style>
