<script lang="ts">
import { IonCard, IonCardHeader, IonCardSubtitle, modalController } from '@ionic/vue';
import { IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonRow, IonInput, IonCheckbox, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex'
import { ActionTypes } from '@/modules/events/business'
import { informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, checkmark, close } from "ionicons/icons";
import DatePickerModal from './modal/DatePickerModal.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import IconPicker from './IconPickerComponent.vue'
import { EventState } from '../../enums'

export default defineComponent({
  name: 'EditEvent',
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonAccordionGroup, 
    IonAccordion, 
    IonItem, 
    IonLabel,
    IonRow,
    IonInput,
    IconPicker,
    IonCheckbox,
    IonSegment, 
    IonSegmentButton,
    ButtonLoading
  },
  props: {
    event: {
        type: Object,
        default() {
            return {eventId: "", startDate: "", endDate: ""}
        }
    },
  },
  data() {
    return {
      edit: true,
      forceCloseTools: false,
      newEvent: {
        eventId: this.event.eventId ? this.event.eventId : undefined,
        title: this.event.title ? this.event.title : "",
        startDate:  this.event.startDate ? this.event.startDate : undefined,
        endDate:  this.event.endDate ? this.event.endDate : undefined,
        description:  this.event.description ? this.event.description : "",
        details: this.event.details?.length ? this.event.details : [{title:"", description: "", icon: ""}],
        type: this.event.type ? this.event.type : EventState.PLAN_NOREG,
        linkInfoPack: this.event.linkInfoPack,
        linkWhatsApp: this.event.linkWhatsApp,
        checkout: {
          pageId: this.event.pageId ? this.event.pageId : undefined,
          title: undefined,
          subtitle: undefined,
          disabled: undefined,
          items: [{
            itemId: undefined,
            item: undefined,
            priceId: undefined,
            price: undefined
          }]
        },
        hidden: undefined
      },
      isLoadingConfirm: false,
      isLoadingDelete: false,
      ready: false,
      titleDanger: false,
      dateDanger: false,
      EventState
    }
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'IS_AUTHORIZED',
      auth: 'AUTH'
    }),
    ...mapGetters('checkout',{
      checkout: 'CHECKOUT'
    }),
    backgroundImage() {
      const eventId = this.event.eventId;
      return `linear-gradient(180deg, rgba(0,0,0,0) 0, var(--background-color-light) 80px), url(/assets/background/card-bg-${eventId}.svg)`;
    },
  },
  methods: {
    ...mapActions('events', {
      registerToEvent: ActionTypes.registerToEvent,
      getEvents: ActionTypes.getEvents
    }),
    ...mapActions('checkout', {
      getCheckout: 'GET_CHECKOUT'
    }),
    ...mapActions('internal', {
      editEvent: 'EDIT_EVENT'
    }),
    ...mapActions({
      isAuthorized: 'IS_AUTHORIZED',
    }),
    getOrdinalNum(n: number) {
      return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    },
    parseDate(startDate, endDate) {
      if (startDate) {
        const formattedStartDate = new Date(startDate)
        const formattedEndDate = new Date(endDate)
        if (typeof formattedStartDate === 'object' && formattedStartDate !== null && 'toLocaleDateString' in formattedStartDate) {
          if (endDate) {
            const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
            const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
            const endMonth = formattedEndDate.toLocaleDateString("en-US", { month: 'short' })
            const endDay = this.getOrdinalNum(parseInt(formattedEndDate.toLocaleDateString("en-US", { day: 'numeric' })))
            const date = startMonth == endMonth ? startMonth + " " + startDay + " - " + endDay : startMonth + " " + startDay + " - " + endMonth + " " + endDay
            return date
          } else {
            const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
            const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
            const date = startMonth + " " + startDay
            return date
          }
        }
      }
    },
    async openStartDatePickerModal() {
      const modal = await modalController.create({
      component: DatePickerModal,
      cssClass: 'date-picker-modal',
      componentProps: {
        defaultDatetime: new Date(this.newEvent.startDate)
      }
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
      var date = new Date(data); // some mock date
      var datetime = date.getTime();
      this.newEvent.startDate = datetime
      // this.message = `Hello, ${data}!`;
      }
    },
    async openEndDatePickerModal() {
      const modal = await modalController.create({
      component: DatePickerModal,
      cssClass: 'date-picker-modal',
      componentProps: {
        defaultDatetime: new Date(this.newEvent.endDate)
      }
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
      var date = new Date(data); // some mock date
      var datetime = date.getTime();
      this.newEvent.endDate = datetime
      
      // this.message = `Hello, ${data}!`;
      }
    },
    closeTools() {
      this.forceCloseTools = true
      setTimeout(() => {
        this.forceCloseTools = false
      }, 10);
    },
    isInternalRole() {
      var token = this.auth.token
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload).role != 'user';
      }
      return false
    },
    adjustTextareaHeight(e) {
      var el = this.$refs[e] as any
      if (el) {
        var height = el.scrollHeight
        el.style = 'height:'+height+'px;'
      }
    },
    adjustDetailsTextareaHeight(e) {
      var el = this.$refs[e] as any
      if (el) {
        el.forEach(element => {
          var height = element.children[3].scrollHeight
          element.children[3].style = 'height:'+height+'px;'
        });
      }
    },
    pickIcon(e) {
      this.newEvent.details[e.index].icon = e.icon
    },
    async confirmEdit() {
      this.isLoadingConfirm = true
      if (this.ready) {
        var event = structuredClone(this.newEvent)
        this.titleDanger = false
        this.dateDanger = false
        if (event.title && event.startDate) {
          event.details = event.details.filter(e => {return e.title})
          event.checkout.items = event.checkout.items.filter(e => {return e.item})
          event.checkout = event.checkout.items.length ? event.checkout : undefined
          await this.editEvent({token: this.auth.token, data: event})
          await this.getEvents(this.auth.token)
          setTimeout(() => {
            this.$emit('close')
          }, 1000)
        } else {
          this.titleDanger = true
          this.dateDanger = true
        }
        
      }
      this.isLoadingConfirm = false
    },
    async hideEvent() {
      this.isLoadingDelete = true
      if (this.ready) {
        var event = structuredClone(this.newEvent)
        event.hidden = true
        event.details = event.details.filter(e => {return e.title})
        event.checkout.items = event.checkout.items.filter(e => {return e.item})
        event.checkout = event.checkout.items.length ? event.checkout : undefined
        await this.editEvent({token: this.auth.token, data: event})
        await this.getEvents(this.auth.token)
        setTimeout(() => {
          this.$emit('close')
        }, 1000)
      }
      this.isLoadingDelete = false
    }
  },
  setup() {
    return {
      informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline,
      checkmark, close
    };
  },
  watch: {
    'newEvent.description': {
      handler(val, oldVal) {
        this.adjustTextareaHeight('textareaDescription')
      }
    },
    'newEvent.details': {
      handler(val, oldVal) {
        this.adjustDetailsTextareaHeight('textareaDetailsDescription')
        var details = this.newEvent.details
        if (details.length && details.slice(-1)[0].title) {
          details.push({title:"", description: "", icon: ""})
        } 
        this.newEvent.details = details  
      },
      deep: true
    },
    'newEvent.checkout.items': {
      handler(val, oldVal) {
        if (Object.keys(this.checkout).length) {
          if (this.newEvent.checkout && this.newEvent.checkout.items) {
            var items = this.newEvent.checkout.items
            if (items.length && items.slice(-1)[0].item) {
              items.push({itemId: undefined, item: undefined, priceId: undefined, price: undefined})
            } 
            this.newEvent.checkout.items = items  
          }
        }
        
      },
      deep: true
    },
    checkout() {
      if (this.checkout) {
        this.newEvent.checkout.pageId = this.checkout.pageId
        this.newEvent.checkout.title = this.checkout.title
        this.newEvent.checkout.subtitle = this.checkout.subtitle
        this.newEvent.checkout.disabled = this.checkout.disabled
        this.checkout.items.forEach((e, i) => {
          var item = {
            itemId: e.itemId,
            item: e.item,
            priceId: e.priceId,
            price: e.price
          }
          if (i == 0) {
            this.newEvent.checkout.items[0] = item
          } else {
            this.newEvent.checkout.items.push(item)
          }
        });
      }
    }
  },
  async mounted() {
    setTimeout(() => {
      this.adjustTextareaHeight('textareaDescription')
      this.adjustDetailsTextareaHeight('textareaDetailsDescription')
    },10)
    if (this.event.pageId) {
      this.isLoadingConfirm = true
      await this.getCheckout(this.event.pageId)
      this.isLoadingConfirm = false
      this.ready = true;
    } else {
      this.ready = true;
    }
  }
});
</script>

<template>

  <ion-card v-if="isInternalRole()" :style="{ backgroundImage: backgroundImage}" @click="closeTools()">
    <!-- HEADER -->
    <ion-card-header style="justify-content: center;">
      <ion-input v-model="this.newEvent.title" class='title' :color="this.titleDanger ? 'danger' : 'dark'" placeholder="Title"></ion-input>
      <div style="display: flex; width: 100%; align-items: center; justify-content: center;">
        <div>
          <ion-card-subtitle style="width: 100px; margin: 0;" v-model="this.newEvent.startDate" :color="this.dateDanger ? 'danger' : 'dark'" @click="openStartDatePickerModal()">{{ this.newEvent.startDate ? (new Date(this.newEvent.startDate).toLocaleDateString("en-US", { month: 'short' }) + " " + this.getOrdinalNum(new Date(this.newEvent.startDate).toLocaleDateString("en-US", { day: 'numeric' }))) : 'Start Date' }}</ion-card-subtitle>
        </div>
        -
        <div style="width: 100px;">
          <ion-card-subtitle style="width: 100%; margin: 0" v-model="this.newEvent.endDate" :color="this.dateDanger ? 'danger' : 'dark'" @click="openEndDatePickerModal()">{{ this.newEvent.endDate ? (new Date(this.newEvent.endDate).toLocaleDateString("en-US", { month: 'short' }) + " " + this.getOrdinalNum(new Date(this.newEvent.endDate).toLocaleDateString("en-US", { day: 'numeric' }))) : 'End Date' }}</ion-card-subtitle>
        </div>
      </div>
    </ion-card-header>

    <textarea v-model="this.newEvent.description" ref="textareaDescription" placeholder="Description" ></textarea>
    
    <ion-card-footer>  
      <!-- INFO DROPDOWN -->
      <ion-accordion-group>
        <ion-accordion value="first">
          <ion-item class="dropdown" slot="header" color="white">
            <ion-label v-html="$t('events.more_details')"></ion-label>
          </ion-item>
          <div readonly class="wrapper" slot="content">
            <div class="detail" v-for="(item,i) in newEvent.details" :key="item" ref="textareaDetailsDescription">
              <icon-picker class="icon-picker" :forceClose="this.forceCloseTools" :setIcon="newEvent.details[i].icon" :index="i" @icon="this.pickIcon"></icon-picker>
              <ion-input v-model="newEvent.details[i].title" class='title' placeholder="Title"></ion-input>
              <textarea v-model="newEvent.details[i].description" placeholder="Description"></textarea>
              {{ this.adjustDetailsTextareaHeight('textareaDetailsDescription') }}
            </div>
          </div>
          <!-- <ion-item v-show="this.infoShown" class="dropdown dropdown-bottom" slot="header" color="white"><ion-label class="collapse" @click="this.triggerCollapse()">Collapse</ion-label></ion-item>-->
        </ion-accordion>
      </ion-accordion-group>
      
    </ion-card-footer>

    <ion-accordion-group>
      <ion-accordion value="first">
        <ion-item class="checkout-dropdown" slot="header" color="white">
          <ion-label>Settings</ion-label>
        </ion-item>
        <div readonly class="wrapper" slot="content">
          <ion-segment v-model="this.newEvent.type" mode="ios">
            <ion-segment-button :value="this.EventState.PLAN_NOREG">
              <ion-label>Planned</ion-label>
            </ion-segment-button>
            <ion-segment-button :value="EventState.PLAN">
              <ion-label>Registrations</ion-label>
            </ion-segment-button>
            <ion-segment-button :value="EventState.LIVE">
              <ion-label>Payments</ion-label>
            </ion-segment-button>
            <ion-segment-button :value="EventState.SOLD_OUT">
              <ion-label>Sold Out</ion-label>
            </ion-segment-button>
          </ion-segment>
          <ion-item>
            <ion-label>Info Pack</ion-label>
            <ion-input v-model="this.newEvent.linkInfoPack" placeholder="https://bit.ly/"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>WhatsApp</ion-label>
            <ion-input v-model="this.newEvent.linkWhatsApp" placeholder="https://chat.whatsapp.com/"></ion-input>
          </ion-item>
        </div>
      </ion-accordion>
    </ion-accordion-group>

    <ion-accordion-group>
      <ion-accordion value="first">
        <ion-item class="checkout-dropdown" slot="header" color="white">
          <ion-label>Checkout page</ion-label>
        </ion-item>
        <div readonly class="wrapper" slot="content">
          <ion-row>
            <ion-item class="checkout-subtitle">
              <ion-label>Subtitle</ion-label>
              <ion-input v-model="newEvent.checkout.subtitle" placeholder="Early birds"></ion-input>
            </ion-item>
            <ion-item class="checkout-enabled">
              <ion-label>Disabled</ion-label>
              <ion-checkbox type="md" v-model="this.newEvent.checkout.disabled" :checked='this.newEvent.checkout.disabled'></ion-checkbox>
            </ion-item>
          </ion-row>

          <div class="container" v-for="(item,i) in this.newEvent.checkout.items" :key="item">
            <ion-item class="checkout-item">
              <ion-label>Item</ion-label>
              <ion-input v-model="newEvent.checkout.items[i].item" placeholder="Entrance"></ion-input>
            </ion-item>
            <ion-row>
              <ion-item class="checkout-stripe">
                <ion-label>Stripe</ion-label>
                <ion-input v-model="newEvent.checkout.items[i].priceId" placeholder="price_id"></ion-input>
              </ion-item>
              <ion-item class="checkout-price">
                <ion-label>Price</ion-label>
                <ion-input v-model="newEvent.checkout.items[i].price" placeholder="20" type="number"></ion-input>
                <ion-label class="currency" slot="end">€</ion-label>
              </ion-item>
            </ion-row>
          </div>
        </div>
      </ion-accordion>
    </ion-accordion-group>
    <ion-row class="submit">
      <button-loading class="cancel" color="medium" size="small" text="Cancel" @click="this.$emit('close')"></button-loading>
      <button-loading class="confirm" color="success" size="small" text="Submit" @click="confirmEdit()" :isLoading="this.isLoadingConfirm"></button-loading>
    </ion-row>
    <ion-row v-if="this.event.eventId" class="delete">
      <button-loading class="remove" color="danger" size="small" text="Remove" @click="hideEvent()" :isLoading="this.isLoadingDelete"></button-loading>
    </ion-row>
    <!-- PAST EVENTS -->
  </ion-card>
</template>

<style scoped>


.submit {
  width: 220px;
  float: right;
}


.delete {
  width: 100px;
  float: left;
}

.remove {
  margin-left: 15px;
}



textarea {
  background: transparent;
  border: none;
  width: 100%;
  text-align: center;  
  line-height: 21px;
  padding-bottom: 15px;
  min-height: 21px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

textarea:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

textarea::placeholder {
  color: var(--ion-color-dark-shade)
}

ion-card {
  width: 95%;
  margin: 50px auto;
  padding: 20px;
  max-width: 720px;
  text-align: center;

  background: var(--background-color-light);
  background-size: 100%;
  background-position: 50% 0;

  border: dashed 2px var(--ion-color-dark-shade);
  border-radius: 10px;
  box-shadow: none;

}

ion-row {
  text-align: left;
  margin: 0 20px;
}

ion-label {
  margin: 20px 0;
  margin-right: 5px;
}

ion-segment {
  height: 50px;
}

.checkout-enabled {
  width: 120px;
}

.checkout-subtitle {
  width: calc(100% - 120px);
}
.checkout-item {
  width: 30%;
}
.checkout-stripe {
  width: 70%;
}
.checkout-price {
  width: 30%;
}
.currency {
  margin: 20px 0;
}


.loading {
  /* filter: grayscale(100%);*/
  min-height: 300px;
}


ion-card-header {
  padding-top: 0;
  max-height: 80px;
}

ion-card-subtitle {
  cursor: pointer;
  margin: 0;
  width: 30%;
  margin: 0 35%;
  margin-top: -2px;
}

.date-picker-modal .modal-wrapper {
  width: 380px;
  height: 480px;
  --width: 380px;
  --height: 480px;
}

.icon-picker {
  z-index: 10
}

textarea,
ion-input {
  --padding-start: 5px;
  --padding-end: 5px;
  z-index: 0;
}

ion-input input {
  padding: 0;
}

.title {
  font-size: 20px;
  font-weight: 500;
  width: 70%;
  margin: 0 15%;
  margin-top: -12px;
}

.detail ion-input {
  text-align: left;
  padding: 0;
  margin: 5px 25px;
  color: var(--ion-color-step-450);
  z-index: 1;
}

.detail textarea {
  text-align: left;
  display: block;
  margin: 5px 25px;
  width: calc(100% - 25px);
  padding: 0;
}

.description {
  padding: 5px 16px 1px 16px;
  margin: 0;
  line-height: 21px
}

.button-container {
  position: absolute;
  right: 0;
  top: 12px;
}

.button-container ion-icon { 
  height: 25px;
  width: 25px;
  margin: 0 5px;
  cursor: pointer;
}


.show_tickets,
.add_to_calendar {
  font-size: medium;
  cursor: pointer;
  text-decoration: underline
}

.add_to_calendar {
  font-size: small;
  margin: 2px 0;
  color: var(--ion-color-dark-tint)
}

.add_to_calendar ion-icon {
  height: 15px;
  color: var(--ion-color-dark-shade);
}

.purchased {
  margin-top: 10px
}
.purchased ion-button {
  margin: 5px 20px;
  width: 150px
}

p {
  opacity: 0.7;
}

.past-event {
  opacity: 0.7;
}

.no_reg-message {
  margin: auto 10px;
}





ion-title { 
  position: relative;
  text-align: left;
  padding-left: 50px;
  padding-inline: 25px
}

ion-text {
  text-align: left;
  display: block;
  margin: 5px 25px;
}



ion-accordion {
  background-color: transparent;
}

.dropdown {
  width: 190px;
  margin: auto;
  color: var(--ion-color-medium-shade);
  background-color: var(--ion-color-white-shade);
  border-radius: 20px;
}

.checkout-dropdown {
  color: var(--ion-color-medium-shade);
  background-color: transparent;
  border-radius: 20px;
}

ion-title:not(:first-child) {
  padding-top: 20px
}

/*
.wrapper {
  padding: 20px 0;
  padding-bottom: 50px;
  text-align: left;
}
*/

.dropdown-bottom {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.collapse {
  opacity: 0.7;
  font-weight: 300;
  position: absolute;
  top: 15px;
  left: 78px;
  transform: translateX(-50%);
}

.detail{
  padding: 0 10px;
}

.container {
  display: flex; 
}

ion-row {
  margin: 0;
}


@media (max-width: 780px) { 
  .dropdown {
    width: 220px;
  }
  .add_to_calendar {
    font-size: 10px
  }
  .add_to_calendar ion-icon {
    height: 13px;
  }

  ion-card-header {
    padding-bottom: 10px;
  }
  
  .description {
    padding: 0;
    padding-bottom: 5px
  }

  .container {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  ion-row {
    margin: 0;
  }

  .container {
    display: block; 
  }

  .checkout-item {
    width: 100%;
  }
  .checkout-stripe {
    width: 65%;
  }
  .checkout-price {
    width: 35%;
  }

  ion-card-content {
    line-height: 18px;
  }

  @media (max-width: 780px) {
      .checkout-stripe {
    width: 60%;
  }
  .checkout-price {
    width: 40%;
  }
  }
}


</style>
