<script lang="ts">
  import {
      IonContent,
      IonHeader,
      IonTitle,
      IonToolbar,
      IonButtons,
      IonButton,
      IonCardSubtitle,
      modalController,
  } from '@ionic/vue';
  import { closeOutline, copy, checkmark } from 'ionicons/icons';
  import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes, GettersTypes } from '../../../business';
  
  export default defineComponent({
      name: 'InviteGuestModal',
      components: { 
        IonContent, 
        IonHeader, 
        IonTitle, 
        IonToolbar, 
        IonButtons,
        IonCardSubtitle,
        IonButton,
      },
      props: {
        token: {
          type: String
        },
        remainingQuantity: {
          type: Number
        }
      },
      data() {
        return {
          coppied: false,
          quantity: 1
        }
      },
      computed: {
        ...mapGetters('tickets',{
          ticketToken: GettersTypes.ticketToken,
        }),
      },
      methods: {
        ...mapActions('tickets',{
          createTicketToken: ActionTypes.createTicketToken,
          removeTicketToken: ActionTypes.removeTicketToken,
        }),
        cancel() {
          this.removeTicketToken()
          return modalController.dismiss(null, 'cancel');
        },
        confirm() {
          this.removeTicketToken()
          return modalController.dismiss(this, 'confirm');
        },
        copyClipboard() {
          navigator.clipboard.writeText(this.getUrl());
          this.coppied = true
        },
        getUrl() {
          return process.env.VUE_APP_FE_URL + '/entrances/?share=' + this.ticketToken
        },
        incrementQuantity() {
          this.quantity++
        },
        decrementQuantity() {
          if (this.quantity > 1) {
            this.quantity--
          }
        }
      },
      setup() {
        return {
          closeOutline,
          copy,
          checkmark
        }
      },
      mounted() {
        if (this.remainingQuantity == 1) {
          this.createTicketToken({ticket_token: this.token, quantity: 1, auth: this.$cookies.get('access_token')})
        }
      }
  });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Share or resell entrances</ion-title>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel" size="large">
          <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  >
  <ion-content v-if="this.ticketToken || this.remainingQuantity == 1" class="ion-padding">
      <ion-text>Share this link:</ion-text>
      {{ ' ' }}
      <ion-text v-show="this.coppied" class="coppied">Coppied to clipboard!</ion-text>
      <ion-button class="url" @click="copyClipboard()">
        <ion-text>
          {{ this.ticketToken ? this.getUrl() : 'Loading ...' }}
        </ion-text>
        <ion-icon :icon="this.coppied ? checkmark : copy" :color="this.coppied ? 'success' : 'light'"></ion-icon>
      </ion-button>
      <ion-card-subtitle>By sharing this link, {{this.quantity}} {{ this.quantity == 1 ? 'entrance' : 'entrances' }} will be assigned to your guest.</ion-card-subtitle>
  </ion-content>
  <ion-content v-else class="ion-padding" style="text-align: center">
    You have {{ this.remainingQuantity }} entrances left.
    <br><br><br>
    Select quantity you want to share:
    <div style="margin: 20px; display: flex; justify-content: center;">
      <ion-button @click="decrementQuantity()">-</ion-button>
      <div style="margin-top: 10px; ">
        <ion-text style="margin: 10px; font-size: 21px;">{{ quantity }}</ion-text>
      </div>
      <ion-button @click="incrementQuantity()">+</ion-button>
    </div>
    <ion-button @click="createTicketToken({ticket_token: this.token, quantity: this.quantity, auth: this.$cookies.get('access_token')})">Generate share link</ion-button>
  </ion-content>
</template>

<style scoped>

  ion-item {
    color: var(--ion-color-light);
    
    --background: var(--ion-color-dark-shade);
    border-radius: 5px;
    cursor: pointer;
  }

  ion-button {
    --background: var(--ion-color-dark-shade); 
    --color: var(--ion-color-light);
  }
  
  .url {
    text-align: left;
    display: flex;
    padding: 0;
    border: none;
    --box-shadow: none;
    text-transform: none;
    cursor: pointer;
  }

  ion-text {
    text-align:left;
    width:100%;
    user-select: text;
    overflow: scroll;
    white-space: nowrap;
    margin-right: 15px;
  }

  .bold {
    font-weight: 400;
  }

  .coppied {
    font-size: 12px;
    font-weight: 500px;
    color: var(--ion-color-success-shade)
  }
  
</style>
