<script lang="ts">
import { IonCard, IonCardContent, IonCardTitle, IonCardSubtitle, IonRow, IonIcon, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { informationCircleOutline, logoWhatsapp, personAddOutline, lockClosed } from 'ionicons/icons';
import InviteGuestModal from './modal/InviteGuestModal.vue'
import QrcodeVue from 'qrcode.vue'

export default defineComponent({
  name: 'TicketCard',
  components: {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonRow,
    IonIcon,
    QrcodeVue
  },
  props: {
    ticket: {
        type: Object,
        default() {
            return {event_id: "01", startDate: "", endDate: ""}
        }
    },
    lock: {
      type: Boolean,
        default() {
            return false
        }
    }
  },
  computed: {
    backgroundImage() {
      const eventId = this.ticket.event_id;
      return `url(/assets/background/element_0${eventId}_bg.svg)`
       // dark mode
      // return `linear-gradient(0deg, var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff))) 120px, rgba(0,0,0,0.7) 100%), url(/assets/background/element_0${eventId}_bg.svg)`;
    },
    circleImage() {
      return process.env.VUE_APP_ASSETS_URL + '/image/bg_circle.svg'
    }
  },
  methods: {
    getOrdinalNum(n: number) {
      return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    },
    parseDate() {
      const formattedStartDate = new Date(this.ticket.startDate)
      const formattedEndDate = new Date(this.ticket.endDate)
      if (typeof formattedStartDate === 'object' && formattedStartDate !== null && 'toLocaleDateString' in formattedStartDate) {
        if (this.ticket.endDate) {
          const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
          const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
          const endMonth = formattedEndDate.toLocaleDateString("en-US", { month: 'short' })
          const endDay = this.getOrdinalNum(parseInt(formattedEndDate.toLocaleDateString("en-US", { day: 'numeric' })))
          const date = startMonth == endMonth ? startMonth + " " + startDay + " - " + endDay : startMonth + " " + startDay + " - " + endMonth + " " + endDay
          return date
        } else {
          const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
          const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
          const date = startMonth + " " + startDay
          return date
        }
      }
    },
    isPastEvent() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      const formattedStartDate = new Date(this.ticket.startDate)
      const formattedEndDate = this.ticket.endDate ? new Date(this.ticket.endDate) : undefined
      return formattedStartDate < tomorrow && (formattedEndDate ? formattedEndDate < tomorrow : true)
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    async openInviteGuestModal() {
      const modal = await modalController.create({
        component: InviteGuestModal,
        componentProps: {token: this.ticket.ticketToken, remainingQuantity: this.ticket.quantity}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      if (role === 'confirm') {
        this.$emit('submit')
      } else {
        this.loaderShown = false
      }
    },
  },
  setup() {
    return {
      informationCircleOutline,
      logoWhatsapp,
      personAddOutline,
      lockClosed,
    }
  }
});
</script>

<template>
  
  <ion-card :class="isPastEvent() ? 'past-event' : '' ">
    <!-- {{this.ticket}} -->
    <div class="container" ion-padding>

      <div class="grid_1 avatar" :style="{ backgroundImage: backgroundImage }">
          <img class="avatar-circle" :src="circleImage">
      </div>

      <div class="grid_2">
        <ion-card-title>{{ ticket.title }}</ion-card-title>
        <ion-card-subtitle>
          {{ parseDate() }}
        </ion-card-subtitle>
      </div>
      <div class="grid_3">
        <ion-card-content>
          <ion-row class="tools">
            <ion-button mode="md" :disabled="(ticket.link_info_pack && !isPastEvent()) ? false : true" @click="this.openLink(ticket.link_info_pack)">
              <div>
                <ion-icon :icon="informationCircleOutline"></ion-icon>
                <p v-html="$t('tickets.buttons.first')"></p>
              </div>
            </ion-button>
            <ion-button mode="md" :disabled="(ticket.link_whatsapp && !isPastEvent())? false : true" @click="this.openLink(ticket.link_whatsapp)">
              <div>
                <ion-icon :icon="logoWhatsapp"></ion-icon>
                <p v-html="$t('tickets.buttons.second')"></p>
              </div>
            </ion-button>
            <ion-button mode="md" :disabled="isPastEvent() || ticket.quantity == 0" @click="openInviteGuestModal()">
              <div>
                <ion-icon :icon="personAddOutline"></ion-icon>
                <p v-html="$t('tickets.buttons.third')"></p>
              </div>
            </ion-button>
          </ion-row>
        </ion-card-content>
      </div>
      <div class="grid_4">
          <div v-html="ticket.item"></div>
      </div>
      <div class="grid_5" :class="this.lock ? 'qr-lock-container' : ''">
        <div class="separator"></div>
        <div v-if="this.lock" class="qr-lock" @click="this.$router.push({path: '/your-details', })">
          <ion-icon :icon="lockClosed"></ion-icon>
          <p>UNLOCK</p>
        </div>
        <qrcode-vue :class="this.isPastEvent() ? 'qr-past' : (ticket.item?.match(/sold/i) ? 'qr qr-invalid' : 'qr')" :value="ticket.ticketToken" level='L'></qrcode-vue>
      </div>
      
    </div>
  </ion-card>
</template>

<style scoped>

ion-card {
  width: 95%;
  margin: 50px auto;
  padding: 20px;
  max-width: 720px;

  background-size: 100%;
  background-position: 50% 0;
  --background: var(--ion-color-white);

  box-shadow: 0px 6px 12px 0px var(--ion-color-light-shade);
  border-radius: 10px;
}

ion-button {
  --background: transparent; 
  --color: var(--ion-color-dark);
  height: 60px;
  width: 30%;
  display: flex;
  padding: 0;
  border: none;
  --box-shadow: none;
}

ion-icon {
  height: 40px;
  width: 40px;
  margin: 0 20px;
}

ion-card-content,
.card-content-ios {
-webkit-padding-start: 0px;
padding-inline-start: 0px;
-webkit-padding-end: 0px;
padding-inline-end: 0px;
}


p {
  font-size: 10px;
  height: 20px;
}



.past-event {
  opacity: 0.5;
}

.no_reg-message {
  margin: auto 10px;
}

.avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.avatar-circle {
  float: left;
  padding: 10%
}

.avat {
  float: left;
  position: absolute;
}

.separator {
  float: left;
  position: absolute;
  width: 0;
  height: 150px;
  border: solid var(--ion-color-light-shade) 1px
}

.qr {
  transform: translate(25%, 25%);
}


.qr-past {
  height: 100px !important;
  width: 100px !important;
  margin: 20px;
}

.qr-lock-container .qr-lock {
  position: absolute;
  height: 136px;
  width: 136px;
  margin: 2px;
  z-index: 10;
  backdrop-filter: blur(4px);
  cursor: pointer;
}

.qr-lock-container .qr {
  opacity: 0.5;
}

.qr-lock-container ion-icon {
  height: 100px;
  width: 100px;
  margin: 18px 20px;
  fill: var(--ion-color-dark);
  opacity: 0.8;
}

.qr-lock-container:hover ion-icon,
.qr-lock-container:active ion-icon {
  opacity: 0.85;
}

.qr-lock-container p {
  position: absolute;
  top: 30px;
  left: 48px;
  margin: 50px 0;
  text-align: center;
  color: var(--ion-color-dark-tint);
}


.container {
  display: grid; 
  grid-template-columns: 20% 58% 22%; 
  grid-template-rows: 25% 60% 10%; 
  gap: 0px 0px; 
  grid-template-areas: 
    "grid_1 grid_2 grid_5"
    "grid_1 grid_3 grid_5"
    "grid_1 grid_4 grid_5"; 
}
.grid_1 { grid-area: grid_1; }
.grid_2 { grid-area: grid_2; }
.grid_3 { grid-area: grid_3; }
.grid_4 { grid-area: grid_4; }
.grid_5 { grid-area: grid_5; }

  .qr-invalid {
    opacity: 0.2 !important;
  }

@media (max-width: 780px) { 
  p {
    white-space: break-spaces;
  }
  ion-card {
    padding: 20px 10px;
  }
  ion-card-title {
    font-size: 20px
  }
  .container {
    display: block
  }
  .separator {
    width: 80%;
    height: 0;
    margin: 0 10%;
    border: solid var(--ion-color-light-shade) 1px
  }
  .tools {
    justify-content: center;
  }
  ion-icon {
    height: 30px;
    width: 30px;
  }

  .container {
    display: grid; 
    grid-template-columns: 25% 70%; 
    grid-template-rows: auto ; 
    gap: 0 5%; 
    grid-template-areas: 
      "grid_1 grid_2"
      "grid_1 grid_4"
      "grid_3 grid_3"
      "grid_5 grid_5"; 
  }

  .grid_1 { grid-area: grid_1; }
  .grid_2 { grid-area: grid_2; }
  .grid_3 { grid-area: grid_3; }
  .grid_4 { grid-area: grid_4; }
  .grid_5 { grid-area: grid_5; text-align: center; }

  .past-event .container {
    display: grid; 
    grid-template-columns: 25% 45% 20%; 
    grid-template-rows: 70% 30%;
    gap: 0px 10px; 
    grid-template-areas: 
      "grid_1 grid_2 grid_5"
      "grid_1 grid_4 grid_5"; 
  }

  .past-event .separator {
    float: left;
    position: absolute;
    width: 0;
    height: 60px;
    margin: 5px 0;
    border: solid var(--ion-color-light-shade) 1px
  }

  .past-event .container .grid_3 {
    display: none;
  }

  .qr {
    height: 220px !important;
    width: 220px !important;
    margin: 40px;
    transform: none;
  }
  
  .qr-past {
    height: 60px !important;
    width: 60px !important;
    margin: 10px;
  }



  .qr-lock-container .qr-lock {
    height: 310px;
    width: 310px;
    backdrop-filter: blur(8px);
  }

  .qr-lock-container ion-icon {
    height: 220px;
    width: 220px;
    margin: 35px 20px;
    opacity: 0.85;
  }

  .qr-lock-container p { 
    margin: 140px 65px;
    font-size: 20px;
  }

  .qr-invalid {
    display: none;
  }

}

</style>