<script lang="ts">
import { IonItem, IonList, IonLabel, IonIcon, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { ellipsisVertical } from "ionicons/icons";
import { QrcodeStream } from 'vue3-qrcode-reader'
import AssignTicketModal from './modal/AssignTicketModal.vue'
import AssignTokenModal from './modal/AssignTokenModal.vue'

export default defineComponent({
name: 'ToolButton',
component: {
  IonItem, 
  IonList,
  IonLabel,
  IonIcon
},
props: {
  item: {
    type: Object
  },
  forceClose: {
    type: Boolean
  }
},
data() {
  return {
    open: false
  }
},
setup() {
  return {
    ellipsisVertical
  }
},
methods: {
  openTools() {
    if (!this.open) {
      setTimeout(() => {
        this.open = true
      }, 20);
    }
  },
  async openAssignTicketModal(email) {
      const modal = await modalController.create({
        component: AssignTicketModal,
        componentProps: {email: this.item.email}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
    },
  async openAssignTokenModal(email) {
      const modal = await modalController.create({
        component: AssignTokenModal,
        componentProps: {email: this.item.email}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
    },
},
watch: {
  forceClose() {
    if (this.open) {
      this.open = false
    }
  },
}

});
</script>

<template>
  <ion-icon :icon="ellipsisVertical" @click="this.openTools()"></ion-icon>
  <ion-list lines="none" v-show="this.open">
    <ion-item @click="this.openAssignTicketModal(item.email)">
      <ion-label v-html="$t('internal.assign_ticket')"></ion-label>
    </ion-item>
    <ion-item @click="this.openAssignTokenModal(item.email)">
      <ion-label v-html="$t('internal.assign_token')"></ion-label>
    </ion-item>
    <ion-item>
      <ion-label v-html="$t('internal.block_user')" color="danger"></ion-label>
    </ion-item>
  </ion-list>
</template>

<style scoped>

ion-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 5px 10px;
}

ion-icon:hover {
  color: var(--ion-color-dark-shade)
}

ion-list {
  position: absolute;
  z-index: 1;
  right: 65px;
  top: 5px;
  box-shadow: 0px 0px 5px 0px var(--ion-color-dark-tint);
  background: var(--ion-color-white);
  border-radius: 5px;
  padding: 5px;
}

ion-item {
  --background: var(--ion-color-white);
  cursor: pointer;
}

ion-item:hover {
  --background: var(--ion-color-light-shade)
}

</style>
