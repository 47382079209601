import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid_1" }
const _hoisted_2 = { class: "grid_2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.tokens, (token) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "wrapper",
      key: token
    }, [
      (token.ticketToken)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["token container", token.scanned ? 'scanned' : ''])
          }, [
            _createElementVNode("h1", _hoisted_1, _toDisplayString(token.quantity) + "x", 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(token.creationTime), 1),
            _createVNode(_component_qrcode_vue, {
              class: "grid_3 qr",
              value: token.ticketToken,
              level: "L"
            }, null, 8, ["value"])
          ], 2))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}