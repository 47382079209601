import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "container",
  "ion-padding": ""
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "grid_2" }
const _hoisted_4 = { class: "grid_3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "grid_1 avatar",
          style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage })
        }, [
          _createElementVNode("img", {
            class: "avatar-circle",
            src: _ctx.circleImage
          }, null, 8, _hoisted_2)
        ], 4),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ion_card_title, {
            class: _normalizeClass(_ctx.item.id > 3 ? 'smaller-title' : '')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.title), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_ion_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.subtitle), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.text), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}