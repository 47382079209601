import { MutationTypes } from './mutations'
import axios from 'axios'
import { useToast } from "vue-toastification";
import { Basket } from '../types';

export enum ActionTypes {
  getCheckout = 'GET_CHECKOUT',
  incrementBasket = 'INCREMENT_BASKET',
  clearBasket = 'CLEAR_BASKET',
  registerPayment = 'REGISTER_PAYMENT',
  loaderShown = 'LOADER_SHOWN',
  getTokens = 'GET_TOKENS',
  totalPrice = 'TOTAL_PRICE',
  applyDiscountCode = 'APPLY_DISCOUNT_CODE'
}


export const actions = {
  async [ActionTypes.getCheckout]({ commit }, pageId) {
    const path = process.env.VUE_APP_BASE_URL+'/checkout/'+pageId;
    await axios.get(path,).then((response) => {
      commit(MutationTypes.checkout, response.data)
    })
  },
  async [ActionTypes.incrementBasket]({ commit }, { itemId, quantity, price, priceId, basket }) {
    if (basket) {
      const index = basket.findIndex(item => item.itemId == itemId);
      if (index > -1) {
        basket[index].quantity = basket[index].quantity + quantity
      } else {
        basket.push({itemId: itemId, quantity: quantity, price: price, priceId: priceId})
      }
    } else {
      basket = [{itemId: itemId, quantity: quantity, price: price, priceId: priceId}]
    }
    commit(MutationTypes.incrementBasket, basket)
  },
  async [ActionTypes.clearBasket]({ commit }) {
    commit(MutationTypes.clearBasket)
  },
  async [ActionTypes.registerPayment]({ commit }, data) {
    const path = `${process.env.VUE_APP_BASE_URL}/register-payment/`;
    data['basket'] = data.basket.filter(item => item.quantity > 0);
    try {
      const response = await axios.post(path, {
        ...data,
        discountCode: data.discountCode // Include the discount code in the payment registration
      });
      commit(MutationTypes.setContact, {
        email: response.data.email,
        instagram: response.data.instagram
      });
    } catch (error : any) {
      if (error.response && error.response.status === 403) {
        useToast().error("You're not allowed to purchase this item!");
        commit(MutationTypes.setContact, { email: undefined });
      } else {
        useToast().error("Something went wrong! Refresh the window and try again.");
      }
      throw error;
    }
  },
  async [ActionTypes.loaderShown]({ commit }, shown) {
    commit(MutationTypes.loaderShown, shown)
  },
  async [ActionTypes.getTokens]({ commit }, auth) {
    const path = process.env.VUE_APP_BASE_URL+'/tokens/' + auth;
    axios.get(path).then((response) => {
      commit(MutationTypes.upadteTokens, response.data)
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      }
      commit(MutationTypes.upadteTokens, [])
    });
  },
  async [ActionTypes.totalPrice]({ commit }, basket: [Basket]) {
    if (basket) {
      let sum = 0
      for (let i = 0, len = basket.length; i < len; i++) {
        sum += basket[i].quantity.valueOf() * basket[i].price.valueOf()
      }
      commit(MutationTypes.totalPrice, sum)
    }
  },

  async [ActionTypes.applyDiscountCode]({ commit }, { token, code }) {
    const path = `${process.env.VUE_APP_BASE_URL}/apply-discount/`;
    try {
      const response = await axios.post(path, { code }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (response.data && response.data.valid) {
        commit(MutationTypes.setDiscount, response.data);
        useToast().success('Discount code applied!');
        return response.data;
      } else {
        useToast().error(response.data.message || 'Invalid discount code. Please try again.');
      }
      return null;
    } catch (error: any) {
      useToast().error(error.response.data.message || 'Failed to apply discount code. Please try again.');
      console.error('Error applying discount:', error);
      return null;
    }
  },



}
