<script lang="ts">
  import { defineComponent } from 'vue';
  import { UserEventState } from '@/modules/events/enums'
  
  export default defineComponent({
  name: 'StepProgressBar',
  // props: ['state'],
  props: {
    state: {
        type: String
    },
  },
  // data() {
  //   return {
  //     state: UserEventState.APPROVED
  //   }
  // },
  methods: {
    updateProgress() {
      const bar: any = this.$refs['bar']
      const s1: any = this.$refs['step-1']
      const s2: any = this.$refs['step-2']
      const s3: any = this.$refs['step-3']
      if (this.state == UserEventState.REGISTERED) {
        s1.classList.add('done')
        s2.classList.add('active')
        bar.classList.add('progress_50')
        bar.classList.add('pending_50')
      } else if (this.state == UserEventState.APPROVED || this.state == UserEventState.PRE_APPROVED ) {
        s1.classList.add('done')
        s2.classList.add('done')
        s3.classList.add('active')
        bar.classList.add('progress_50')
        bar.classList.add('pending_100')
      } else if (this.state == UserEventState.PURCHASED) {
        s1.classList.add('done')
        s2.classList.add('done')
        s3.classList.add('done')
        bar.classList.add('progress_100')
      }
    },
    updateState() {
      // this.state = UserEventState.PURCHASED;
    }
  },
  mounted() {
    this.updateProgress()
  },
  watch: {
    state() {
      this.updateProgress()
    }
  }
  });
</script>


<template>
  <div class="container">
    <div id="progress">
      <div id="progress-bar-bg"></div>
      <div ref="bar" id="progress-bar" class="pending"></div>
      <ul ref="ul" id="progress-num">
        <li ref="step-1" class="step"></li>
        <li ref="step-2" class="step"></li>
        <li ref="step-3" class="step"></li>
      </ul>

      
    </div>
    <ul ref="ul" id="progress-desc">
        <li ref="desc-1" v-html="$t('events.progress_bar.step_one')"></li>
        <li ref="desc-2" v-html="$t('events.progress_bar.step_two')"></li>
        <li ref="desc-3" v-html="$t('events.progress_bar.step_three')"></li>
      </ul>
    <!-- <button id="progress-next" class="btn" @click="this.updateState()">Next</button> -->
  </div>
</template>


<style scoped>
  .container {
    margin: 20px 0
  }
  #progress {
    position: relative;
    margin: auto;
    width: 50%;
  }
  #progress-bar {
    position: absolute;
    background: var(--ion-color-tertiary);
    background-image: url("https://app.cabanalibre.com/assets/background/element_02_bg.svg");
    background-position: center;
    background-size: 200%;
    height: 3px;
    top: calc(50% - 1.5px);
    left: 0;
    transition: 500ms
  }
    #progress-bar-bg {
    position: absolute;
    background: var(--ion-color-light-shade);
    height: 3px;
    top: calc(50% - 1.5px);
    left: 0;
    width: 100%;
    transition: 500ms
  }
  #progress-num {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  #progress-desc {
    margin: 5px auto;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 57%;
    left: 21.5%;
  }
  #progress-num::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 5px;
    width: 100%;
    z-index: -1;
  }
  #progress-num .step {
    border: 2px solid var(--ion-color-light-shade);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    line-height: 19px;
    text-align: center;
    background-color: #fff;
    font-size: 14px;  
    position: relative;
    z-index: 1;
    margin: 0;
  }
  #progress-num .step.active {
    border-color: var(--cl-color-first-dark);
    background-color: var(--ion-color-light);
    color: #fff;
  }
  #progress-num .step.done {
    border-color: transparent;
    background-image: url("https://app.cabanalibre.com/assets/background/element_02_bg.svg");
    background-position: center;
    background-size: 400%;
    color: var(--ion-color-light-contrast);
  }
  .btn {
      background: lightgray;    
      border: none;
      border-radius: 3px;
      padding: 6px 12px;   
  }

  .progress_50 {
    width: 50%;
  }

  .progress_100 {
    width: 100%;
  }

  @keyframes pending50 {
  from {
    width: 0;
  }

  to {
    width: 50%;
  }
}

  @keyframes pending100 {
  from {
    width: 50%;
  }

  to {
    width: 100%;
  }
}

.pending_50 {
  animation-duration: 5s;
  animation-name: pending50;
  animation-iteration-count: infinite;
}

.pending_100 {
  animation-duration: 5s;
  animation-name: pending100;
  animation-iteration-count: infinite;
}


@media (max-width: 780px) { 
  #progress {
    width: 80%;
  }
  #progress-desc {
    width: 95%;
  }
}
</style>