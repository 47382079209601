export enum ScanStatuses {
  SUCCESS = 'SUCCESS',
  SCANNED = 'SCANNED',
  INVALID = 'INVALID',
  NONE = 'NONE'
}

export enum Tables {
  REGISTRATIONS = 'registrations',
  USERS = 'users',
  PAYMENTS = 'payments',
  TICKETS = 'tickets',
  DISCOUNTS = 'discounts'
}