<script lang="ts">
import { IonCard, IonCardTitle, IonCardSubtitle, IonText } from '@ionic/vue';
import { defineComponent } from 'vue';
import { informationCircleOutline, logoWhatsapp, logoInstagram } from 'ionicons/icons';


export default defineComponent({
  name: 'CoreValues',
  components: {
    IonCard,
    
    IonCardTitle,
    IonCardSubtitle,
    IonText
  },
  props: {
    item: {
        type: Object,
        default() {
          return {id:1, title:"About Us"}
        }
    },
  },
  computed: {
    backgroundImage() {
      const id = this.item.id;
      return `url(/assets/background/element_0${id}_bg.svg)`
       // dark mode
      // return `linear-gradient(0deg, var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff))) 120px, rgba(0,0,0,0.7) 100%), url(/assets/background/element_0${eventId}_bg.svg)`;
    },
    circleImage() {
      return process.env.VUE_APP_ASSETS_URL + '/image/bg_circle.svg'
    }
  },
  setup() {
    return {
      informationCircleOutline,
      logoWhatsapp,
      logoInstagram,
    }
  }
});
</script>

<template>
  
  <ion-card >

    <div class="container" ion-padding>

      <div class="grid_1 avatar" :style="{ backgroundImage: backgroundImage }">
          <img class="avatar-circle" :src="circleImage">
      </div>

      <div class="grid_2">
        <ion-card-title :class="item.id > 3 ? 'smaller-title' : ''">{{ item.title }}</ion-card-title>
        <ion-card-subtitle>
          {{ item.subtitle }}
        </ion-card-subtitle>
      </div>
      <div class="grid_3">
        
          <ion-text>{{ item.text }}</ion-text>
        
      </div>
      
    </div>
  </ion-card>
</template>

<style scoped>

ion-card {
  width: 95%;
  margin: 50px auto;
  padding: 20px;
  max-width: 600px;

  background-size: 100%;
  background-position: 50% 0;

  box-shadow: none;
  border-radius: 10px;
}

ion-button {
  --background: transparent; 
  --color: var(--ion-color-dark);
  height: 60px;
  width: 30%;
  display: flex;
  padding: 0;
  border: none;
  --box-shadow: none;
}

ion-icon {
  height: 40px;
  width: 40px;
  margin: 0 20px;
}

ion-card-content,
.card-content-ios {
-webkit-padding-start: 0px;
padding-inline-start: 0px;
-webkit-padding-end: 0px;
padding-inline-end: 0px;
}


p {
  font-size: 10px;
  height: 20px;
}


.past-event {
  opacity: 0.5;
}

.no_reg-message {
  margin: auto 10px;
}

.avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.avatar-circle {
  float: left;
  padding: 10%
}

.avat {
  float: left;
  position: absolute;
}

.separator {
  float: left;
  position: absolute;
  width: 0;
  height: 150px;
  border: solid var(--ion-color-light-shade) 1px
}



.container {
  display: grid; 
  grid-template-columns: 15% 90%; 
  grid-template-rows: auto 90%; 
  gap: 10px 10px; 
  grid-template-areas: 
    "grid_1 grid_2"
    "grid_3 grid_3" 
}
.grid_1 { grid-area: grid_1; }
.grid_2 { grid-area: grid_2; margin-top: 10px}
.grid_3 { grid-area: grid_3; }
.grid_4 { grid-area: grid_4; }

@media (max-width: 780px) { 
  p {
    white-space: break-spaces;
  }

  ion-card-title {
    font-weight: 400;
    font-size: 22px !important; 
  }
  ion-card {
    padding: 8px;
  }
  .container {
    display: block
  }
  .separator {
    width: 80%;
    height: 0;
    margin: 0 10%;
    border: solid var(--ion-color-light-shade) 1px
  }
  .tools {
    justify-content: center;
  }
  ion-icon {
    height: 30px;
    width: 30px;
  }

  .container {
    display: grid; 
    grid-template-columns: 25% 70%; 
    grid-template-rows: auto ; 
    gap: 0 10px; 
    grid-template-areas: 
      "grid_1 grid_2"
      "grid_1 grid_4"
      "grid_3 grid_3"
      "grid_5 grid_5"; 
  }

  .grid_1 { grid-area: grid_1; }
  .grid_2 { grid-area: grid_2; }
  .grid_3 { grid-area: grid_3; margin: 20px; margin-top: 10px;}
  .grid_4 { grid-area: grid_4; }



}

</style>