<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ActionTypes, GettersTypes } from '../../business'
import QrcodeVue from 'qrcode.vue'

export default defineComponent({
  name: 'PurchasedTokens',
  components: {
    QrcodeVue
  },
  computed: {
    ...mapGetters('checkout',{
      tokens: GettersTypes.tokens
    }),
    ...mapGetters({
      auth: 'AUTH',
    }),
  },
  methods: {
    ...mapActions('checkout',{
      getTokens: ActionTypes.getTokens,
    }),
  },
  mounted() {
    this.getTokens(this.$cookies.get('access_token'))
    // setInterval(() => {
    //   if (this.$router.currentRoute.value.path == this.$t('toolbar.path_tokens')) {
    //     this.getTokens(this.$cookies.get('access_token'))
    //   }
    // }, 5000) 
  },
});
</script>

<template>
  <div class="wrapper" v-for="token in this.tokens" :key="token">
    <div v-if="token.ticketToken" class="token container" :class="token.scanned ? 'scanned' : ''">
      <h1 class="grid_1">{{ token.quantity }}x</h1>
      <p class="grid_2">{{ token.creationTime }}</p>
      <qrcode-vue class="grid_3 qr" :value="token.ticketToken" level='L'></qrcode-vue>
    </div>
  </div>
</template>

<style scoped>
.token {
  align-items: center;
  padding-bottom: 50px;
  max-width: 500px;
  margin: auto;
}

.scanned {
  opacity: 0.5
}


.container {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "grid_1 grid_2 grid_3";
}

.container * {
  margin: auto
}

.grid_1 { grid-area: grid_1; }

.grid_2 { grid-area: grid_2; }

.grid_3 { grid-area: grid_3; }

@media (max-width: 780px) { 
  .qr {
    height: 150px !important;
    width: 150px !important;
  }
  .container {  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 50px 20px;
  gap: 0px 15px;
  grid-auto-flow: row;
  grid-template-areas: 
    "grid_3"
    "grid_1"
    "grid_2"; 
}


.grid_1 { grid-area: grid_1; }

.grid_3 { grid-area: grid_3; }

.grid_2 { grid-area: grid_2; }

}
</style>
