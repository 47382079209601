import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  readonly: "",
  class: "wrapper",
  slot: "content"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 5 }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "no_reg-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_step_progress_bar = _resolveComponent("step-progress-bar")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_footer = _resolveComponent("ion-card-footer")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: _normalizeClass(this.showSkeleton ? 'loading' : (_ctx.isPastEvent() ? 'past-event' : '') ),
    style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          (this.showSkeleton)
            ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                key: 0,
                animated: "",
                style: {"width":"30%","min-width":"200px","height":"25px"}
              }))
            : (_openBlock(), _createBlock(_component_ion_card_title, { key: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.event.title), 1)
                ]),
                _: 1
              })),
          (this.showSkeleton)
            ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                key: 2,
                animated: "",
                style: {"width":"10%","min-width":"70px","height":"20px","opacity":"0.5"}
              }))
            : (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                key: 3,
                style: {"color":"var(--ion-color-dark)","opacity":"0.7"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.parseDate(this.event.startDate, this.event.endDate)), 1)
                ]),
                _: 1
              })),
          _withDirectives(_createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_icon, {
              icon: _ctx.pencil,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('edit')))
            }, null, 8, ["icon"])
          ], 512), [
            [_vShow, _ctx.isInternalRole() && !this.showSkeleton]
          ])
        ]),
        _: 1
      }),
      (this.showSkeleton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (this.showSkeleton)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 0,
                  animated: "",
                  style: {"width":"70%","min-width":"250px"}
                }))
              : _createCommentVNode("", true),
            (this.showSkeleton)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 1,
                  animated: "",
                  style: {"width":"60%","min-width":"150px"}
                }))
              : _createCommentVNode("", true),
            (this.showSkeleton)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 2,
                  animated: "",
                  style: {"width":"30%","min-width":"50px"}
                }))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createBlock(_component_ion_card_content, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.event.description), 1)
            ]),
            _: 1
          })),
      (this.showSkeleton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
            (this.showSkeleton)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 0,
                  animated: "",
                  style: {"width":"50%","min-width":"200px","height":"40px","margin-top":"15px","opacity":"0.5"}
                }))
              : _createCommentVNode("", true),
            (this.showSkeleton)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 1,
                  animated: "",
                  style: {"width":"20%","min-width":"100px","height":"40px","margin-top":"35px"}
                }))
              : _createCommentVNode("", true)
          ]))
        : _withDirectives((_openBlock(), _createBlock(_component_ion_card_footer, { key: 3 }, {
            default: _withCtx(() => [
              (_ctx.event.details.length)
                ? (_openBlock(), _createBlock(_component_ion_accordion_group, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_accordion, { value: "first" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            class: "dropdown",
                            slot: "header",
                            color: "white",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (this.triggerCollapse()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, {
                                innerHTML: _ctx.$t('events.more_details')
                              }, null, 8, ["innerHTML"])
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_4, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.event.details, (detail) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "detail",
                                key: detail
                              }, [
                                _createVNode(_component_ion_title, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.getIcon(detail.icon),
                                      size: "medium"
                                    }, null, 8, ["icon"]),
                                    _createTextVNode(_toDisplayString(detail.title), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_text, {
                                  innerHTML: detail.description
                                }, null, 8, ["innerHTML"])
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (this.event.state != _ctx.UserEventState.PURCHASED && this.event.state != _ctx.UserEventState.NOT_REGISTERED && this.event.state != _ctx.UserEventState.PRE_APPROVED)
                ? (_openBlock(), _createBlock(_component_step_progress_bar, {
                    key: 1,
                    state: this.event.state
                  }, null, 8, ["state"]))
                : _createCommentVNode("", true),
              (this.event.state == _ctx.UserEventState.NOT_REGISTERED && this.event.type != _ctx.EventState.SOLD_OUT)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 2,
                    innerHTML: _ctx.$t('events.message.not_registered')
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true),
              (this.event.state == _ctx.UserEventState.PURCHASED)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 3,
                    color: "light",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ path: _ctx.$t('toolbar.path_tickets') })))
                  }, _toDisplayString(_ctx.$t('events.message.purchased')), 1))
                : _createCommentVNode("", true),
              (this.event.state == _ctx.UserEventState.PRE_APPROVED  && this.event.type != _ctx.EventState.SOLD_OUT)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 4,
                    innerHTML: _ctx.$t('events.message.pre_approved')
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("br", _hoisted_7)),
              (this.event.type == _ctx.EventState.SOLD_OUT)
                ? (_openBlock(), _createBlock(_component_button_loading, {
                    key: 6,
                    isLoading: this.loaderShown,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (this.register())),
                    text: 'Sold Out',
                    color: 'danger',
                    disabled: ""
                  }, null, 8, ["isLoading"]))
                : (this.event.state == _ctx.UserEventState.NOT_REGISTERED)
                  ? (_openBlock(), _createBlock(_component_button_loading, {
                      key: 7,
                      isLoading: this.loaderShown,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (this.register())),
                      text: 'Register'
                    }, null, 8, ["isLoading"]))
                  : (this.event.state == _ctx.UserEventState.REGISTERED)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 8,
                        innerHTML: _ctx.$t('events.message.registartion_pending')
                      }, null, 8, _hoisted_8))
                    : ([_ctx.UserEventState.APPROVED, _ctx.UserEventState.PRE_APPROVED].includes(this.event.state) && !this.isAuthorized)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 9,
                          size: "medium",
                          color: "medium",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (this.openRegistrationModal())),
                          innerHTML: _ctx.$t('events.message.purchase')
                        }, null, 8, ["innerHTML"]))
                      : (this.event.state == _ctx.UserEventState.PURCHASED)
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 10,
                            size: "small",
                            color: "medium",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({ path: _ctx.$t('toolbar.path_checkout'), query:{'id': this.event.pageId}}))),
                            innerHTML: _ctx.$t('events.message.purchase_more')
                          }, null, 8, ["innerHTML"]))
                        : (this.isAuthorized)
                          ? (_openBlock(), _createBlock(_component_ion_button, {
                              key: 11,
                              size: "medium",
                              color: "medium",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push({ path: _ctx.$t('toolbar.path_checkout'), query:{'id': this.event.pageId}}))),
                              innerHTML: _ctx.$t('events.message.purchase')
                            }, null, 8, ["innerHTML"]))
                          : _createCommentVNode("", true)
            ]),
            _: 1
          }, 512)), [
            [_vShow, !_ctx.isPastEvent() && _ctx.isLiveEvent()]
          ]),
      _withDirectives(_createVNode(_component_ion_card_footer, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_9, [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.informationCircleOutline,
                  size: "small"
                }, null, 8, ["icon"]),
                _createTextVNode(_toDisplayString(_ctx.$t('events.message.no_reg')), 1)
              ])
            ]),
            _: 1
          }, 512), [
            [_vShow, !_ctx.isPastEvent()]
          ])
        ]),
        _: 1
      }, 512), [
        [_vShow, !_ctx.isPastEvent() && !_ctx.isLiveEvent() && !this.showSkeleton && this.event.type != _ctx.EventState.SOLD_OUT]
      ])
    ]),
    _: 1
  }, 8, ["class", "style"]))
}