<script lang="ts">
import { IonPage, IonItem, IonLabel, IonInput, IonLoading, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { ActionTypes as EventActionTypes } from '@/modules/events/business'
import { Site } from '../../enums'
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import UserExistsModal from '../components/modal/UserExistsModal.vue'
import TermsOfUseModal from '../components/modal/TermsOfUseModal.vue'


export default defineComponent({
name: 'SignUpPage',
  components: {
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonLoading,
    ButtonLoading
  },
  data() {
    return {
      loaderShown: false,
      submitted: false,
      nameDanger: "",
      surnameDanger: "",
      instagramDanger: "",
      emailDanger: "",
      passwordDanger: "",
      passwordDangerMessage: "",
      checkboxDanger: false,
      buttonDanger: "light",
      name: "",
      surname: "",
      instagram: this.$route.query.instagram ? this.$route.query.instagram as string : "",
      email: this.$route.query.email ? this.$route.query.email as string : "",
      password: "",
      noInstagram: false,
      checkbox: false,
      showPassword: false,
      Site
    }
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized,
      userExists: GettersTypes.userExists,
    }),
  },
  methods: {
    ...mapActions({
      signUp: ActionTypes.signUp,
      checkEmail: ActionTypes.checkEmail,
      confirmPayment: 'CONFIRM_PAYMENT',
    }),
    ...mapActions('events',{
      registerToEvent: EventActionTypes.registerToEvent
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        const parsedInstagram = this.instagram[0] == "@" ? this.instagram.substring(1) : this.instagram
        const instagram = this.noInstagram ? parsedInstagram : "@" + parsedInstagram
        await this.signUp({ token: this.$cookies.get('access_token'), email: this.email, instagram: instagram, password: this.password})
        this.loaderShown = false
        if (!this.userExists) {
          this.$emit('signedUp')
          if (this.$route.query.register) {
            await this.registerToEvent({eventId: this.$route.query.register, token: this.auth.token})
          } 
          this.$router.push({ path: this.$t('toolbar.path_events') })
          this.redirect()
        }
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validateInput() {
      if (this.submitted) {
        this.emailDanger = this.validateEmail(this.email) ? '' : 'danger'
        this.instagramDanger = this.instagram ? '' : 'danger'
        this.passwordDanger = this.password.length >= 8 ? '' : 'danger'
        this.passwordDangerMessage = this.password ? '' : 'danger'
        this.checkboxDanger = !this.checkbox
        this.buttonDanger = (this.instagram && this.password && this.email && this.checkbox)  ? 'light' : 'danger'
        return (this.instagram && this.password && this.email && this.checkbox)
      }
    },
    checkExistingEmail() {
      if (this.email) {
        this.checkEmail(this.email)
      }
    },
    async openUserExistsModal() {
      const modal = await modalController.create({
        component: UserExistsModal,
        componentProps: {prefilledEmail: this.email, site: this.userExists}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
    async openTermsOfUseModal() {
      const modal = await modalController.create({
        component: TermsOfUseModal,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
    redirect() {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect.includes('share=')) {
          this.$router.push({path: this.$route.query.redirect, query: {share: this.$route.query.redirect.split('share=')[1]}})
        } else {
          this.$router.push({path: this.$route.query.redirect})
        }
      }
    }
  },
  watch: {
    userExists() {
      if (this.userExists) {
        this.openUserExistsModal()
      }
    }
  },
  mounted() {
    const paymentId = this.$route.query.paymentId as string
    if (paymentId) {
      this.confirmPayment(paymentId)
    }
  },
  setup() {
    return {
      eyeOutline,
      eyeOffOutline
    }
  }
});
</script>


<template>
  <ion-page>
    <ion-content>
        <ion-loading
          :is-open="this.loaderShown"
          message=""
        ></ion-loading>
      <ion-card v-show="!this.isAuthorized">
        <ion-header class="ion-no-border">
          <ion-title v-html="$t('auth.signup.title')"></ion-title>
          <br>
          <ion-subtitle v-html="$t('auth.signup.subtitle')"></ion-subtitle>
        </ion-header>

        <div class="container">

          <div class="grid_1">
          <ion-text class="subtitle" v-html="$t('auth.signup.credentials')"></ion-text>
           <ion-item>
              <ion-label position="floating" :color="this.emailDanger">
                {{ $t('auth.email') }}
                
                <ion-text class="danger-message" v-show="this.emailDanger" color="danger" v-html="$t('auth.signup.email_validation')"></ion-text>
                <ion-text class="danger-message" v-show="this.userExists" color="danger" v-html="$t('auth.signup.email_exists')"></ion-text>
              </ion-label>
              <ion-input v-model="email" @change="validateInput(); checkExistingEmail()" @keyup.enter="submit()" type="email"></ion-input>
            </ion-item>
          </div>

          <div class="grid_2">
            <ion-item>
              <ion-label position="floating" :color="this.instagramDanger">{{ this.noInstagram ? $t('auth.referral') :  $t('auth.instagram') }}</ion-label>
              <ion-input class="instagram_input" v-model="instagram" @change="validateInput()" @keyup.enter="submit()"></ion-input>
              <ion-button :color="this.noInstagram ? 'primary' : 'danger'" class="no_instagram" @click="this.noInstagram = !this.noInstagram">{{ this.noInstagram ?  $t('auth.short_instagram') : $t('auth.short_no_instagram') }}</ion-button>
            </ion-item>
          </div>

          <div class="grid_3">
           <ion-item>
              <ion-label position="floating" :color="this.passwordDanger"> 
                {{ $t('auth.password') }}
                <ion-text class="danger-message" v-if="this.passwordDanger" color="danger" v-html="$t('auth.signup.password_validation')"></ion-text>
              </ion-label>
              <ion-input v-model="password" @change="validateInput()" @keyup.enter="submit()" :type=" this.showPassword ? 'text' : 'password'"></ion-input>
              <ion-button class="show_password" slot="end" @click="this.showPassword = !this.showPassword">
                  <ion-icon :icon="this.showPassword ? eyeOffOutline : eyeOutline" size="small"></ion-icon>
              </ion-button>
            </ion-item>
          </div>

          <div class="grid_4">
            <ion-item class="checkbox" lines="none">
              <ion-checkbox mode="md" :class="this.checkboxDanger ? 'checkbox-danger' : ''" @click="this.checkbox = !this.checkbox; this.submitted ? this.validateInput() : '';" position="start"></ion-checkbox>
              <ion-label position="fixed" :color="this.checkboxDanger ? 'danger' : ''" class="checkbox-label" @click="this.openTermsOfUseModal()"> 
                {{ $t('auth.terms') }}
              </ion-label>
            </ion-item>
            
          </div>
        </div>
        <div class="button">
          <button-loading :color="this.buttonDanger" @click="this.submit()" :isLoading="this.loaderShown" :text="$t('auth.signup.button_submit')"></button-loading>
        </div>
      </ion-card>
      
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-card {
  margin: 0px auto;
  max-height: 720px;
  max-width: 780px;
  width: 400px;
  padding: 50px;
  padding-top: 30px;
  --box-shadow: none;
  background: var(--ion-color-white)
}

ion-item {
  --background: var(--ion-color-white);
  --inner-padding-end: 0px;
}

ion-header {
  margin-top: 20px;
  margin-bottom: 30px;
}

ion-title {
  color: var(--ion-color-dark);
  position: relative;
  padding: 0 0;
  text-align: left;
  font-size: 18px;
}

ion-label {
  min-width: 200px !important;
  z-index: 10;
}

.subtitle {
  color: var(--ion-color-dark-tint);
}


.button {
  text-align: center
}

.danger-message {
  font-style: italic;
}

.edit {
  position: absolute;
  top: 20px;
  right: 40px;
  height: 40px;
  width: 40px;
  z-index: 10;
  --padding-start: 0;
  --padding-end: 0;
}

.edit ion-icon {
  margin-left: 2px;
  min-width: 20px;
}

.confirm {
  justify-content: right;
  margin-top: 50px;
}

.confirm ion-button {
  margin: 0 10px;
}

.delete_account {
  --background: transparent; 
  --color: var(--ion-color-dark);
  padding: 0;
  margin-top: 20px;
  border: none;
  --box-shadow: none;
}

.no_instagram {
    opacity:0;
    position: absolute;
    margin: 20px;
    right: 0;
    z-index: 2;
}

ion-input:focus + .no_instagram {
    opacity:1;
}

.instagram_input.has-focus + .no_instagram {
   opacity:1;
}

.instagram_input {
  transition: 1000;
}

.checkbox {
  margin-top: 10px;
  border: none;
}

.checkbox-label {
  margin-left: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.checkbox-danger::part(container) {
  border: 2px solid var(--ion-color-danger);
}

.container {  
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70px 50px 50px 50px;
  gap: 10px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    "grid_1"
    "grid_2"
    "grid_3"
    "grid_4";
  margin-bottom: 50px
}

.grid_1 { grid-area: grid_1; }

.grid_2 { grid-area: grid_2; }

.grid_3 { grid-area: grid_3; }

.grid_4 { grid-area: grid_4; }


.show_password {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  --background: none;
  --box-shadow: none;
  --color: var(--ion-color-dark-shade);
  z-index: 10;
}


@media (max-width: 780px) { 
  ion-card {
    margin-top: 50px;
    height: 630px;
    width: 90%;
    padding: 40px 30px;
  }

  ion-button {
    float: none
  }

  ion-item {
    width: 95%;
  }

  .danger-message {
    font-size: 12px;
  }
  .container {  
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 0.7fr 0.5fr 0.5fr 0.5fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "grid_1"
      "grid_2"
      "grid_3"
      "grid_4";
  }

}
</style>
