<script lang="ts">
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem
} from '@ionic/vue';
import { searchOutline, closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex'
import { Tables } from '@/modules/internal/enums'

export default defineComponent({
  name: 'InternalNavigation',
  components: {
    IonButton,
    IonIcon,
    IonInput,
    IonItem
  },
    props: {
      isAuthorized: {
        type: Boolean
      },
      forceClose: {
        type: Boolean
      }
    },
    data() {
      return {
        isMenuOpen: false,
        activeMenuColor: 'primary',
        activated: false,
        search: "",
        expandSearchPhone: false,
        Tables
      }
    },
    setup() {
      return {
        searchOutline,
        closeOutline
      }
    },
    computed: {
      ...mapGetters({
        isAuthorized: 'IS_AUTHORIZED',
        auth: 'AUTH'
      }),
      ...mapGetters('internal',{
        table: 'TABLE'
      })
    },
    methods: {
      ...mapActions('internal',{
        setSearch: 'SEARCH',
        selectTable: 'SELECT_TABLE',
      }),
      click() {
        this.isMenuOpen = !this.isMenuOpen
        this.$emit('fabClick')
      },
      isCurrentPath(path) {
        return path == this.$router.currentRoute.value.path 
      },
      navigateTable(table) {
        this.$router.push({query: {name: table}})
        this.selectTable(table)
      },
      clearSearch() {
        this.search = "",
        this.expandSearchPhone = false
      }
    },
    watch: {
      search() {
        this.setSearch(this.search)
      },
    }
  });
</script>

<template>
  <ion-item v-if="isCurrentPath($t('toolbar.path_admin'))" class="nav-internal" lines="none">
    <ion-button size="small" :color="(this.$route.query.name == Tables.REGISTRATIONS || !this.$route.query.name) ? 'medium' : 'light'" @click="this.navigateTable(Tables.REGISTRATIONS)">REGISTRATIONS</ion-button>
    <ion-button size="small" :color="this.$route.query.name == Tables.USERS ? 'medium' : 'light'" @click="this.navigateTable(Tables.USERS)">USERS</ion-button>
    <ion-button size="small" :color="this.$route.query.name == Tables.PAYMENTS ? 'medium' : 'light'" @click="this.navigateTable(Tables.PAYMENTS)">PAYMENTS</ion-button>
    <ion-button size="small" :color="this.$route.query.name == Tables.TICKETS ? 'medium' : 'light'" @click="this.navigateTable(Tables.TICKETS)">TICKETS</ion-button>
    <ion-button size="small" :color="this.$route.query.name == Tables.DISCOUNTS ? 'medium' : 'light'" @click="this.navigateTable(Tables.DISCOUNTS)">DISCOUNTS</ion-button>
  </ion-item>

  <ion-item class="search" :class="this.expandSearchPhone ? 'expand-search' : ''" lines="none">
    <ion-input v-model="this.search" placeholder="Search"></ion-input>
    <ion-icon 
      :icon="this.search ? closeOutline : searchOutline" slot="start" 
      :color="this.search ? 'danger' : 'medium'"
      :class="this.search ? 'clear-search' : ''"
      @click="this.search ? this.clearSearch() : this.expandSearchPhone = !this.expandSearchPhone"
    ></ion-icon>
  </ion-item>
</template>

<style scoped>
    .search {
      position: fixed;
      right: 15px;
      top: 15px;
      width: 200px;
      height: 45px;
      color: var(--ion-color-dark);
      border-bottom: solid 1px var(--ion-color-dark-tint);
      z-index: 100;
    }

    .search ion-icon {
      margin-inline-end: 10px;
    }

    .clear-search {
      cursor: pointer;
      color: var(--ion-color-danger);
    }

    .nav-internal {
      position: absolute;
      left: 60px;
      margin-top: 5px;
      z-index: 0;
    }

    .nav-internal ion-button {
      margin: 0 5px;
    }

    @media (max-width: 780px) { 
      .nav-internal {
      width: 330px; /* Increased width to accommodate the new button */
      overflow: scroll;
    }

      .search {
        transition: 0.2s;
        width: 50px;
        border-bottom: solid 1px var(--ion-background-color);
        box-shadow: 0px 0px 10px 10px var(--ion-background-color);
      }

      .expand-search {
        width: 200px;
        border-bottom: solid 1px var(--ion-color-dark-tint);
      }

      .search ion-icon {
        margin-inline-end: 0px;
        transition: 0.2s;
      }

      .expand-search ion-icon {
        margin-inline-end: 10px;
      }
    }

  
  </style>
  