import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"display":"flex","width":"100%","align-items":"center","justify-content":"center"} }
const _hoisted_2 = { style: {"width":"100px"} }
const _hoisted_3 = {
  readonly: "",
  class: "wrapper",
  slot: "content"
}
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = {
  readonly: "",
  class: "wrapper",
  slot: "content"
}
const _hoisted_6 = {
  readonly: "",
  class: "wrapper",
  slot: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_icon_picker = _resolveComponent("icon-picker")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_card_footer = _resolveComponent("ion-card-footer")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.isInternalRole())
    ? (_openBlock(), _createBlock(_component_ion_card, {
        key: 0,
        style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage}),
        onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.closeTools()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, { style: {"justify-content":"center"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                modelValue: this.newEvent.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.newEvent.title) = $event)),
                class: "title",
                color: this.titleDanger ? 'danger' : 'dark',
                placeholder: "Title"
              }, null, 8, ["modelValue", "color"]),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", null, [
                  _createVNode(_component_ion_card_subtitle, {
                    style: {"width":"100px","margin":"0"},
                    modelValue: this.newEvent.startDate,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.newEvent.startDate) = $event)),
                    color: this.dateDanger ? 'danger' : 'dark',
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openStartDatePickerModal()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.newEvent.startDate ? (new Date(this.newEvent.startDate).toLocaleDateString("en-US", { month: 'short' }) + " " + this.getOrdinalNum(new Date(this.newEvent.startDate).toLocaleDateString("en-US", { day: 'numeric' }))) : 'Start Date'), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "color"])
                ]),
                _cache[15] || (_cache[15] = _createTextVNode(" - ")),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_card_subtitle, {
                    style: {"width":"100%","margin":"0"},
                    modelValue: this.newEvent.endDate,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.newEvent.endDate) = $event)),
                    color: this.dateDanger ? 'danger' : 'dark',
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openEndDatePickerModal()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.newEvent.endDate ? (new Date(this.newEvent.endDate).toLocaleDateString("en-US", { month: 'short' }) + " " + this.getOrdinalNum(new Date(this.newEvent.endDate).toLocaleDateString("en-US", { day: 'numeric' }))) : 'End Date'), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "color"])
                ])
              ])
            ]),
            _: 1
          }),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.newEvent.description) = $event)),
            ref: "textareaDescription",
            placeholder: "Description"
          }, null, 512), [
            [_vModelText, this.newEvent.description]
          ]),
          _createVNode(_component_ion_card_footer, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_accordion_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_accordion, { value: "first" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        class: "dropdown",
                        slot: "header",
                        color: "white"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            innerHTML: _ctx.$t('events.more_details')
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newEvent.details, (item, i) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "detail",
                            key: item,
                            ref_for: true,
                            ref: "textareaDetailsDescription"
                          }, [
                            _createVNode(_component_icon_picker, {
                              class: "icon-picker",
                              forceClose: this.forceCloseTools,
                              setIcon: _ctx.newEvent.details[i].icon,
                              index: i,
                              onIcon: this.pickIcon
                            }, null, 8, ["forceClose", "setIcon", "index", "onIcon"]),
                            _createVNode(_component_ion_input, {
                              modelValue: _ctx.newEvent.details[i].title,
                              "onUpdate:modelValue": ($event: any) => ((_ctx.newEvent.details[i].title) = $event),
                              class: "title",
                              placeholder: "Title"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _withDirectives(_createElementVNode("textarea", {
                              "onUpdate:modelValue": ($event: any) => ((_ctx.newEvent.details[i].description) = $event),
                              placeholder: "Description"
                            }, null, 8, _hoisted_4), [
                              [_vModelText, _ctx.newEvent.details[i].description]
                            ]),
                            _createTextVNode(" " + _toDisplayString(this.adjustDetailsTextareaHeight('textareaDetailsDescription')), 1)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_accordion, { value: "first" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    class: "checkout-dropdown",
                    slot: "header",
                    color: "white"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode("Settings")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ion_segment, {
                      modelValue: this.newEvent.type,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.newEvent.type) = $event)),
                      mode: "ios"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_segment_button, {
                          value: this.EventState.PLAN_NOREG
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                _createTextVNode("Planned")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _createVNode(_component_ion_segment_button, {
                          value: _ctx.EventState.PLAN
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                _createTextVNode("Registrations")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _createVNode(_component_ion_segment_button, {
                          value: _ctx.EventState.LIVE
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[19] || (_cache[19] = [
                                _createTextVNode("Payments")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _createVNode(_component_ion_segment_button, {
                          value: _ctx.EventState.SOLD_OUT
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[20] || (_cache[20] = [
                                _createTextVNode("Sold Out")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                            _createTextVNode("Info Pack")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          modelValue: this.newEvent.linkInfoPack,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.newEvent.linkInfoPack) = $event)),
                          placeholder: "https://bit.ly/"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[22] || (_cache[22] = [
                            _createTextVNode("WhatsApp")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          modelValue: this.newEvent.linkWhatsApp,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.newEvent.linkWhatsApp) = $event)),
                          placeholder: "https://chat.whatsapp.com/"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_accordion, { value: "first" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    class: "checkout-dropdown",
                    slot: "header",
                    color: "white"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => _cache[23] || (_cache[23] = [
                          _createTextVNode("Checkout page")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { class: "checkout-subtitle" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                _createTextVNode("Subtitle")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              modelValue: _ctx.newEvent.checkout.subtitle,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newEvent.checkout.subtitle) = $event)),
                              placeholder: "Early birds"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { class: "checkout-enabled" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                _createTextVNode("Disabled")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_checkbox, {
                              type: "md",
                              modelValue: this.newEvent.checkout.disabled,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.newEvent.checkout.disabled) = $event)),
                              checked: this.newEvent.checkout.disabled
                            }, null, 8, ["modelValue", "checked"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.newEvent.checkout.items, (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "container",
                        key: item
                      }, [
                        _createVNode(_component_ion_item, { class: "checkout-item" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                _createTextVNode("Item")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              modelValue: _ctx.newEvent.checkout.items[i].item,
                              "onUpdate:modelValue": ($event: any) => ((_ctx.newEvent.checkout.items[i].item) = $event),
                              placeholder: "Entrance"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, { class: "checkout-stripe" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                                    _createTextVNode("Stripe")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_ion_input, {
                                  modelValue: _ctx.newEvent.checkout.items[i].priceId,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.newEvent.checkout.items[i].priceId) = $event),
                                  placeholder: "price_id"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_item, { class: "checkout-price" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                                    _createTextVNode("Price")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_ion_input, {
                                  modelValue: _ctx.newEvent.checkout.items[i].price,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.newEvent.checkout.items[i].price) = $event),
                                  placeholder: "20",
                                  type: "number"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_ion_label, {
                                  class: "currency",
                                  slot: "end"
                                }, {
                                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                                    _createTextVNode("€")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "submit" }, {
            default: _withCtx(() => [
              _createVNode(_component_button_loading, {
                class: "cancel",
                color: "medium",
                size: "small",
                text: "Cancel",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (this.$emit('close')))
              }),
              _createVNode(_component_button_loading, {
                class: "confirm",
                color: "success",
                size: "small",
                text: "Submit",
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.confirmEdit())),
                isLoading: this.isLoadingConfirm
              }, null, 8, ["isLoading"])
            ]),
            _: 1
          }),
          (this.event.eventId)
            ? (_openBlock(), _createBlock(_component_ion_row, {
                key: 0,
                class: "delete"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_button_loading, {
                    class: "remove",
                    color: "danger",
                    size: "small",
                    text: "Remove",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.hideEvent())),
                    isLoading: this.isLoadingDelete
                  }, null, 8, ["isLoading"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["style"]))
    : _createCommentVNode("", true)
}