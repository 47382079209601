import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_icon, {
      class: "menu-icon",
      icon: _ctx.getIcon(this.toolIcon),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (this.openTools()))
    }, null, 8, ["icon"]),
    _withDirectives(_createVNode(_component_ion_list, {
      class: "container",
      lines: "none"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.icons, (icon, index) => {
          return (_openBlock(), _createBlock(_component_ion_item, {
            class: _normalizeClass('grid_'+index),
            key: icon,
            lines: "none",
            onClick: ($event: any) => (this.pickIcon(icon))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                ref_for: true,
                ref: "icon",
                icon: icon
              }, null, 8, ["icon"])
            ]),
            _: 2
          }, 1032, ["class", "onClick"]))
        }), 128))
      ]),
      _: 1
    }, 512), [
      [_vShow, this.open]
    ])
  ], 64))
}