<script lang="ts">
import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/vue';
import { ellipsisVertical, trashOutline } from "ionicons/icons";
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { ActionTypes } from '../../business';

export default defineComponent({
name: 'ToolButton',
component: {
  IonItem, 
  IonList,
  IonLabel,
  IonIcon
},
props: {
  item: {
    type: Object
  },
  forceClose: {
    type: Boolean
  }
},
data() {
  return {
    open: false
  }
},
setup() {
  return {
    ellipsisVertical,
    trashOutline
  }
},
methods: {
  ...mapActions('internal',{
    removeTickets: ActionTypes.removeTickets,
    getTickets: ActionTypes.getTickets
  }),
  openTools() {
    if (!this.open) {
      setTimeout(() => {
        this.open = true
      }, 20);
    }
  },
  async confirm(item) {
    await this.removeTickets({token: this.$cookies.get('access_token'), data: item})
    await this.getTickets(this.$cookies.get('access_token'))
    this.open = false
  }
},
watch: {
  forceClose() {
    if (this.open) {
      this.open = false
    }
  },
},

});
</script>

<template>
  <ion-icon :icon="ellipsisVertical" @click="this.openTools()"></ion-icon>
  <ion-list lines="none" v-show="this.open">
    <ion-item @click="this.confirm(item)">
      <ion-icon :icon="trashOutline"></ion-icon>
      <ion-label v-html="$t('internal.remove_tickets')"></ion-label>
    </ion-item>
  </ion-list>
</template>

<style scoped>

ion-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 5px 10px;
}

ion-icon:hover {
  color: var(--ion-color-dark-shade)
}

ion-list {
  position: absolute;
  z-index: 1;
  right: 65px;
  top: 5px;
  box-shadow: 0px 0px 5px 0px var(--ion-color-dark-tint);
  background: var(--ion-color-white);
  border-radius: 5px;
  padding: 5px;
}

ion-item {
  --background: var(--ion-color-white);
  cursor: pointer;
}

ion-item:hover {
  --background: var(--ion-color-light-shade)
}

</style>
