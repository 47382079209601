import { Event } from '@/modules/events/types'
import { UserEventState } from '@/modules/events/enums'


export type State = {
  events: [Event],
};

export const state = (): State => ({
  events: [{
    eventId: 0,
    title: "",
    description: "",
    details: [{title: "", description: "", icon: ""}],
    startDate: "",
    endDate: "",
    type:"past",
    state: UserEventState.NOT_REGISTERED,
    linkInfoPack: "",
    linkWhatsApp: "",
    background: "",
    edit: false
  }]
})
