<script lang="ts">
import { IonBadge } from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../../business'
import { refresh } from "ionicons/icons";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import ToolButtonTickets from '../ToolButtonTicketsComponent.vue'

export default defineComponent({
name: 'TicketsTable',
  components: {
    IonBadge,
    // IonCheckbox,
    ButtonLoading,
    Vue3EasyDataTable,
    // ProgressBar,
    ToolButtonTickets,
  },
   setup() {
    return {
      refresh
    }
  },
  computed: {
    ...mapGetters('internal',{
      search: GettersTypes.search,
      tickets: GettersTypes.tickets,
      loading: GettersTypes.loading
    }),
  },
  methods: {
    ...mapActions('internal',{
      getTickets: ActionTypes.getTickets,
      scan: ActionTypes.scan
    }),
    formattedTime(time) {
      var date = new Date(time)
      var formattedTime = date.toLocaleTimeString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) 
      return formattedTime
    },
    scanTicket(item) {
      this.scan({ticket_token: item.ticket_token, access_token: this.$cookies.get('access_token')})
      var index = this.tickets.findIndex(function(e) {return e.id == parseInt(item.id)})
      this.tickets[index].scanned = true
    },
    async refreshTable() {
      this.refreshLoading = true
      await this.getTickets(this.$cookies.get('access_token'))
      var ids = this.data.map(a => a.id)
      if (this.tickets && this.tickets.length > 0) {
        var newData = this.tickets.filter(e => !ids.includes(e.id))
        if (newData.length) {
          this.data = newData.concat(this.data)
        }
      }
      this.refreshLoading = false
    },
    closeTools() {
      this.forceCloseTools = true
      setTimeout(() => {
        this.forceCloseTools = false
      }, 10);
    },
  },
  data() {
    return {
      headers: [
        { text: "CREATION TIME", value: "creation_time", sortable: true},
        { text: "EVENT", value: "event", sortable: true},
        { text: "EMAIL", value: "email", sortable: true},
        { text: "TITLE", value: "title", sortable: true},
        { text: "ITEM", value: "item", sortable: true},
        { text: "QUANTITY", value: "quantity", sortable: true},
        { text: "PRICE", value: "price", sortable: true},
        { text: "INVITED BY", value: "invited_by", sortable: true},
        { text: "SCANNED", value: "scanned", sortable: true},
        { text: "", value: "tools"},
        
      ],
      searchValue: ref(""),
      forceCloseTools: false,
      data: [],
      refreshLoading: false
    }
  },
  async mounted() {
    await this.getTickets(this.$cookies.get('access_token'))
    this.data = this.tickets
  },
  watch: {
    tickets: {
      handler() {
        console.log('tickets changed')
        this.data = this.tickets
      },
      deep: true
    }
  }
})
</script>

<template>
   <vue3-easy-data-table
    buttons-pagination
    alternating
    :headers="headers" 
    :items="this.data" 
    :loading="this.data.length ? false : this.loading"
    :search-value="this.search"
    @click="closeTools()">

    <template v-slot:[`header-tools`]>
      <div class="customize-header">
        <ion-icon v-if="this.data.length ? !this.loading : true" :icon="refresh" class="refresh" @click="this.refreshTable()"></ion-icon>
        <div v-else class="dot-carousel"></div>
      </div>
    </template>

    <template v-slot:[`item-creation_time`]="{ creation_time }">
      {{ this.formattedTime(creation_time) }}
    </template>

    <template v-slot:[`item-price`]="item">
      {{ parseInt(item.price) * parseInt(item.quantity) + parseInt(item.service_fee) + '€' }}
    </template>

    <template v-slot:[`item-scanned`]="item">
      <ion-badge v-if="item.scanned" color="success">YES</ion-badge>
      <ion-row  v-else>
        <ion-badge color="danger">NO</ion-badge>
        <button-loading class="action-button" color="medium" text="scan" @click="this.scanTicket(item)"></button-loading>
      </ion-row>
    </template>

    <template v-slot:[`item-tools`]="item">
      <div v-if="!item.edit">
        <tool-button-tickets :item="item" :forceClose="this.forceCloseTools"></tool-button-tickets>
      </div>
      <div v-else class="dot-carousel"></div>
      
    </template>

  </vue3-easy-data-table>
</template>

<style scoped>

ion-row {
  min-width: 120px;
}

.action-button {
  height: 20px;
  margin: 0 5px
}

  .customize-header {
    width: 35px;
  }

  ion-icon {
    margin: 5px 6px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .refresh {
    margin: 0;
    
  }
  .refresh:hover {
    color: var(--ion-color-dark-shade)
  }

.dot-carousel {
    position: relative;
    left: -9999px;
    margin: 0 25px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--ion-color-light);
    color: var(--ion-color-light);
    box-shadow: 9984px 0 0 0 var(--ion-color-light), 9999px 0 0 0 var(--ion-color-light), 10014px 0 0 0 var(--ion-color-light);;
    animation: dotCarousel 1.5s infinite linear;
  }
  
  @keyframes dotCarousel {
    0% {
      box-shadow: 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade);
    }
    50% {
      box-shadow: 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade);
    }
    100% {
      box-shadow: 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade);
    }
  }


</style>
