import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "sign-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_iom_item = _resolveComponent("iom-item")!
  const _component_sign_in = _resolveComponent("sign-in")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.closeOutline,
                      color: "danger",
                      size: "large"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, {
              innerHTML: _ctx.$t('events.modal.title_register')
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item_divider, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('events.modal.subtitle_signup')
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_iom_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              class: "login",
              onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$router.push({ path: _ctx.$t('toolbar.path_signup'), query: {register: this.eventId} }); this.confirm()}),
              innerHTML: _ctx.$t('toolbar.title_signup')
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item_divider, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('events.modal.subtitle_signin')
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_sign_in, {
            onConfirm: _cache[1] || (_cache[1] = ($event: any) => (this.confirm())),
            createAccount: false
          })
        ])
      ]),
      _: 1
    })
  ], 64))
}