<script lang="ts">
import { IonContent, IonPage, IonText, IonRow} from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import EventCardComponent from '@/modules/events/presentation/components/EventCardComponent.vue'
import CoreValues from '../components/CoreValuesComponent.vue'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { logoInstagram, logoFacebook, logoSoundcloud, logoTwitch } from "ionicons/icons";

export default defineComponent({
name: 'PortfolioPage',
  components: {
    IonContent,
    IonPage,
    IonText,
    EventCardComponent,
    CoreValues,
    Carousel,
    Slide,
    Navigation,
    // IonSpinner,
    IonRow
  },
  data() {
    return {
      randomIndex: [...new Set(Array.from({length: 40}, () => Math.floor(Math.random() * 40)).slice(0,15).filter(e => e != 0))]
    }
  },
  computed: {
    ...mapGetters('events',{
      events: 'EVENTS',
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED',
    }),
  },
  methods: {
    ...mapActions('events',{
      getNextEvent: 'GET_NEXT_EVENT',
    }),
    getGalleryImage(i) {
      return "url("+process.env.VUE_APP_ASSETS_URL+"/gallery/gallery_" + i + ".jpg)"
    },
    getEvent() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      const event = this.events.filter(e => new Date(e.startDate) >= tomorrow)
      if (event) {
        return event[0]
      }
    },
  },
  mounted() {
    this.getNextEvent(this.$cookies.get('access_token'))
  },
  setup() {
    return {
      logoInstagram,
      logoFacebook,
      logoSoundcloud,
      logoTwitch
    }
  },
  watch: {
    isAuthorized() {
      if (this.isAuthorized) {
        this.getNextEvent(this.auth.token)
      }
    }
  }
});
</script>


<template>
  <ion-page>
    <ion-content>
      <div class='about_us'>
        <ion-text v-html="$t('portfolio.heading_first')"></ion-text>
          <div class="video-container">
            <iframe width="560" height="315" :src="$t('portfolio.link_youtube')" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <ion-text v-html="$t('portfolio.heading_second')"></ion-text>
      </div>

      <div class="item-divider">
        <ion-label class="divider-center">
          <p v-html="$t('portfolio.divider.first')"></p>
        </ion-label>
      </div>

      <div class="next-event">
        <event-card-component @submit="this.events[0].state = 'REGISTERED'" :event="this.getEvent()" ></event-card-component>
        <!-- <ion-spinner v-else></ion-spinner>  v-if="this.nextEvent.eventId" -->
      </div>
      
      <div class="item-divider">
        <ion-label class="divider-center">
          <p v-html="$t('portfolio.divider.second')"></p>
        </ion-label>
      </div>
      
      <item-list v-for="item in $tm('portfolio.content_first')" :key="item">
        <core-values :item="item"></core-values>
      </item-list>

      <div class="item-divider">
        <ion-label class="divider-center">
          <p v-html="$t('portfolio.divider.third')"></p>
        </ion-label>
      </div>

      <div class="gallery">
        <carousel :items-to-show="1.4" :wrapAround="true" >
          <slide v-for="slide in this.randomIndex" :key="slide">
            <div :style="{backgroundImage: this.getGalleryImage(slide)}" class="carousel__item gallery_item"></div>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
      

      <div class="item-divider">
        <ion-label class="divider-center">
          <p v-html="$t('portfolio.divider.fourth')"></p>
        </ion-label>
      </div>

      <item-list v-for="item in $tm('portfolio.content_second')" :key="item">
        <core-values :class="smaller-title" :item="item"></core-values>
      </item-list>

      <div class="item-divider">
        <ion-label class="divider-center">
          <p v-html="$t('portfolio.divider.fifth')"></p>
        </ion-label>
      </div>

      <ion-row>
        <ion-button shape="round" color="dark" onclick="window.open('https://www.instagram.com/cabanalibre/')">
          <ion-icon :icon="logoInstagram" size="large"></ion-icon>
        </ion-button>

        <ion-button shape="round" color="dark" onclick="window.open('https://www.facebook.com/CabanaLibreInt')">
          <ion-icon :icon="logoFacebook" size="large"></ion-icon>
        </ion-button>

        <ion-button shape="round" color="dark" onclick="window.open('https://soundcloud.com/cabanalibre')">
          <ion-icon :icon="logoSoundcloud" size="large"></ion-icon>
        </ion-button>

        <ion-button shape="round" color="dark" onclick="window.open('https://www.twitch.tv/cabanalibre')">
          <ion-icon :icon="logoTwitch" size="large"></ion-icon>
        </ion-button>
      </ion-row>

      <div class="footer">
        <div></div>
        <p v-html="$t('portfolio.copyright')"></p>
      </div>
    </ion-content>
  </ion-page>  
</template>

<style scoped>




ion-title {
  margin-bottom: 20px;
  text-align: center;
}

ion-card-title {
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 20px;
}

ion-text {
  line-height: 25px;
}

ion-spinner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 155px;
}

ion-row {
  height: 100px;
  max-width: 400px;
  margin: 50px auto;
}

ion-button {
  margin: auto;
  padding: 0;
  height: 60px;
  width: 60px;
}

ion-icon {
  min-width: 30px;
}


.button-native {
  padding: 0;
}

.about_us {
  padding: 20px;
  text-align: center;
  margin: auto;
  max-width: 780px;
}

.divider-center {
  margin-left: 0;
  background: var(--ion-background-color);
  }

.gallery {
  max-width: 780px;
  margin: 50px auto;
}

.gallery_item {
  height: 330px; 
  width: 586px;
  background-size: cover;
  background-position: center;
}


.footer {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  text-align: right;
  margin: auto;
  padding: 0 20px;
}

.footer div {
  height: 80px;
  width: 150px;
  margin-bottom: 13px;
  background-image: url("https://app.cabanalibre.com/assets/image/cl_logo.svg");
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  align-self: start
}

.footer p {
  font-weight: 200;
  font-size: 12px;
  align-self: flex-end;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: 20px auto
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  
  width: 100%;
  height: 100%;
}

/* Gallery */
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
  z-index: 10
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}

@media (max-width: 780px) { 
  .carousel__item {
    max-height: 200px
  }
}

</style>
