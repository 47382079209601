import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_scan_response = _resolveComponent("scan-response")!
  const _component_qrcode_stream = _resolveComponent("qrcode-stream")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_loading, {
        "is-open": this.loaderShown,
        message: ""
      }, null, 8, ["is-open"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_scan_response, {
            class: _normalizeClass(["scan_response", this.scanned ? 'scanned' : '']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideDetails()))
          }, null, 8, ["class"]),
          _createVNode(_component_qrcode_stream, {
            camera: _ctx.camera,
            onDecode: _ctx.onDecode,
            onInit: _ctx.onInit
          }, null, 8, ["camera", "onDecode", "onInit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}