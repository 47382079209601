import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "sign-in-header" }
const _hoisted_2 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_title, {
          innerHTML: _ctx.$t('auth.reset_password.modal_title')
        }, null, 8, ["innerHTML"]),
        _createElementVNode("form", null, [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, {
                position: "floating",
                color: this.passwordDanger
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('auth.reset_password.password')) + " ", 1),
                  (this.passwordDanger)
                    ? (_openBlock(), _createBlock(_component_ion_text, {
                        key: 0,
                        class: "danger-message",
                        color: "danger",
                        innerHTML: _ctx.$t('auth.reset_password.password_validation')
                      }, null, 8, ["innerHTML"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["color"]),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateInput())),
                onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
                type: "password"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_button_loading, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => {this.submit();}),
              isLoading: this.loaderShown,
              color: this.buttonDanger,
              text: _ctx.$t('confirm')
            }, null, 8, ["isLoading", "color", "text"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}