import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "customize-header" }
const _hoisted_2 = {
  key: 1,
  class: "dot-carousel"
}
const _hoisted_3 = {
  key: 1,
  class: "data"
}
const _hoisted_4 = {
  key: 1,
  class: "data"
}
const _hoisted_5 = {
  key: 1,
  class: "data"
}
const _hoisted_6 = {
  key: 1,
  class: "data"
}
const _hoisted_7 = { class: "data" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "dot-carousel"
}
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_vue3_easy_data_table = _resolveComponent("vue3-easy-data-table")!

  return (_openBlock(), _createBlock(_component_vue3_easy_data_table, {
    "buttons-pagination": "",
    alternating: "",
    headers: _ctx.headers,
    items: _ctx.data,
    loading: _ctx.data.length ? false : _ctx.loading,
    "search-value": _ctx.search
  }, {
    "header-actions": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_icon, {
          icon: _ctx.add,
          class: "add",
          onClick: _ctx.createNewDiscount
        }, null, 8, ["icon", "onClick"]),
        (!_ctx.refreshLoading)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              icon: _ctx.refresh,
              class: "refresh",
              onClick: _ctx.refreshTable
            }, null, 8, ["icon", "onClick"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2))
      ])
    ]),
    "item-code": _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.code,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.code) = $event),
            value: item.code
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.code), 1))
    ]),
    "item-percentage": _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.percentage,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.percentage) = $event),
            type: "number",
            value: item.percentage
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.percentage) + "%", 1))
    ]),
    "item-expiration_date": _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.expiration_date,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.expiration_date) = $event),
            type: "date",
            value: item.expiration_date
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.formattedTime(item.expiration_date)), 1))
    ]),
    "item-max_uses": _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.max_uses,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.max_uses) = $event),
            type: "number",
            value: item.max_uses
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.max_uses), 1))
    ]),
    "item-used": _withCtx((item) => [
      _createElementVNode("div", _hoisted_7, _toDisplayString(item.used), 1)
    ]),
    "item-actions": _withCtx((item) => [
      (!item.edit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_ion_icon, {
              icon: _ctx.pencil,
              class: "action-icon",
              onClick: ($event: any) => (_ctx.editDiscount(item))
            }, null, 8, ["icon", "onClick"]),
            _createVNode(_component_ion_icon, {
              icon: _ctx.close,
              class: "action-icon delete",
              onClick: ($event: any) => (_ctx.deleteDiscountItem(item))
            }, null, 8, ["icon", "onClick"])
          ]))
        : (item.editLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_ion_icon, {
                icon: _ctx.checkmark,
                class: "action-icon save",
                onClick: ($event: any) => (_ctx.updateDiscountDetails(item))
              }, null, 8, ["icon", "onClick"]),
              _createVNode(_component_ion_icon, {
                icon: _ctx.close,
                class: "action-icon cancel",
                onClick: ($event: any) => (_ctx.editDiscount(item))
              }, null, 8, ["icon", "onClick"])
            ]))
    ]),
    _: 1
  }, 8, ["headers", "items", "loading", "search-value"]))
}