<script lang="ts">
  import { IonContent, IonHeader, IonTitle, IonToolbar, IonList, IonItem, IonInput, IonSelectOption, IonText, modalController} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex'
  import { GettersTypes, ActionTypes } from '../../../business'
  import { closeOutline } from 'ionicons/icons';
  import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue';

  export default defineComponent({
    name: 'AssignTicketModal',
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonList, 
      IonItem,
      IonInput, 
      IonText,
      ButtonLoading
    },
    props: {
      email: {
        type: String
      }
    },
    setup() {
      return {
        closeOutline
      }
    },
    data() {
      return {
        event: undefined,
        page: undefined,
        item: undefined,
        isLoading: false,
        buttonColor: 'light',
        quantity: undefined,
      }
    },
    computed: {
    ...mapGetters('internal',{
      checkoutPages: GettersTypes.checkoutPages,
    }),
  },
    methods: {
      ...mapActions('internal',{
        getCheckoutPages: ActionTypes.getCheckoutPages,
        assignTicket: ActionTypes.assignTicket,
      }),
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      async confirm() {
        this.isLoading = true
        var selectedEvent = 0
        var selectedPage = 1
        var selectedItem = this.item
        
        await this.assignTicket({token: this.$cookies.get('access_token'), data: [{ email: this.email, event_title: "tokens", event_id: 1, page_id: 1, item_id: 1, quantity: parseInt(this.quantity) }]})
        this.isLoading = false
        this.buttonColor = 'success'
        setTimeout(() => {
          return modalController.dismiss(this, 'confirm');
        }, 1000);
      },
      getEvents() {
        return [...new Set( this.checkoutPages.map(item => item.event))]
      },
      getPages(event) {
        var pages = this.checkoutPages.filter(function(e){return e.event == event})
        return [...new Set( pages.map(item => item.page))]
      },
      getItems(page) {
        var items = this.checkoutPages.filter(function(e){return e.page == page})
        return [...new Set( items.map(item => item.item))]
      }
    },
    mounted(){
      this.getCheckoutPages(this.$cookies.get('access_token'))
    },
    watch: {
      event() {
        this.page = undefined,
        this.item = undefined
      },
      page() {
        this.item = undefined
      },
    }
  });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel" size="large">
          <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    <ion-title v-html="$t('internal.assign_token')"></ion-title>
    </ion-toolbar>
  </ion-header>
    <ion-content>
      <ion-list lines="none">
        <ion-item>
          <ion-label>Email</ion-label>
          <ion-text>{{ this.email }}</ion-text>
        </ion-item>
      </ion-list>

      <ion-list lines="none">
        <ion-item>
          <ion-label>Quantity</ion-label>
          <ion-input
            v-model="quantity"
            interface="popover"
            @ionChange="this.item = $event.detail.value"
            type="number"
            style="text-align: right;"
            placeholder="0"
          >
          </ion-input>
        </ion-item>
      </ion-list>

      <button-loading class="confirm" text="Confirm" :color="this.buttonColor" :isLoading="this.isLoading" :disabled="!this.item" @click="confirm()"></button-loading>
    </ion-content>
</template>

<style scoped>
  ion-content {
    --padding-top: 50px;
    --background: var(--ion-color-white)
  }
  ion-list {
    max-width: 400px;
    margin: auto;
    background: var(--ion-color-white)
  }
  ion-item {
    --background: var(--ion-color-white)
  }
  ion-select {
    max-width: 100%;
  }

  .confirm {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    margin: 20px 0;
  }

  @media (max-width: 780px) { 
    ion-content {
      --padding-top: 20px;
    }
  }

</style>
