<script lang="ts">
import { IonContent, IonPage, IonHeader, IonCard, IonTitle } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { GettersTypes } from '../../business'
import SignIn from '@/modules/auth/presentation/components/SignInComponent.vue'

export default defineComponent({
name: 'SignInPage',
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonCard,
    IonTitle,
    SignIn
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized
    }),
  },
  watch: {
    isAuthorized() {
      if (this.isAuthorized) {
        this.$router.push({ path: '/' })
      }
    }
  }
});
</script>


<template>
  <ion-page>
    <ion-content>
        <ion-card v-show="!this.isAuthorized">
            <ion-header class="ion-no-border">
              <ion-title v-html="$t('auth.signin.title')"></ion-title>
              <br>
              <ion-subtitle v-html="$t('auth.signin.subtitle')"></ion-subtitle>
            </ion-header>
            <sign-in @confirm="this.$emit('confirm')"></sign-in>
        </ion-card>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-card {
  margin: 20px auto;
  height: 500px;
  width: 90%;
  max-width: 400px;
  padding: 50px 50px;
  --box-shadow: none;
  background: var(--ion-color-white)
}

.wrapper {
  margin-top: 20px 
}

ion-header {
  margin-top: 20px;
  margin-bottom: 10px;
}

ion-title {
  color: var(--ion-color-dark);
  position: relative;
  padding: 0;
  text-align: left
}


@media (max-width: 780px) { 
  ion-card {
    margin-top: 50px;
    padding: 40px 30px;
  }
}

</style>
