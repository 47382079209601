<script lang="ts">
import { mapActions } from 'vuex'
import { ActionTypes } from '../../../business'
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonFabButton, IonIcon, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, logInOutline } from 'ionicons/icons';
import { Site } from '../../../enums'

export default defineComponent({
    name: 'UserExistsModal',
    props: {
        prefilledEmail: {
            type: String
        },
        site: {
            type: String
        }
    },
    data() {
      return {
        email: this.prefilledEmail,
        Site
      }
    },
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonButtons,
      IonFabButton,
      IonIcon,
    },
    setup() {
      return {
        closeOutline,
        logInOutline
      }
    },
    methods: {
    ...mapActions({
      sendRenewalEmail: ActionTypes.sendRenewalEmail,
    }),
    cancel() {
      this.$emit('cancel')
      return modalController.dismiss(null, 'cancel');
    },
    confirm() {
      return modalController.dismiss(this, 'confirm');
    },
    submit() {
      this.sendRenewalEmail(this.email)
      return modalController.dismiss(this, 'confirm');
    },
  },
});
</script>

<template>
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button color="medium" @click="cancel" size="large">
              <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
            </ion-button>
          </ion-buttons>
        <ion-title v-html="$t('auth.signup.modal_user_exists.modal_title')"></ion-title>
        </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-title v-html="$t('auth.signup.modal_user_exists.title').replace('*site*', Site[this.site])"></ion-title>
    <ion-fab-button color="light" @click="this.$router.push({path: $t('toolbar.path_signin'), query: {email: this.email} }); confirm()">
      <div>
        <ion-icon :icon="logInOutline"></ion-icon>
        <p v-html="$t('auth.signup.modal_user_exists.button')"></p>
      </div>
    </ion-fab-button>
    
  </ion-content>
</template>

<style scoped>
ion-title {
    margin: 10px 0;
}

ion-content, ion-item {
  --background: var(--ion-color-white)
}

ion-content ion-title {
  text-align: center;
}

ion-fab-button {
  margin: 50px auto;
  height: 200px;
  width: 200px;
}

ion-fab-button ion-icon {
  margin-left: -20px;
  height: 100px;
  width: 100px;
}

ion-fab-button p {
  margin: 0;
}

form {
  margin-top: 20px;
}

</style>
