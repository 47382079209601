import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "grid_1" }
const _hoisted_3 = { class: "grid_2" }
const _hoisted_4 = { class: "grid_3" }
const _hoisted_5 = { class: "grid_4" }
const _hoisted_6 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_loading, {
            "is-open": this.loaderShown,
            message: ""
          }, null, 8, ["is-open"]),
          _withDirectives(_createVNode(_component_ion_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, { class: "ion-no-border" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, {
                    innerHTML: _ctx.$t('auth.signup.title')
                  }, null, 8, ["innerHTML"]),
                  _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_component_ion_subtitle, {
                    innerHTML: _ctx.$t('auth.signup.subtitle')
                  }, null, 8, ["innerHTML"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_text, {
                    class: "subtitle",
                    innerHTML: _ctx.$t('auth.signup.credentials')
                  }, null, 8, ["innerHTML"]),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        position: "floating",
                        color: this.emailDanger
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('auth.email')) + " ", 1),
                          _withDirectives(_createVNode(_component_ion_text, {
                            class: "danger-message",
                            color: "danger",
                            innerHTML: _ctx.$t('auth.signup.email_validation')
                          }, null, 8, ["innerHTML"]), [
                            [_vShow, this.emailDanger]
                          ]),
                          _withDirectives(_createVNode(_component_ion_text, {
                            class: "danger-message",
                            color: "danger",
                            innerHTML: _ctx.$t('auth.signup.email_exists')
                          }, null, 8, ["innerHTML"]), [
                            [_vShow, this.userExists]
                          ])
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_input, {
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                        onChange: _cache[1] || (_cache[1] = ($event: any) => {_ctx.validateInput(); _ctx.checkExistingEmail()}),
                        onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
                        type: "email"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        position: "floating",
                        color: this.instagramDanger
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(this.noInstagram ? _ctx.$t('auth.referral') :  _ctx.$t('auth.instagram')), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_input, {
                        class: "instagram_input",
                        modelValue: _ctx.instagram,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.instagram) = $event)),
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateInput())),
                        onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_ion_button, {
                        color: this.noInstagram ? 'primary' : 'danger',
                        class: "no_instagram",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (this.noInstagram = !this.noInstagram))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(this.noInstagram ?  _ctx.$t('auth.short_instagram') : _ctx.$t('auth.short_no_instagram')), 1)
                        ]),
                        _: 1
                      }, 8, ["color"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        position: "floating",
                        color: this.passwordDanger
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('auth.password')) + " ", 1),
                          (this.passwordDanger)
                            ? (_openBlock(), _createBlock(_component_ion_text, {
                                key: 0,
                                class: "danger-message",
                                color: "danger",
                                innerHTML: _ctx.$t('auth.signup.password_validation')
                              }, null, 8, ["innerHTML"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_input, {
                        modelValue: _ctx.password,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.password) = $event)),
                        onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.validateInput())),
                        onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.submit()), ["enter"])),
                        type:  this.showPassword ? 'text' : 'password'
                      }, null, 8, ["modelValue", "type"]),
                      _createVNode(_component_ion_button, {
                        class: "show_password",
                        slot: "end",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (this.showPassword = !this.showPassword))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: this.showPassword ? _ctx.eyeOffOutline : _ctx.eyeOutline,
                            size: "small"
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ion_item, {
                    class: "checkbox",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        mode: "md",
                        class: _normalizeClass(this.checkboxDanger ? 'checkbox-danger' : ''),
                        onClick: _cache[11] || (_cache[11] = ($event: any) => {this.checkbox = !this.checkbox; this.submitted ? this.validateInput() : '';}),
                        position: "start"
                      }, null, 8, ["class"]),
                      _createVNode(_component_ion_label, {
                        position: "fixed",
                        color: this.checkboxDanger ? 'danger' : '',
                        class: "checkbox-label",
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (this.openTermsOfUseModal()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('auth.terms')), 1)
                        ]),
                        _: 1
                      }, 8, ["color"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_button_loading, {
                  color: this.buttonDanger,
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (this.submit())),
                  isLoading: this.loaderShown,
                  text: _ctx.$t('auth.signup.button_submit')
                }, null, 8, ["color", "isLoading", "text"])
              ])
            ]),
            _: 1
          }, 512), [
            [_vShow, !this.isAuthorized]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}