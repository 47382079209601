<script lang="ts">
import { mapActions } from 'vuex'
import { ActionTypes } from '../../../business'
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon, IonInput, IonLabel, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
    name: 'ForgottenPasswordModal',
    props: {
        prefilledEmail: {
            type: String
        }
    },
    data() {
      return {
        email: this.prefilledEmail
      }
    },
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonButtons,
      IonIcon,
      IonInput,
      IonLabel
    },
    setup() {
      return {
        closeOutline
      }
    },
    methods: {
    ...mapActions({
      sendRenewalEmail: ActionTypes.sendRenewalEmail,
    }),
    cancel() {
      this.$emit('cancel')
      return modalController.dismiss(null, 'cancel');
    },
    confirm() {
      return modalController.dismiss(this, 'confirm');
    },
    submit() {
      this.sendRenewalEmail(this.email)
      return modalController.dismiss(this, 'confirm');
    },
  },
});
</script>

<template>
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button color="medium" @click="cancel" size="large">
              <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
            </ion-button>
          </ion-buttons>
        <ion-title v-html="$t('auth.forgotten_password.modal_title')"></ion-title>
        </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="sign-in-header">
      <ion-title v-html="$t('auth.forgotten_password.title')"></ion-title>
      <ion-subtitle v-html="$t('auth.forgotten_password.subtitle')"></ion-subtitle>
      <form>
        <ion-item>
          <ion-label position="floating" :color="this.emailDanger" v-html="$t('auth.forgotten_password.email')"></ion-label>
          <ion-input :value="this.prefilledEmail" v-model="email" @change="validateInput()" type="email"></ion-input>
        </ion-item>
        <div class="button">
          <ion-button @click="this.submit()" :color="this.buttonDanger" v-html="$t('auth.forgotten_password.button_send')"></ion-button>
        </div>
      </form>
    </div>
    
  </ion-content>
</template>

<style scoped>
ion-title {
    margin: 10px 0
}
ion-content, ion-item {
  --background: var(--ion-color-white)
}
form {
  margin-top: 20px;
}
.sign-in-subtitle {
    margin: 20px 130px;
    margin-bottom: 50px;
    padding-bottom: 50px;
}
.sign-in-header {
  max-width: 300px;
  margin: 20px auto;
  margin-top: 80px;
  margin-bottom: 50px
}
.center {
  position: absolute;
  margin: 50px auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 120px;
}

.button {
  margin-top: 20px;
  text-align: center
}
</style>
