<script lang="ts">
import { defineComponent } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { mapGetters, mapActions } from 'vuex';
import { GettersTypes, ActionTypes } from '../../business'
import RegistrationsTable from '../components/tables/RegistrationsTableComponent.vue';
import PaymentsTable from '../components/tables/PaymentsTableComponent.vue';
import TicketsTable from '../components/tables/TicketsTableComponent.vue';
import UsersTable from '../components/tables/UsersTableComponent.vue';
import DiscountsTable from '../components/tables/DiscountsTableComponent.vue';
import { Tables } from '../../enums';

export default defineComponent({
  name: 'TablePage',
  components: {
    IonContent,
    IonPage,
    RegistrationsTable,
    PaymentsTable,
    TicketsTable,
    UsersTable,
    DiscountsTable
  },
  data() {
    return {
      Tables
    }
  },
  computed: {
    ...mapGetters('internal', {
      scanData: GettersTypes.scanData,
      table: GettersTypes.table
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED',
    })
  },
  methods: {
    ...mapActions('internal', {
      scan: ActionTypes.scan,
    }),
  },
});
</script>

<template>
  <ion-page>
    <ion-content>
      <users-table v-if="this.$route.query.name == Tables.USERS"></users-table>
      <payments-table v-else-if="this.$route.query.name == Tables.PAYMENTS"></payments-table>
      <tickets-table v-else-if="this.$route.query.name == Tables.TICKETS"></tickets-table>
      <discounts-table v-else-if="this.$route.query.name == Tables.DISCOUNTS"></discounts-table>
      <registrations-table v-else></registrations-table>
    </ion-content>
  </ion-page>  
</template>

<style scoped>

  .scan_response {
    transition: 0.5s;
    top: -400px;
  }

 .scanned {
    top: 20px
  }

  .dot-carousel {
    position: relative;
    left: -9999px;
    margin: 0 25px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--ion-color-light);
    color: var(--ion-color-light);
    box-shadow: 9984px 0 0 0 var(--ion-color-light), 9999px 0 0 0 var(--ion-color-light), 10014px 0 0 0 var(--ion-color-light);;
    animation: dotCarousel 1.5s infinite linear;
  }
  
  @keyframes dotCarousel {
    0% {
      box-shadow: 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade);
    }
    50% {
      box-shadow: 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade);
    }
    100% {
      box-shadow: 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade);
    }
  }

</style>
