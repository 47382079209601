<script lang="ts">
import { IonMenu, menuController } from '@ionic/vue';
import { defineComponent } from 'vue';
import router from '@/router'

export default defineComponent({
  name: 'QRPage',
  data() {
    return {
      url: 'https://www.instagram.com/cabanalibre/',
      discord: 'https://discord.com/api/webhooks/950840633755582464/LFtYRHf-J2AAAO5hzeZs_poBwIRypzIbhgHUkkGXj0vMMfspPOydoQPrbkyIY6GJUfaP'
    }
  },
  methods: {
    redirect(url) {
      fetch(this.discord, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'content': 'QR Sticker Scanned!'})
      })
      window.location.href = url
      this.$router.push({ path:'/home' })
    }
  },
  mounted() {
    this.redirect(this.url)
  }
});
</script>


<template>
  <div class="container">
    <p>You're being redirected</p>
    <div class="show">
      <a :href="this.url">Click here!</a>
      <p>If it takes too long</p>
    </div>
  </div>
</template>

<style scoped>
  .container {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    color: var(--ion-color-dark);
    text-align: center;
    
  }
  a {
    font-size: 32px;
    color: var(--ion-color-dark);
  }
  p {
    font-size: 18px;
  }

  .show {
    width: 100%;
    opacity: 0;
    animation: show 2s forwards ease-in;
  }

  @keyframes show {
    0% {
      opacity: 0;
      margin-top: 50px;
    }
    90% {
      opacity: 0;
      margin-top: 50px;
    }
    100% {
      opacity: 1;
      margin-top: 20px;
    }
  }
</style>
