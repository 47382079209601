import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Assign Ticket")
              ])),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.closeOutline,
                      color: "danger",
                      size: "large"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_text, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("You've been invited to an event!"),
            _createElementVNode("br", null, null, -1),
            _createTextVNode("Do you want to assign ticket to yourself?")
          ])),
          _: 1
        }),
        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_button_loading, {
          color: "success",
          onClick: _ctx.confirm,
          text: _ctx.$t('common.yes'),
          isLoading: this.isLoading
        }, null, 8, ["onClick", "text", "isLoading"]),
        _createVNode(_component_ion_button, {
          color: "danger",
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.no')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ], 64))
}