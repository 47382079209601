<script lang="ts">
import { IonPage, IonContent, IonTitle, IonLoading } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
name: 'SignOutPage',

  component: {
    IonPage,
    IonContent,
    IonTitle,
    IonLoading,
  },
  mounted() {
    setTimeout(() => {
      window.location.href = '/'
    }, 1000);
  },

});
</script>

<template>
  <ion-page>
    <ion-content>
      <ion-title class="page-message" v-html="$t('auth.signout.message')"></ion-title>
    </ion-content>
  </ion-page>
</template>

<style scope>

</style>
