import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_users_table = _resolveComponent("users-table")!
  const _component_payments_table = _resolveComponent("payments-table")!
  const _component_tickets_table = _resolveComponent("tickets-table")!
  const _component_discounts_table = _resolveComponent("discounts-table")!
  const _component_registrations_table = _resolveComponent("registrations-table")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (this.$route.query.name == _ctx.Tables.USERS)
            ? (_openBlock(), _createBlock(_component_users_table, { key: 0 }))
            : (this.$route.query.name == _ctx.Tables.PAYMENTS)
              ? (_openBlock(), _createBlock(_component_payments_table, { key: 1 }))
              : (this.$route.query.name == _ctx.Tables.TICKETS)
                ? (_openBlock(), _createBlock(_component_tickets_table, { key: 2 }))
                : (this.$route.query.name == _ctx.Tables.DISCOUNTS)
                  ? (_openBlock(), _createBlock(_component_discounts_table, { key: 3 }))
                  : (_openBlock(), _createBlock(_component_registrations_table, { key: 4 }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}