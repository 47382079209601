import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"20px","display":"flex","justify-content":"center"} }
const _hoisted_2 = { style: {"margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Share or resell entrances")
              ])),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.closeOutline,
                      color: "danger",
                      size: "large"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _cache[15] || (_cache[15] = _createTextVNode(" > ")),
    (this.ticketToken || this.remainingQuantity == 1)
      ? (_openBlock(), _createBlock(_component_ion_content, {
          key: 0,
          class: "ion-padding"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_text, null, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Share this link:")
              ])),
              _: 1
            }),
            _cache[7] || (_cache[7] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
            _withDirectives(_createVNode(_component_ion_text, { class: "coppied" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Coppied to clipboard!")
              ])),
              _: 1
            }, 512), [
              [_vShow, this.coppied]
            ]),
            _createVNode(_component_ion_button, {
              class: "url",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyClipboard()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.ticketToken ? this.getUrl() : 'Loading ...'), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_icon, {
                  icon: this.coppied ? _ctx.checkmark : _ctx.copy,
                  color: this.coppied ? 'success' : 'light'
                }, null, 8, ["icon", "color"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_subtitle, null, {
              default: _withCtx(() => [
                _createTextVNode("By sharing this link, " + _toDisplayString(this.quantity) + " " + _toDisplayString(this.quantity == 1 ? 'entrance' : 'entrances') + " will be assigned to your guest.", 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_ion_content, {
          key: 1,
          class: "ion-padding",
          style: {"text-align":"center"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(" You have " + _toDisplayString(this.remainingQuantity) + " entrances left. ", 1),
            _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
            _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
            _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
            _cache[14] || (_cache[14] = _createTextVNode(" Select quantity you want to share: ")),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.decrementQuantity()))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("-")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_text, { style: {"margin":"10px","font-size":"21px"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.quantity), 1)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_ion_button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.incrementQuantity()))
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("+")
                ])),
                _: 1
              })
            ]),
            _createVNode(_component_ion_button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createTicketToken({ticket_token: this.token, quantity: this.quantity, auth: this.$cookies.get('access_token')})))
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Generate share link")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
  ], 64))
}