<script lang="ts">
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonLoading, modalController } from '@ionic/vue'
import { musicalNotesOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import TicketCard from '../components/TicketCardComponent.vue'
import AssignTicketModal from '../components/modal/AssignTicketModal.vue'

export default defineComponent({
name: 'TicketsPage',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    TicketCard,
    IonLoading,
  },
  data() {
    return {
      loaderShown: true,
      newTicket: false
    }
  },
  computed: {
    ...mapGetters('tickets',{
      tickets: GettersTypes.tickets
    }),
    ...mapGetters({
      auth: 'AUTH',
      accountInfo: 'ACCOUNT_INFO',
      isAuthorized: 'IS_AUTHORIZED'
    }),
  },
  methods: {
    ...mapActions('tickets',{
      getTickets: ActionTypes.getTickets,
    }),
    ...mapActions({
      confirmPayment: 'CONFIRM_PAYMENT',
      getAccountInfo: 'GET_ACCOUNT_INFO',
    }),
    getActiveTickets() {
      if (this.tickets && this.tickets.length > 0) {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() - 1)
        return this.tickets.filter(e => (new Date(e.startDate) >= tomorrow || new Date(e.endDate) >= tomorrow))
      } else {
        return []
      }
    },
    getPastTickets() {
      if (this.tickets && this.tickets.length > 0) {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() - 1)
        const pastTicket = this.tickets.filter(e => (new Date(e.startDate) < tomorrow && (e.endDate? new Date(e.endDate) < tomorrow : true)))
        if (pastTicket) {
          return pastTicket.slice()
        }
      } else {
        return []
      }
    },
    clearUrlPath() {
      history.pushState(
        {},
        "",
        '/entrances'
      )
    },
    async openAssignTicketModal(token) {
      const modal = await modalController.create({
        component: AssignTicketModal,
        componentProps: {token: token}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      if (role === 'confirm') {
        this.$emit('submit')
      } else {
        this.loaderShown = false
      }
    },
  },
  setup() {
    const isDisabled = ref(false);
    const toggleInfiniteScroll = () => {
      isDisabled.value = !isDisabled.value;
    }
    const items = ref<number[]>([]);
    const pushData = () => {
      const max = items.value.length + 5;
      const min = max - 5;
      for (let i = min; i < max; i++) {
        items.value.push(15-i);
      }
    }
    
    const loadData = (ev: any) => {
      setTimeout(() => {
        pushData();
        ev.target.complete();
  
        // App logic to determine if all data is loaded
        // and disable the infinite scroll
        if (items.value.length === 15) {
          ev.target.disabled = true;
        }
      }, 1000);
    }
    
    pushData();
    
    return {
      isDisabled,
      toggleInfiniteScroll,
      loadData,
      items,
      musicalNotesOutline
    }
    
  },
  mounted() {
 
    const paymentId = this.$route.query.paymentId as string
    if (paymentId) {
      this.confirmPayment(paymentId)
      this.clearUrlPath()
    }
    this.getAccountInfo(this.$cookies.get('access_token'))
    this.getTickets(this.$cookies.get('access_token'))
    const token = this.$route.query.share as string
    
    if (token) {
      if (this.isAuthorized) {
        this.loaderShown = false
        this.openAssignTicketModal(token)
        this.clearUrlPath()
      } else {
        this.$router.push({path:this.$t("toolbar.path_signin"), query:{redirect: "entrances",share: token}})
      }
    }
  },
  watch: {
    tickets() {
      if (this.$route.query.paymentId && !this.newTicket) {
        this.loaderShown = true
        this.getTickets(this.auth.token)
        this.newTicket = true
        this.loaderShown = false
      }
      this.loaderShown = false
    },
    async $route (to, from) {
      if (this.isAuthorized && to.path.includes(this.$t('toolbar.path_tickets'))) {
        this.loaderShown = true
        this.getAccountInfo(this.$cookies.get('access_token'))
        await this.getTickets(this.auth.token)
        this.loaderShown = false
      }
    },
    async isAuthorized() {
      this.loaderShown = true
      await this.getAccountInfo(this.$cookies.get('access_token'))
      await this.getTickets(this.auth.token)
      this.loaderShown = false
      const token = this.$route.query.share as string
      if (token) {
        if (this.isAuthorized) {
          this.loaderShown = false
          this.openAssignTicketModal(token)
          this.clearUrlPath()
        }
      }
    }
  }
});
</script>


<template>
  <ion-page>
    <ion-loading
      :is-open="this.loaderShown"
      message=""
    ></ion-loading>
    <ion-content>
    <div v-if="!this.loaderShown && this.getActiveTickets().length == 0" class="no-tickets">
      <ion-title v-html="$t('tickets.no_tickets.title')"></ion-title>
       <p v-html="$t('tickets.no_tickets.subtitle')"></p>
      <ion-button class="events" color="light" shape="round" @click="this.$router.push({ path: '/events' })" ><ion-icon :icon="musicalNotesOutline"></ion-icon></ion-button>
    </div>
      <ion-list v-if="!this.loaderShown" lines=none>
        <ion-item  v-for="item in this.getActiveTickets()" :key="item">
          <ticket-card :ticket="item" :lock="((this.accountInfo.document == 'None' || this.accountInfo.document == '') || (this.accountInfo.name == 'None' || this.accountInfo.name == '') || (this.accountInfo.surname == 'None' || this.accountInfo.surname == '')  || (this.accountInfo.phone == 'None' || this.accountInfo.phone == '')) ? true : false"></ticket-card>
        </ion-item>
      </ion-list>

      <div v-if="!this.loaderShown && getPastTickets().length > 0" class="item-divider">
        <ion-label class="divider-center">
          <p v-html="$t('tickets.divider.past_tickets')"></p>
        </ion-label>
      </div>

      <ion-list v-if="!this.loaderShown" lines=none>
        <ion-item  v-for="item in this.getPastTickets()" :key="item">          
          <ticket-card :ticket="item"></ticket-card>
        </ion-item>
      </ion-list>

        <ion-infinite-scroll
        @ionInfinite="loadData($event)"
        threshold="100px" 
        id="infinite-scroll"
        :disabled="isDisabled"
        position="bottom"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text=""
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-title {
  margin: 50px auto;
  margin-bottom: 50px;
  text-align: center;
}

.no-tickets {
  margin: auto;
  max-width: 500px
}

.no-tickets p {
  text-align: center;
}

.events {
  top: 20px;
  right: 40px;
  height: 100px;
  width: 100px;
  z-index: 10;
  --padding-start: 0;
  --padding-end: 0;
  margin: 20px 50%;
  transform: translateX(-50%);
}

.events ion-icon {
  margin-left: -5px;
  min-height: 50px;
  min-width: 50px;
}

</style>
