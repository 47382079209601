<script lang="ts">
  import { IonContent, IonHeader, IonTitle, IonToolbar, IonList, IonItem, IonSelect, IonSelectOption, IonText, IonInput, modalController} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex'
  import { GettersTypes, ActionTypes } from '../../../business'
  import { closeOutline } from 'ionicons/icons';
  import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue';

  export default defineComponent({
    name: 'AssignTicketModal',
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonList, 
      IonItem,
      IonSelect, 
      IonSelectOption,
      IonText,
      ButtonLoading,
      IonInput
    },
    props: {
      email: {
        type: String
      }
    },
    setup() {
      return {
        closeOutline
      }
    },
    data() {
      return {
        event: undefined,
        page: undefined,
        item: undefined,
        isLoading: false,
        buttonColor: 'light',
        quantity: undefined
      }
    },
    computed: {
    ...mapGetters('internal',{
      checkoutPages: GettersTypes.checkoutPages,
    }),
  },
    methods: {
      ...mapActions('internal',{
        getCheckoutPages: ActionTypes.getCheckoutPages,
        assignTicket: ActionTypes.assignTicket,
      }),
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      async confirm() {
        this.isLoading = true
        var selectedEvent = this.event
        var selectedPage = this.page
        var selectedItem = this.item
        var tickets = this.checkoutPages.filter(function(e){return e.event == selectedEvent && e.page == selectedPage && selectedItem.includes(e.item)})
        tickets = tickets.map(obj => ({ ...obj, email: this.email, quantity: this.quantity }))
        await this.assignTicket({token: this.$cookies.get('access_token'), data: tickets})
        this.isLoading = false
        this.buttonColor = 'success'
        setTimeout(() => {
          return modalController.dismiss(this, 'confirm');
        }, 1000);
      },
      getEvents() {
        return [...new Set( this.checkoutPages.map(item => item.event))]
      },
      getPages(event) {
        var pages = this.checkoutPages.filter(function(e){return e.event == event})
        return [...new Set( pages.map(item => item.page))]
      },
      getItems(page) {
        var items = this.checkoutPages.filter(function(e){return e.page == page})
        return [...new Set( items.map(item => item.item))]
      }
    },
    mounted(){
      this.getCheckoutPages(this.$cookies.get('access_token'))
    },
    watch: {
      event() {
        this.page = undefined,
        this.item = undefined
      },
      page() {
        this.item = undefined
      },
    }
  });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel" size="large">
          <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    <ion-title v-html="$t('internal.assign_ticket')"></ion-title>
    </ion-toolbar>
  </ion-header>
    <ion-content>
      <ion-list lines="none">
        <ion-item>
          <ion-label>Email</ion-label>
          <ion-text>{{ this.email }}</ion-text>
        </ion-item>
      </ion-list>
      <ion-list lines="none">
        <ion-item>
          <ion-label>Event</ion-label>
          <ion-select interface="popover" placeholder="Select event" @ionChange="this.event = $event.detail.value">
            <ion-select-option v-for="checkoutPage in this.getEvents()" :key="checkoutPage" :value="checkoutPage">{{ checkoutPage }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>

      <ion-list lines="none">
        <ion-item>
          <ion-label>Checkout</ion-label>
          <ion-select interface="popover" placeholder="Select page" @ionChange="this.page = $event.detail.value" :disabled="!this.event">
            <ion-select-option v-for="checkoutPage in this.getPages(this.event)" :key="checkoutPage" :value="checkoutPage">{{ checkoutPage }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
      <ion-list lines="none">
        <ion-item>
          <ion-label>Item</ion-label>
          <ion-select
            interface="popover"
            placeholder="Select item"
            :compareWith="compareWith"
            @ionChange="this.item = $event.detail.value"
            :multiple="true"
            :disabled="!this.page"
          >
            <ion-select-option v-for="checkoutPage in this.getItems(this.page)" :key="checkoutPage" :value="checkoutPage">{{ checkoutPage }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>

      <ion-list lines="none">
        <ion-item>
          <ion-label>Quantity</ion-label>
          <ion-input
            v-model="quantity"
            interface="popover"
            type="number"
            style="text-align: right;"
            placeholder="0"
          >
          </ion-input>
        </ion-item>
      </ion-list>

      <button-loading class="confirm" text="Confirm" :color="this.buttonColor" :isLoading="this.isLoading" :disabled="!this.item || this.quantity == undefined || this.quantity == 0" @click="confirm()"></button-loading>
    </ion-content>
</template>

<style scoped>
  ion-content {
    --padding-top: 50px;
    --background: var(--ion-color-white)
  }
  ion-list {
    max-width: 400px;
    margin: auto;
    background: var(--ion-color-white)
  }
  ion-item {
    --background: var(--ion-color-white)
  }
  ion-select {
    max-width: 100%;
  }

  .confirm {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    margin: 20px 0;
  }

  @media (max-width: 780px) { 
    ion-content {
      --padding-top: 20px;
    }
  }

</style>
