import { Auth, Account, PrefillData } from '../types'

export type State = {
  isAuthorized: boolean,
  auth: Auth,
  accountInfo: Account,
  invalidCredentials: boolean,
  userExists: string,
  prefillData: PrefillData
};

export const state = (): State => ({
  isAuthorized: false,
  auth: {
    token: '',
    role: ''
  },
  accountInfo: {
    username: "", 
    creation_time: "", 
    name: "", 
    surname: "", 
    email: "", 
    document: "",
    phone: "", 
    instagram: ""
  },
  invalidCredentials: false,
  userExists: "",
  prefillData: {
    email: "",
    name: "",
    surname: "",
    document: ""
  }
})
