import { ScanData, ScanStatus, Registrations, Payments, Tickets, Users, CheckoutPages, Discounts } from '@/modules/internal/types'
import { ScanStatuses, Tables } from '../enums';

export type State = {
  search: string,
  table: string,
  scanData: ScanData,
  scanStatus: ScanStatus,
  loading: boolean,
  registrations: Registrations,
  payments: Payments,
  tickets: Tickets,
  users: Users,
  discounts: Discounts,
  checkoutPages: CheckoutPages
};

export const state = (): State => ({
  search: "",
  table: Tables.USERS,
  scanData: {
    creation_time: "",
    email: "",
    quantity: 0,
    title: "",
    item: "",
    scanned: 0,
    price: 0,
    name: "",
    surname: "",
    username: ""
  },
  discounts: [{
    id: 0,
    code: "",
    percentage: 0,
    expiration_date: "",
    max_uses: 0,
    used: 0,
  }],
  scanStatus: {
    status: ScanStatuses.NONE
  },
  loading: true,
  registrations: [{
    id: 0,
    creation_time: "",
    email: "",
    instagram: "",
    name: "",
    surname: "",
    event_id: 0,
    event: "",
    page_id: 0,
    approved: false,
    pre_approved: false,
    approval_delay: false,
    tickets: false
  }],
  payments: [{
    id: 0,
    creation_time: "",
    email: "",
    event: "",
    title: "",
    item: "",
    quantity: 0,
    price: 0,
    service_fee: 0,
    ticket_token: "",
    scanned: false
  }],
  tickets: [{
    id: 0,
    creation_time: "",
    email: "",
    event: "",
    title: "",
    item: "",
    quantity: 0,
    price: 0,
    service_fee: 0,
    ticket_token: "",
    invited_by: "",
    scanned: false
  }],
  users: [{
    id: 0,
    creation_time: "",
    name: "",
    surname: "",
    email: "",
    instagram: "",
    phone: "",
    document: "",
    edit: false,
    editDetails: {
      name: "",
      surname: "",
      instagram: "",
      phone: "",
      document: "",
    },
    editLoading: false,
    new: true
  }],
  checkoutPages: [{
    event: "",
    page: "",
    item: "",
    event_id: 0,
    page_id: 0,
    item_id: 0,
    event_title: "",
  }]
})
