import { MutationTree } from 'vuex'
import { State, state } from './state'
import { Checkout, Contact, Token, Basket } from '../types'

export enum MutationTypes {
  checkout = 'CHECKOUT',
  basket = 'BASKET',
  incrementBasket = 'INCREMENT_BASKET',
  clearBasket = 'CLEAR_BASKET',
  loaderShown = 'LOADER_SHOWN',
  setContact = 'SET_CONTACT',
  upadteTokens = 'UPDATE_TOKENS',
  totalPrice = 'TOTAL_PRICE',
  setDiscount = 'SET_DISCOUNT'
  
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  [MutationTypes.checkout](state: State, checkout: Checkout) {
    state.checkout = checkout;
  },
  [MutationTypes.incrementBasket](state: State, basket: [Basket]) {
    state.basket = basket
  },
  [MutationTypes.clearBasket](state: State) {
    state.basket = [{itemId: 0, quantity: 0, price: 0, priceId: ''}]
  },
  [MutationTypes.setDiscount](state: State, discount: number) {
    state.checkout.discount = discount;
  },
  [MutationTypes.loaderShown](state: State, shown: boolean) {
    state.loaderShown = shown;
  },
  [MutationTypes.setContact](state: State, contact: Contact) {
    state.contact.email = contact.email;
    state.contact.instagram = contact.instagram;
  },
  [MutationTypes.upadteTokens](state: State, tokens: [Token]) {
    state.tokens = tokens;
  },
  [MutationTypes.totalPrice](state: State, price: number) {
    state.totalPrice = price;
  },
}
