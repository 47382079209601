import { MutationTree } from 'vuex'
import { State, state } from '@/modules/events/business/state'
import { State as StatePortfolio } from '@/modules/portfolio/business/state'
import { UserEventState } from '../enums'
import { Event } from '../types'

export enum MutationTypes {
  openRegistration = 'OPEN_REGISTRATION',
  preApprovedToEvent = 'PRE_APPROVED_TO_EVENT',
  getEvents = 'GET_EVENTS',
  getNextEvent = 'GET_NEXT_EVENTS',
  cancelRegistration = 'CANCEL_REGISTRATION',
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  [MutationTypes.getEvents](state: State, events: [Event]) {
    state.events = events;
  },
  [MutationTypes.getNextEvent](state: State, event: Event) {
    state.events = [event];
  },
  [MutationTypes.openRegistration](state: State, eventId: number) {
    const event = state.events.filter(data => data.eventId == eventId);
    if (event.length) {
      event[0].state = UserEventState.REGISTERED;
    } else {
      state.events[0].state = UserEventState.APPROVED
    }
  },
  [MutationTypes.preApprovedToEvent](state: StatePortfolio, eventId: number) {
    const event = state.events.filter(data => data.eventId == eventId);
    if (event.length) {
      event[0].state = UserEventState.PRE_APPROVED;
    } else {
      state.events[0].state = UserEventState.PRE_APPROVED
    }
  },
  [MutationTypes.cancelRegistration](state: State, eventId: number) {
    const event = state.events.filter(data => data.eventId == eventId);
    if (event) {
      event && (event[0].state = UserEventState.NOT_REGISTERED);
    }
  },
}
