<script lang="ts">
  import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon, modalController} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { closeOutline } from 'ionicons/icons';
  import SignIn from '../SignInComponent.vue'

  export default defineComponent({
    name: 'DeleteAccountModal',
    props: {
        prefilledEmail: {
            type: String
        }
    },
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonButtons,
      IonIcon, 
      SignIn
    },
    setup() {
      return {
        closeOutline
      }
    },
    methods: {
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      confirm() {
        return modalController.dismiss(this, 'confirm');
      },
    },
  });
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel" size="large">
          <ion-icon :icon="closeOutline" color="danger" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    <ion-title v-html="$t('auth.delete_account.modal_title')"></ion-title>
    </ion-toolbar>
  </ion-header>
    <ion-content>
      <div class="sign-in-header">
          <ion-title v-html="$t('auth.delete_account.title')"></ion-title>
          <ion-subtitle v-html="$t('auth.delete_account.subtitle')"></ion-subtitle>
      </div>
        <sign-in :prefilledEmail="this.prefilledEmail" :unAuthorized="true" :createAccount="false" :styling="{buttonText: $t('auth.delete_account.button_delete'), buttonColor: 'danger'}"></sign-in>
    </ion-content>
</template>

<style scoped>
ion-title {
    margin: 10px 0
}
ion-content, ion-item {
  --background: var(--ion-color-white)
}
.sign-in-subtitle {
    margin: 20px 130px;
    margin-bottom: 50px;
    padding-bottom: 50px;
}
.sign-in-header {
    max-width: 300px;
  margin: 10px auto;
  margin-top: 50px;
  margin-bottom: 20px
}
</style>
