export enum UserEventState {
    NOT_REGISTERED = 'NOT_REGISTERED',
    REGISTERED = 'REGISTERED',
    PRE_APPROVED = 'PRE_APPROVED',
    APPROVED = 'APPROVED',
    PURCHASED = 'PURCHASED'
  }

export enum EventState {
  LIVE = 'live',
  PLAN = 'plan',
  PLAN_NOREG = 'plan_noreg',
  PAST = 'past',
  SOLD_OUT = 'sold_out'
}