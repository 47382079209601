import { MutationTree } from 'vuex'
import { State, state } from './state'
import { Account, Auth, PrefillData } from '../types'

export enum MutationTypes {
  isAuthorized = 'IS_AUTHORIZED',
  setToken = 'SET_TOKEN',
  getAccountInfo = 'GET_ACCOUNT_INFO',
  invalidCredentials = 'INVALID_CREDENTIALS',
  userExists = 'USER_EXISTS',
  prefillData = "PREFILL_DATA"
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  [MutationTypes.isAuthorized](state: State, authorized: boolean) {
    state.isAuthorized = true;
  },
  [MutationTypes.setToken](state: State, data: Auth) {
    state.auth.token = data.token;
    state.auth.role = data.role;
  },
  [MutationTypes.getAccountInfo](state: State, accountInfo: Account) {
    state.accountInfo = accountInfo;
  },
  [MutationTypes.invalidCredentials](state: State, invalidCredentials: boolean) {
    state.invalidCredentials = invalidCredentials;
  },
  [MutationTypes.userExists](state: State, userExists: string) {
    state.userExists = userExists;
  },
  [MutationTypes.prefillData](state: State, prefillData: PrefillData) {
    state.prefillData = prefillData;
  },
}
