<script lang="ts">
import { defineComponent, ref } from 'vue';
import { IonBadge, IonIcon, IonInput, modalController } from '@ionic/vue';
import { pencil, checkmark, close, refresh, add } from 'ionicons/icons';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css'; // Don't forget to import the CSS
import { mapGetters, mapActions } from 'vuex';
import { useToast } from "vue-toastification";
import { GettersTypes, ActionTypes } from '../../../business';
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue';

export default defineComponent({
  name: 'DiscountsTable',
  components: {
    Vue3EasyDataTable,
    IonIcon,
    IonInput,
  },
  setup() {
    return {
      pencil,
      checkmark,
      close,
      refresh,
      add,
    };
  },
  computed: {
    ...mapGetters('internal', {
      search: GettersTypes.search,
      discounts: GettersTypes.discounts,
      loading: GettersTypes.loading,
    }),
  },
  methods: {
    ...mapActions('internal', {
      getDiscounts: ActionTypes.getDiscounts,
      createDiscount: ActionTypes.createDiscount,
      updateDiscount: ActionTypes.updateDiscount,
      deleteDiscount: ActionTypes.deleteDiscount,
    }),
    formattedTime(time) {
      const date = new Date(time);
      return date.toLocaleString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" });
    },
    editDiscount(item) {
      const index = this.data.findIndex(e => e.id === parseInt(item.id));
      this.data[index].edit = !this.data[index].edit;
      item.editDetails = { ...item };
      this.data[index].editDetails = { ...item };
    },
    async updateDiscountDetails(item) {
      const index = this.data.findIndex(e => e.id === parseInt(item.id));
      if (index === -1) {
        // This is a new discount, so we'll create it instead of updating
        const newDiscount = {
          id: item.id,
          code: item.editDetails.code ? item.editDetails.code : item.code,
          percentage: item.editDetails.percentage ? item.editDetails.percentage : item.percentage,
          expiration_date: item.editDetails.expiration_date? item.editDetails.expiration_date : item.expiration_date,
          max_uses: item.editDetails.max_uses ? item.editDetails.max_uses : item.max_uses,
          used: 0,
          edit: false,
          editLoading: false,
          editDetails: { ...item.editDetails },
        };
        this.data.push(newDiscount);
        const newIndex = this.data.findIndex(e => e.id === parseInt(item.id));
        // this.data[newIndex].editLoading = true;
        try {
          await this.createDiscount({
            token: this.$cookies.get('access_token'),
            data: {
              code: newDiscount.code,
              percentage: newDiscount.percentage,
              expiration_date: newDiscount.expiration_date,
              max_uses: newDiscount.max_uses,
            },

          });
          if (this.data[newIndex]) {
            this.data[newIndex].edit = false;
          }
          // this.data[newIndex].editLoading = false;
        } catch (error) {
          console.error("Failed to create discount for item: ", item, error);
          // this.data[newIndex].editLoading = false;
        }
      } else {
        this.data[index].editLoading = true;
        try {
          await this.updateDiscount({
          commit: this.$store.commit,
          token: this.$cookies.get('access_token'),
          id: item.id,
          data: {
            id: item.id,
            code: item.editDetails.code,
            percentage: item.editDetails.percentage,
            expiration_date: item.editDetails.expiration_date,
            max_uses: item.editDetails.max_uses,
          },
          });
          Object.assign(this.data[index], item.editDetails);
          this.data[index].edit = false;
        } catch (error: any) {
          console.error("Failed to update discount details for item: ", item, error);
          useToast().error(error.message || "Failed to update discount. Please try again.");
        } finally {
          this.data[index].editLoading = false;
        }
      }
    },
    async createNewDiscount() {
      const newDiscount = {
        code: '',
        percentage: 0,
        expiration_date: new Date().toISOString().split('T')[0],
        max_uses: 1,
        used: 0,
        edit: true,
        editDetails: {},
      };
      this.data.unshift(newDiscount);
    },
    async deleteDiscountItem(item) {
      if (confirm('Are you sure you want to delete this discount?')) {
        try {
          const response = await this.deleteDiscount({
            token: this.$cookies.get('access_token'),
            id: item.id
          });

          if (response.status === 200) {
            const index = this.data.findIndex(e => e.id === parseInt(item.id));
            this.data.splice(index, 1);
            useToast().success("Discount deleted successfully!");
          } else if (response.status === 404) {
            useToast().error("Discount not found. It may have been already deleted.");
            // Optionally refresh the table to ensure it's up to date
            await this.refreshTable();
          } 
        } catch (error: any) {
          console.error("Failed to delete discount:", error);
          useToast().error(error.message || "Failed to delete discount. Please try again.");
        }
      }
    },
    async refreshTable() {
      this.refreshLoading = true;
      await this.getDiscounts(this.$cookies.get('access_token'));
      this.data = [...this.discounts];
      this.refreshLoading = false;
    },
  },
  data() {
    return {
      headers: [
        { text: "CODE", value: "code", sortable: true },
        { text: "PERCENTAGE", value: "percentage", sortable: true },
        { text: "EXPIRATION DATE", value: "expiration_date", sortable: true },
        { text: "MAX USES", value: "max_uses", sortable: true },
        { text: "USED", value: "used", sortable: true },
        { text: "", value: "actions" },
      ],
      data: [],
      refreshLoading: false,
    };
  },
  async mounted() {
    await this.getDiscounts(this.$cookies.get('access_token'));
    this.data = [...this.discounts].sort((a, b) => b.id - a.id);
  }
});
</script>

<template>
  <vue3-easy-data-table buttons-pagination alternating :headers="headers" :items="data"
    :loading="data.length ? false : loading" :search-value="search">

    <template #header-actions>
      <div class="customize-header">
        <ion-icon :icon="add" class="add" @click="createNewDiscount"></ion-icon>
        <ion-icon v-if="!refreshLoading" :icon="refresh" class="refresh" @click="refreshTable"></ion-icon>
        <div v-else class="dot-carousel"></div>
      </div>
    </template>

    <template #item-code="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.code" :value="item.code"></ion-input>
      <div v-else class="data">{{ item.code }}</div>
    </template>

    <template #item-percentage="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.percentage" type="number"
        :value="item.percentage"></ion-input>
      <div v-else class="data">{{ item.percentage }}%</div>
    </template>

    <template #item-expiration_date="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.expiration_date" type="date"
        :value="item.expiration_date"></ion-input>
      <div v-else class="data">{{ formattedTime(item.expiration_date) }}</div>
    </template>

    <template #item-max_uses="item">
      <ion-input v-if="item.edit" v-model="item.editDetails.max_uses" type="number" :value="item.max_uses"></ion-input>
      <div v-else class="data">{{ item.max_uses }}</div>
    </template>

    <template #item-used="item">
      <div class="data">{{ item.used }}</div>
    </template>

    <template #item-actions="item">
      <div v-if="!item.edit">
        <ion-icon :icon="pencil" class="action-icon" @click="editDiscount(item)"></ion-icon>
        <ion-icon :icon="close" class="action-icon delete" @click="deleteDiscountItem(item)"></ion-icon>
      </div>
      <div v-else-if="item.editLoading" class="dot-carousel"></div>
      <div v-else>
        <ion-icon :icon="checkmark" class="action-icon save" @click="updateDiscountDetails(item)"></ion-icon>
        <ion-icon :icon="close" class="action-icon cancel" @click="editDiscount(item)"></ion-icon>
      </div>
    </template>
  </vue3-easy-data-table>
</template>

<style scoped>
.customize-header {
  display: flex;
  align-items: center;
}

.action-icon {
  margin: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.add,
.refresh {
  margin: 0 10px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.add:hover,
.refresh:hover,
.action-icon:hover {
  color: var(--ion-color-dark-shade);
}

.delete:hover {
  color: var(--ion-color-danger);
}

.save:hover {
  color: var(--ion-color-success);
}

.cancel:hover {
  color: var(--ion-color-warning);
}

ion-input {
  --padding-start: 5px;
  width: 100px;
  border-bottom: solid 2px var(--ion-color-warning);
  margin: 5px -5px;
  height: 33px;
  font-weight: 500;
}

.data {
  width: 100px;
}

.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-light);
  box-shadow: 9984px 0 0 0 var(--ion-color-light), 9999px 0 0 0 var(--ion-color-light), 10014px 0 0 0 var(--ion-color-light);
  animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px var(--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade);
  }

  50% {
    box-shadow: 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var (--ion-color-dark-shade), 9999px 0 0 1px var(--ion-color-dark-shade);
  }

  100% {
    box-shadow: 9999px 0 0 1px var(--ion-color-dark-shade), 10014px 0 0 -1px var(--ion-color-dark-shade), 9984px 0 0 -1px var(--ion-color-dark-shade);
  }
}
</style>
