import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-in-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_sign_in = _resolveComponent("sign-in")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.closeOutline,
                      color: "danger",
                      size: "large"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, {
              innerHTML: _ctx.$t('auth.delete_account.modal_title')
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_title, {
            innerHTML: _ctx.$t('auth.delete_account.title')
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_ion_subtitle, {
            innerHTML: _ctx.$t('auth.delete_account.subtitle')
          }, null, 8, ["innerHTML"])
        ]),
        _createVNode(_component_sign_in, {
          prefilledEmail: this.prefilledEmail,
          unAuthorized: true,
          createAccount: false,
          styling: {buttonText: _ctx.$t('auth.delete_account.button_delete'), buttonColor: 'danger'}
        }, null, 8, ["prefilledEmail", "styling"])
      ]),
      _: 1
    })
  ], 64))
}