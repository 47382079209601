<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
name: 'ProgressBar',

});
</script>

<template>
  <div class="progress-bar">
    <div class="container"></div>
    <div class="progress"></div>
  </div>
</template>

<style>
 
.progress-bar {
  height: 10px;
  width: 50px;
  background-color: var(--ion-color-light-shade);
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar .progress {
  height: 100%;
  width: 0%;
  background-color: var(--ion-color-danger);
  animation: progress 10s linear forwards;
}

@keyframes progress {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

</style>
